import { BountyType, AttachmentType, EntityType } from 'Constants/enums';
import { cloneDeep } from 'lodash';
import { getAttachmentStorageRef, uploadOneFile } from 'Services/StorageService';
import {
  formatAttachment,
  getNextAttachmentId,
  getNextOrder,
  getOrder,
  valueOfUploadFile,
  valueOfUploadFileWithUri,
} from 'Utils/attachments';
import { getBountyDetailsLink, getPromotionDetailsLink } from 'Utils/link';
import { checkStatus, errorParser, getHeaders, responseParser } from 'Utils/api';
import { getApplicationConfig } from 'Utils/appConfig';
import { getProductCoverUrl, getPromotionCoverUrl } from 'Utils/bounty';
import { firebaseGetCurrentlySignedInUser } from 'Services/FirebaseService';
import { generateUID } from "Utils/helpers";

const { API_BASE_URL } = getApplicationConfig();

export async function uploadAttachment(props) {
  const { attachmentId, attachment, attachmentStorageRef, order } = props;

  if (attachment.type === AttachmentType.Image || attachment?.data) {
    const result = await uploadOneFile(valueOfUploadFile(attachment), attachmentStorageRef);
    return result ? formatAttachment(result, attachmentId, order) : null;
  }
  const result = await uploadOneFile(valueOfUploadFileWithUri(attachment), attachmentStorageRef);
  return result ? formatAttachment(result, attachmentId, order) : null;
}

export function uploadGenericBountyAttachments({ entityData, entityType, attachments, userData }) {
  const oldAttachments = entityData?.attachments?.attachments
    ? JSON.parse(JSON.stringify(entityData.attachments.attachments))
    : {};
  const takenAttachIds = Object.keys(oldAttachments) || [];
  let order = getOrder(Object.values(oldAttachments) || []);
  const promises = [];

  attachments
    .filter((attach) => !attach.id)
    .forEach((attach) => {
      const attachmentId = getNextAttachmentId(takenAttachIds);
      const attachmentStorageRef = getAttachmentStorageRef({
        entityType,
        hasAttachments: entityData,
        attachmentType: attach.type,
        attachmentId,
        userData,
      });

      promises.push(uploadAttachment({
        attachmentStorageRef,
        attachmentId,
        order,
        attachment: attach,
      }));

      if (!attach.order) {
        order++;
      }

      takenAttachIds.push(attachmentId);
    });

  return Promise
    .all(promises)
    .then((responses) => responses
      .filter((resp) => resp)
      .reduce((map, attach) => {
        map[attach.id] = attach;
        return map;
      }, {}))
    .then((uploadedAttachments) => {
      let ord = getOrder([...Object.values(oldAttachments), ...Object.values(uploadedAttachments)]);
      const oldAttach = attachments
        .filter((attach) => attach.id)
        .reduce((map, attach) => {
          if (!attach.order) {
             
            map[attach.id] = {
              ...attach,
              order: getNextOrder(ord),
            };
            ord++;
          } else {
            map[attach.id] = attach;
          }

          return map;
        }, {});

      const finalAttachments = { ...oldAttach, ...uploadedAttachments };
      return Object.keys(finalAttachments).length ? { attachments: { ...oldAttach, ...uploadedAttachments } } : null;
    });
}

export function uploadBountyAttachments(bounty, attachments, userData) {
  return uploadGenericBountyAttachments({
    entityData: bounty,
    attachments,
    userData,
    entityType: EntityType.bounty,
  });
}

export function uploadBountyResponseAttachments(response, attachments, userData) {
  return uploadGenericBountyAttachments({
    entityData: response,
    attachments,
    userData,
    entityType: EntityType.response,
  });
}

export async function uploadUserPicture(attachments, attachment, userData, userId) {
  const takenAttachIds = attachments?.map(({ id }) => id) || [];
  const order = getOrder(attachments);

  const attachmentId = getNextAttachmentId(takenAttachIds);
  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.user,
    hasAttachments: { id: userId },
    attachmentType: attachment.type,
    attachmentId,
    userData,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    order,
    attachment,
  });
}

export async function uploadContractAttachment(attachment, user, userData, attachId) {
  const attachmentId = attachId || getNextAttachmentId();
  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.user,
    hasAttachments: user,
    attachmentType: attachment.type,
    attachmentId,
    userData,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    attachment,
  });
}

export async function uploadChatAttachment(attachment, userData, msgId) {
  const takenAttachIds = Object.keys(msgId?.attachment || {}) || [];

  const attachmentId = getNextAttachmentId(takenAttachIds);
  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.chat,
    attachmentType: AttachmentType.PDF,
    attachmentId,
    userData,
    msgId,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    attachment,
    msgId,
  });
}

export async function uploadChatAttachments(attachments, userData, msgId) {
  return Promise.all(attachments.map(async (attachment) => {
    const takenAttachIds = Object.keys(msgId?.attachment || {}) || [];

    const attachmentId = getNextAttachmentId(takenAttachIds);
    const attachmentStorageRef = getAttachmentStorageRef({
      entityType: EntityType.chat,
      attachmentType: AttachmentType.PDF,
      attachmentId,
      userData,
      msgId,
    });

    return uploadAttachment({
      attachmentStorageRef,
      attachmentId,
      attachment,
      msgId,
    });
  }));
}

export async function onPromotionAttachmentUpdate(oldBounty, promotions) {
  const oldAttachments = cloneDeep(oldBounty?.attachments?.attachments || {});
  const takenAttachIds = Object.keys(oldAttachments) || [];
  const newAttachments = {};

  let order = getOrder(Object.values(oldAttachments));

  promotions
    .forEach((prom) => {
      if (prom.order) {
        newAttachments[prom.id] = prom;
      } else {
        const attachmentId = getNextAttachmentId(takenAttachIds);
        takenAttachIds.push(attachmentId);

        newAttachments[attachmentId] = {
          id: attachmentId,
          title: prom?.description,
          url: getPromotionCoverUrl(prom) || null,
          type: AttachmentType.Image,
          meaning: BountyType.Promotion,
          action: {
            actionUrl: getPromotionDetailsLink(prom.id),
          },
          order: getNextOrder(order),
        };

        order++;
      }
    });

  return { attachments: newAttachments };
}

export async function onProductAttachmentUpdate(oldBounty, products) {
  const oldAttachments = cloneDeep(oldBounty?.attachments?.attachments || {});
  const takenAttachIds = Object.keys(oldAttachments) || [];
  const newAttachments = {};

  let order = getOrder(Object.values(oldAttachments));

  products
    .forEach((prod) => {
      if (prod.order) {
        newAttachments[prod.id] = prod;
      } else {
        const attachmentId = getNextAttachmentId(takenAttachIds);
        takenAttachIds.push(attachmentId);

        newAttachments[attachmentId] = {
          id: attachmentId,
          title: prod?.product?.name,
          url: getProductCoverUrl(prod) || null,
          type: AttachmentType.Image,
          meaning: BountyType.Product,
          action: {
            actionUrl: getBountyDetailsLink(prod.id, BountyType.Product),
          },
          order: getNextOrder(order),
        };

        order++;
      }
    });

  return { attachments: newAttachments };
}

export const uploadFile = async (path, file) => {
  const url = `${API_BASE_URL}${path}`;
  const token = await firebaseGetCurrentlySignedInUser();
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    headers: getHeaders(token, true),
    method: 'POST',
    body: formData,
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const uploadCompanyAttachment = async ({
  attachment,
  userData,
  attachmentType = AttachmentType.Image,
}) => {
  const attachmentId = generateUID();

  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.company,
    hasAttachments: attachment,
    attachmentType,
    attachmentId,
    userData,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    attachment: attachment,
  });
};