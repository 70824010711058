import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Types/redux.types';
import { Client, ClientInfo } from 'Types/client.interface';
import { ShopSettings } from 'Types/shopSettings.interface';
import { ClientLocation } from 'Types/clientLocation.interface';
import { SelectOption } from 'Types/option.interface';
import { CompanyType, SettingsStrategy, WriteMode } from 'Constants/enums';
import { companySettingsSelector } from 'Store/settings/selectors';
import { CustomChemical, ProductChemicals } from "Types/chemicals.interface";

export const locationsSelector = (state: RootState): ClientLocation[] => state.clientReducer.locations || [];
export const usStatesSelector = (state: RootState): Record<string, string> => state.clientReducer.usStates || {};
export const clientSelector = (state: RootState): Client | null => state.clientReducer.client;
export const parentClientSelector = (state: RootState): ClientInfo | null => state.clientReducer.parentClient;
export const shopSettingsSelector = (state: RootState): ShopSettings | null => state.clientReducer.shopSettings;
export const parentSettingsSelector = (state: RootState): ShopSettings | null => state.clientReducer.parentSettings;
export const isMerchantSettingsSelectedSelector = (state: RootState): boolean => state.clientReducer.isMerchantSettingsSelected;
export const isLoadingSelector = (state: RootState): boolean => state.clientReducer.isLoading || false;
export const isSubmittingSelector = (state: RootState): boolean => state.clientReducer.isSubmitting || false;
export const clientIdSelector = (state: RootState): string => state.clientReducer.clientId || '';
export const activeSettingsPageSelector = (state: RootState): number => state.clientReducer.activeSettingsPage || 1;
export const customProductChemicalsSelector = (state: RootState): CustomChemical[] => state.clientReducer.customProductChemicals || [];
export const productChemicalsSelector = (state: RootState): ProductChemicals => state.clientReducer.productChemicals || {};

export const formattedLocationsSelector = (state: RootState): Record<string, string> => {
  const locations = state.clientReducer.locations || [];
  const formattedLocations: Record<string, string> = {};

  if (locations) {
    locations.forEach(({ id, name }) => {
      formattedLocations[id] = name;
    });
  }

  return formattedLocations;
};

export const locationsAsOptionsSelector = (state: RootState): SelectOption[] => {
  const locations = state.clientReducer.locations || [];

  return locations.map((location) => ({
    value: location?.id || '',
    label: location?.name,
  }));
};

export const clientAdditionalInfoSelector = createSelector(
  companySettingsSelector,
  clientSelector,
  shopSettingsSelector,
  isMerchantSettingsSelectedSelector,
  (companySettings, client, shopSettings, isMerchantSettingsSelected) => {
    const currentOrgType = companySettings?.data?.organizationType;

    const clientName = client?.clientInfo?.name || '';
    const isOpsUser = CompanyType.Special === currentOrgType;
    const isMerchantUser = CompanyType.Merchant === currentOrgType;
    const isMerchantSettings = isMerchantUser || isMerchantSettingsSelected;
    const isCorporateSettings = !isMerchantSettings;
    const pageTitle = isMerchantSettings ? clientName : 'label.global';
    const areCustomSettingsDisabled = isMerchantSettings && shopSettings?.writeMode === WriteMode.Corporate;
    const areSettingsDisabledForMerchants = isMerchantUser && shopSettings?.settingsStrategy === SettingsStrategy.CorpOnly;

    return {
      isOpsUser,
      isMerchantSettings,
      isMerchantSettingsSelected,
      isCorporateSettings,
      areSettingsDisabled: areCustomSettingsDisabled || areSettingsDisabledForMerchants,
      pageTitle,
    };
  },
);
