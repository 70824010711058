import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomInput from 'Components/CustomInput';

import { closeModal } from 'Store/modal/actions';
import { getUserSelector } from 'Store/user/selectors';
import { UserService } from 'Services/UserService';

import { ThunkDispatchType } from 'Types/redux.types';
import { getUser } from 'Store/user/actions';
import { getUserAgent } from 'Utils/api';
import { notificationToast } from 'Utils/notificationToaster';
import CustomModalButtons from 'Components/CustomModalButtons';

const EditName = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const userProfile = useSelector(getUserSelector) || {};
  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    if (userProfile?.name) {
      setName(userProfile?.name);
    }
  }, [userProfile]);

  const handleInputChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setName(value);

  const editName = async () => {
    setIsSubmitting(true);

    try {
      await UserService.updateUserRequest(userProfile?.id, {
        meta: {
          action: 'update_user',
          userId: userProfile?.id,
          agent: getUserAgent(),
          restEvent: true,
        },
        name,
      });
      await dispatch(getUser(userProfile.id));

      dispatch(closeModal());
    } catch (error: any) {
      notificationToast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => dispatch(closeModal());

  return (
    <form onSubmit={editName}>
      <CustomInput
        name="name"
        autoFocus
        value={name}
        onChange={handleInputChanges}
      />
      <CustomModalButtons
        onDismiss={handleClose}
        onSubmit={editName}
        isLoading={isSubmitting}
      />
    </form>
  );
};

export default EditName;
