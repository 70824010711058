import { useDispatch } from 'react-redux';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, FC } from 'react';

import RadioStyled from 'Components/RadioStyled';

import { closeModal } from 'Store/modal/actions';
import { reportBounty } from 'Services/bounty/BountyService';
import { reportResponse } from 'Services/response/ResponseService';

import { reportSchema } from 'Utils/validation/report';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import { REPORT_REASON_OPTIONS } from 'Constants/dictionaries';
import CustomModalButtons from 'Components/CustomModalButtons';

interface ReportBountyProps {
  bounty?: Bounty;
  response?: BountyResponse;
}

const ReportBounty:FC<ReportBountyProps> = ({ bounty, response }) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();

  const handleCloseModal = () => dispatch(closeModal());

  const handleSubmit = ({ reason }: { reason: string }) => {
    if (!reason.trim()) {
      notificationToast.info(intl.formatMessage({ id: 'info.provideReasonReport' }));
    } else {
      if (response?.bountyInfo) {
        reportResponse({ response, reason });
      } else {
        reportBounty({ bounty, reason });
      }

      notificationToast.info(intl.formatMessage({ id: 'info.reportHasBeenReceived' }));
      handleCloseModal();
    }
  };

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: reportSchema,
    onSubmit: handleSubmit,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <RadioGroup
        aria-label="gender"
        name="reason"
        value={formik.values.reason}
        onChange={handleChanges}
      >
        {REPORT_REASON_OPTIONS.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<RadioStyled />}
            label={label}
          />
        ))}
      </RadioGroup>

      <input type="submit" hidden />
      <CustomModalButtons
        onDismiss={handleCloseModal}
        submitName="button.send"
        onSubmit={formik.handleSubmit}
      />
    </form>
  );
};

export default ReportBounty;
