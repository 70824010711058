import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { AttachmentType, EntityType } from 'Constants/enums';
import * as UserProfile from 'Utils/profile';
import * as ObjectUtils from 'Utils/object';
import * as BountyResponse from 'Utils/bountyResponse';
import * as Bounty from '../utils/bounty.tsx';
import { getMyUserId } from './BaseService';

const UPLOADS_DIR = 'uploads';
const DOCS_DIR = 'docs';
const PICTURES_DIR = 'pictures';
const VIDEOS_DIR = 'video';
const AUDIOS_DIR = 'audio';
const BOUNTIES_DIR = 'bounties';
const RESPONSES_DIR = 'responses';
const USERS_DIR = 'users';
const COMPANIES_DIR = 'companies';
const MESSAGES_DIR = 'messages';

const CHILD_BY_ATTACHMENT_TYPE = {
  [AttachmentType.Image]: PICTURES_DIR,
  [AttachmentType.GIF]: PICTURES_DIR,
  [AttachmentType.Video]: VIDEOS_DIR,
  [AttachmentType.Audio]: AUDIOS_DIR,
  [AttachmentType.PDF]: DOCS_DIR,
  [AttachmentType.File]: DOCS_DIR,
  [AttachmentType.Other]: DOCS_DIR,
};

const getStorageRef = () => {
  return firebase.storage().ref();
};

const getUploadsRef = (userData) => {
  const { owner } = userData;
  const ownerId = ObjectUtils.firstNonNull(owner ? owner.id : null, getMyUserId());

  return getStorageRef().child(UPLOADS_DIR).child(ownerId);
};

export const getBountyUploadRef = (bounty, userData) => {
  const id1 = Bounty.getLevel1Id(bounty);
  return getUploadsRef(userData)
    .child(BOUNTIES_DIR)
    .child(id1);
};

export const getResponseUploadRef = (response, userData) => {
  const id1 = BountyResponse.getLevel1Id(response);
  const id2 = BountyResponse.getLevel2Id(response);

  return getUploadsRef(userData)
    .child(BOUNTIES_DIR)
    .child(id1)
    .child(RESPONSES_DIR)
    .child(id2);
};

export const getUserUploadRef = (user, userData) => {
  const id1 = UserProfile.getLevel1Id(user?.user || user);
  return getUploadsRef(userData)
    .child(USERS_DIR)
    .child(id1);
};

const getUploadRefByEntityType = ({ entityType, data, userData, msgId }) => {
  if (entityType === EntityType.chat) {
    return getUploadsRef(userData).child(MESSAGES_DIR).child(msgId);
  }
  if (entityType === EntityType.bounty) {
    return getBountyUploadRef(data, userData);
  }

  if (entityType === EntityType.response) {
    return getResponseUploadRef(data, userData);
  }

  if (entityType === EntityType.user) {
    return getUserUploadRef(data, userData);
  }

  if (entityType === EntityType.company) {
    return getStorageRef().child(UPLOADS_DIR).child(COMPANIES_DIR).child(userData?.company?.id);
  }

  return null;
};

export const getAttachmentStorageRef = (data) => {
  const {
    hasAttachments, attachmentType, attachmentId, userData, entityType, msgId,
  } = data;
  const childName = CHILD_BY_ATTACHMENT_TYPE[attachmentType];

  if (!childName) {
    return null;
  }
  return getUploadRefByEntityType({ entityType, data: hasAttachments, userData, msgId })
    .child(childName)
    .child(attachmentId);
};

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64.split(',')[1]); // Remove the `data:*/*;base64,` prefix
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

const putFile = (ref, file) => {
  if (file?.fileContent) {
    // Detect and handle base64 content
    if (typeof file?.fileContent === 'string' && file?.fileContent?.startsWith('data:')) {
      const blob = base64ToBlob(file.fileContent, file.mimeType);
      return ref.put(blob);
    }

    return ref.put(file.fileContent);
  }

  return ref.putString(file.fileUri, 'data_url');
};

const UploadResult = (uploadFile, fileRef) => {
  const {
    fileUri, type, w, h, mimeType, filename, meaning, ...remainingProps
  } = uploadFile;

  return {
    ...remainingProps,
    meaning: meaning || null,
    type,
    mimeType,
    filename,
    fileUri,
    fileRef,
    width: w,
    height: h,
  };
};

export const uploadOneFile = (uploadFile, fileRef) => {
  return new Promise((resolve) => {
    const uploadRef = fileRef.child(uploadFile.filename);
    const result = UploadResult(uploadFile, uploadRef);
    const uploadTask = putFile(fileRef, uploadFile);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      result.size = snapshot.totalBytes;
      // console.log('progress', progress);// eslint-disable-line
    }, (error) => {
      console.log('Error while trying to upload', error); // eslint-disable-line
    }, () => {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref
        .getDownloadURL()
        .then((downloadURL) => {
          result.downloadUri = downloadURL;
          resolve(result);
        });
    });
  });
};
