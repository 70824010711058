import { isBefore, subYears } from 'date-fns';

import {
  JoiningStrategy,
  KYCLevel,
  LanguageLevel,
  UserIdentityConfigState,
  UserKind,
  UserReputation,
} from 'Constants/enums';
import { OptimizedUserData } from 'Types/optimizedUserData.interface';
import { UserData } from 'Types/userData.interface';
import { User } from 'Types/user.interface';
import { KYCVisibleState } from 'Types/KYCVisibleState.interface';
import { UserLookup } from 'Types/userLookup.interface';
import { InitialInvitee } from 'Types/invitee.interface';
import { extractPhoneNumber } from 'Utils/phoneUtils';
import { getUserReputationTranslationKey } from 'Utils/translationKeyFormatters';
import { UserResponse } from 'Types/userResponse.interface';
import { Flows } from 'Types/flows.interface';
import { capitalize } from 'Utils/text';

export const PREFIX = 'avatar:';
export const UNDERCOVER_AVATAR_URI = `${PREFIX}undercover`;
export const UNDERCOVER_NAME = 'Undercover';

const CUSTOM_PREFIX = 'rep:';
const ICON_PREFIX = 'gmd_';

// TODO: remove
export const getUserKindAsEnum = (user: User) => {
  if (!user || !user.kind) {
    return UserKind.User;
  }

  return user.kind.toUpperCase();
};

export const reputationBuilder = (reputation?: string) => {
  if (!reputation) return {};

  const parts = (reputation
      && reputation.replace('code=rep:', '').substring(CUSTOM_PREFIX.length).split('&').filter((item) => item))
    || [];
  const reputationProps: Record<string, string> = {};

  parts.forEach((part) => {
    const [name, value] = part.split('=').filter((item) => item);
    reputationProps[name] = value;
  });

  if (reputationProps.icon && reputationProps.icon.startsWith(ICON_PREFIX)) {
    reputationProps.icon = reputationProps.icon.substring(ICON_PREFIX.length);
  }

  return reputationProps;
};

export const formatUserData = (response: UserData): OptimizedUserData => {
  const {
    basicProfile: {
      user: { avatar, id, latinizedName, name, reputation },
      createdAt,
      coverImageUrl,
      rating,
      description,
      badges,
      educationExperience,
      workExperience,
      attachments,
      medicalInfo,
      livePaymentMode,
    },
    extraData,
    specialSituationInfo,
    jobProgramInfo,
  } = response;

  const {
    address, dob, email, mailingAddress, citizenship, firstName,
    gender, lastName, middleName, secondLastName, sameAddress, socialNetworks, spokenLanguages,
  } = response?.basicProfile?.basicInfo || {};

  const formattedLanguages = spokenLanguages && Object.entries(spokenLanguages).map((el) => ({ code: el[0], ...el[1] }));

  return {
    address: {
      line1: address?.line1,
      line2: address?.line2,
      phoneNumber: address?.phoneNumber,
      phoneStatus: address?.phoneStatus,
      countryCode: address?.countryCode,
      city: address?.city,
      state: address?.state,
      postalCode: address?.postalCode,
    },
    avatar,
    reputation,
    rating,
    badges,
    citizenship: citizenship || '',
    countryCode: extraData?.countryCode,
    dob,
    description,
    coverImageUrl,
    secondLastName,
    email,
    educationExperience: {
      certifications: educationExperience?.certifications ? Object.values(educationExperience?.certifications) : [],
      didAttendSchool: educationExperience?.didAttendSchool,
      highestGradeCompletedType: educationExperience?.highestGradeCompletedType,
      schoolLocation: educationExperience?.schoolLocation,
      driversLicense: educationExperience?.driversLicense
        ? Object.keys(educationExperience?.driversLicense).filter((v) => v !== 'UNKNOWN')
        : [],
    },
    firstName,
    gender,
    id,
    name,
    lastName,
    latinizedName,
    foreignLanguages: (formattedLanguages && formattedLanguages.filter(({ level }) => level !== LanguageLevel.Native)) || [],
    nativeLanguage: (formattedLanguages && formattedLanguages.filter(({ level }) => level === LanguageLevel.Native)[0]?.code) || null,
    languages: extraData?.languages ? extraData.languages.map((l) => l.langCode) : [],
    mailingAddress: {
      line1: mailingAddress?.line1,
      line2: mailingAddress?.line2,
      phoneNumber: mailingAddress?.phoneNumber,
      phoneStatus: mailingAddress?.phoneStatus,
      countryCode: mailingAddress?.countryCode,
      city: mailingAddress?.city,
      state: mailingAddress?.state,
      postalCode: mailingAddress?.postalCode,
    },
    middleName,
    sameAddress,
    socialNetworks: socialNetworks || [],
    specialSituationInfo,
    jobProgramInfo,
    systemInfo: {
      blacklisted: extraData?.blacklisted,
      createdAt,
      createdBy: extraData?.creator?.name,
      creatorId: extraData?.creator?.id,
      inviteCode: extraData?.inviteCode,
      inviteLink: extraData?.inviteLink,
      lastLoginAt: extraData?.lastLoginAt,
      pin: extraData?.pin,
      companyId: extraData?.companyId,
      testUser: extraData?.testUser,
      updatedAt: extraData?.updatedAt,
      deviceInfo: extraData?.deviceInfo || null,
      appInfo: extraData?.appInfo,
      timezone: extraData?.timezone,
      appliedRoles: extraData?.roles || [],
    },
    workExperience: {
      workExperienceYears: workExperience?.workExperienceYears,
      workplaceExperience: workExperience?.workplaceExperience ? Object.values(workExperience?.workplaceExperience) : [],
    },
    attachments: attachments && attachments.attachments ? Object.values(attachments.attachments) : [],
    skillSetIds: extraData?.skillset ? extraData?.skillset.map((s) => s.id) : [],
    medicalInfo: medicalInfo && medicalInfo.vaccinations ? { ...medicalInfo.vaccinations } : null,
    kind: extraData?.kind,
    type: extraData?.type,
    orgType: extraData?.orgType,
    website: extraData?.websites?.length ? extraData.websites[0]?.url : '',
    livePaymentMode: livePaymentMode || false,
  };
};

export const KYC_ACTION_PERFORMED = [
  UserIdentityConfigState.Verified,
  UserIdentityConfigState.Rejected,
];

export const getEmptyContentResId = (isGroupMembers: boolean, joiningStrategy: JoiningStrategy | null, defaultEmptyMsg: string) => {
  if (!isGroupMembers && joiningStrategy !== null) {
    if (joiningStrategy === JoiningStrategy.InviteOnly) {
      return 'empty_state_invite_only_subtitle';
    }

    if (joiningStrategy === JoiningStrategy.NeedsApproval) {
      return 'empty_state_needs_approval_subtitle';
    }
  }

  return defaultEmptyMsg;
};

export const getCutName = (fullName: string) => {
  if (!fullName) {
    return '';
  }
  const splitNameArray = fullName.split(' ');
  splitNameArray.forEach((item, index) => {
    if (!item) {
      splitNameArray.splice(index, 1);
    }
  });

  if (splitNameArray.length === 1) {
    return fullName;
  }

  if (splitNameArray.length > 1) {
    return `${splitNameArray[0]} ${splitNameArray[1].charAt(0)}.`;
  }
};

// TODO: type any
export const sortUsersDueRelevantDate = (arr: any[], entity = '') => {
  if (entity === 'applicant') {
    return arr.sort((a, b) => {
      if (a.userData?.createdAt < b.userData?.createdAt) {
        return 1;
      }
      if (a.userData?.createdAt > b.userData?.createdAt) {
        return -1;
      }
      return 0;
    });
  }
  if (entity === 'notifications') {
    return arr.sort((a, b) => {
      if (a?.sentAt < b?.sentAt) {
        return 1;
      }
      if (a?.sentAt > b?.sentAt) {
        return -1;
      }
      return 0;
    });
  }

  return arr.sort((a, b) => {
    if (a?.createdAt < b?.createdAt) {
      return 1;
    }
    if (a?.createdAt > b?.createdAt) {
      return -1;
    }
    return 0;
  });
};

// TODO: add proper type
export const getKYCVisibleState = (identityConfigInfo: any): KYCVisibleState | null => {
  const levels = Object
    .keys(identityConfigInfo)
    .filter((key) => KYC_ACTION_PERFORMED.includes(identityConfigInfo[key].state));

  if (!levels) {
    return null;
  }
  if (levels.length === 1) {
    return {
      ...identityConfigInfo[levels[0]],
      level: levels[0],
    };
  }

  const internationalLevel = levels.find((level) => level === KYCLevel.International);
  const europeLevel = levels.find((level) => level === KYCLevel.Europe);
  const localLevel = levels.find((level) => level === KYCLevel.Local);
  const mainLevel = internationalLevel || europeLevel || localLevel;

  if (!mainLevel) return null;

  return {
    ...identityConfigInfo[mainLevel as keyof typeof identityConfigInfo],
    level: mainLevel,
  };
};

// TODO: add proper type
export const getKycLevel = (identity: any): KYCLevel | null => {
  const ALLOWED_STATES = [
    UserIdentityConfigState.Rejected,
    UserIdentityConfigState.Verified,
    UserIdentityConfigState.Pending,
    UserIdentityConfigState.ReadyForKYC,
    UserIdentityConfigState.Required,
  ];

  if (identity[KYCLevel.International] && ALLOWED_STATES.includes(identity[KYCLevel.International].state)) {
    return KYCLevel.International;
  }

  if (identity[KYCLevel.Europe] && ALLOWED_STATES.includes(identity[KYCLevel.Europe].state)) {
    return KYCLevel.Europe;
  }

  if (identity[KYCLevel.Local] && ALLOWED_STATES.includes(identity[KYCLevel.Local].state)) {
    return KYCLevel.Local;
  }

  return null;
};

export const formatMemberToInvitee = (member: UserLookup): InitialInvitee => {
  const phoneNumber = member?.phoneNumber?.phoneNumber;
  const { countryCode = '', basePhoneNumber = '' } = phoneNumber
    ? extractPhoneNumber(member?.phoneNumber?.phoneNumber)
    : {};

  return {
    phone: basePhoneNumber || phoneNumber,
    phoneCountryCode: countryCode || '',
    email: member?.email,
    name: member?.user?.name,
    roles: member?.roles?.[0],
  };
};

const hasAdditionalNameProps = (userResponse: UserResponse) => (
  userResponse
  && userResponse.firstName
  && userResponse.middleName
  && userResponse.lastName
  && userResponse.secondLastName
);

export const getUserFullName = (displayName: string, userResponse: UserResponse) => {
  if (hasAdditionalNameProps(userResponse)) {
    const {
      firstName, middleName, lastName, secondLastName,
    } = userResponse;

    return `${firstName} ${middleName || ''} ${lastName}${secondLastName ? `-${secondLastName}` : ''}`;
  }

  return displayName || '';
};

export const isUserAdult = (birthDate: string | null) => {
  if (!birthDate) {
    return false;
  }

  const currentDob = new Date(birthDate).getTime();
  const ageLimit = subYears(new Date(), 18).getTime();

  return isBefore(currentDob, ageLimit);
};

export const getUserReputation = (reputation?: UserReputation) => {
  switch (reputation) {
    case UserReputation.VIP:
      return { label: getUserReputationTranslationKey(UserReputation.VIP), className: 'officialBadge' };
    case UserReputation.Pro:
      return { label: getUserReputationTranslationKey(UserReputation.Pro) };
    case UserReputation.Verified:
      return { icon: 'check_circle', color: '#197dec' };
    default:
      return reputationBuilder(reputation);
  }
};

export const isSetupRequired = (flows?: Flows) => {
  if (flows?.setup === undefined || flows?.setup === null) {
    return true;
  }

  return !flows.setup;
};

export const extractNameFromEmail = (email: string) => {
  const [namePart] = email.split('@');
  const formattedName = namePart.replace(/[._-]/g, ' ');

  return formattedName
    .split(' ')
    .map(capitalize)
    .join(' ');
};
