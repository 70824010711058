import moment from 'moment';
import { MetaInfo } from 'Types/metaInfo.interface';
import { Survey } from 'Types/survey.interface';
import { BountyBehaviour } from 'Types/bountyBehaviour.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { Summary } from 'Types/bountySummary.interface';
import { OutboundResponsesType } from 'Types/outboundResponses.type';
import { Posting } from 'Types/posting.interface';
import { Bounty } from 'Types/bounty.interface';
import { OptimizedJobBounty } from 'Types/optimizedJobBounty.interface';
import { User } from 'Types/user.interface';
import { VisibilityMode as VisibilityModeInterface } from 'Types/visibilityMode.interface';
import { Badges as BadgesInterface } from 'Types/badge.interface';
import { UserData } from 'Types/userData.interface';
import * as Badges from 'Utils/badges';
import { getDaysHrsMinTimeUtilFormat, isExpired } from 'Utils/date';
import { BOUNTY_TYPE_LABELS, SENTS_FOLDER_BY_TYPE, SURVEY_TYPES } from 'Constants/bounty';
import { Reward } from 'Types/reward.interface';
import { Attachment } from 'Types/attachment.interface';
import { Product } from 'Types/product.interface';
import { OrderDetails } from 'Types/order.interface';
import {
  BadgeTypes,
  BountySocialNetworks,
  BountyState,
  BountyType,
  RebountyType,
  ResponseType,
  SortBy,
  StreamListType,
  SummaryProgressState,
  UserKind, VisibilityModeType,
} from 'Constants/enums';
import * as VisibilityMode from 'Utils/visibilityMode';
import { getTotalCount, valueOf } from 'Utils/survey';
import { addBadge, getDisplayName, removeBadge } from 'Utils/badges';
import { getDescriptionText } from 'Utils/yaml';
import { FormattedMessage, IntlShape } from 'react-intl';
import { StreamListData } from 'Types/streamListData.interface';
import { getVisibilityTypeAsEnum } from 'Utils/visibilityMode';
import { DAY_IN_MILLIS, WEEK_IN_MILLIS } from 'Constants/dates';
import { capitalize } from 'Utils/text';

interface CloneBounty {
  creator: User | null;
  owner: User | null;
  author: User | null;
  type: BountyType | null;
  description: {text: string} | null;
  reward: Reward | null;
  typeCreatedAtSortKey: string | null;
  typePopularitySortKey: string | null;
  typeDiscussedSortKey: string | null;
  terms: { rebountyType: string } | null;
  varTerms: { hopsToLive: number } | null;
  attachments: { attachments: { [key: string]: Attachment } } | null;
  product: Product | null;
  order: OrderDetails | null;
  visibility: string | null;
}

export const isCreatedByMe = (data: Bounty | BountyResponse, user: Partial<User>): boolean => data.creator && user && data.creator.id === user?.uid;

export const getEffectiveResponseVisibilityMode = (responseVisibilityMode: VisibilityModeInterface) => responseVisibilityMode || VisibilityMode.DEFAULT;

export const getAsSurvey = (bounty: Bounty): Survey|null => {
  const emptyData = { options: [], question: '', type: BountyType.None };

  if (isSurveyType(bounty.type)) {
    return bounty.description
      ? valueOf(bounty.description, bounty.type)
      : emptyData;
  }

  return emptyData;
};

export const getLevel1Id = (bounty: Bounty) => bounty.id;

export const isPosted = (postings?: Record<string, Posting>) => !!(postings && Object.keys(postings).length);

export const isRecommendation = (bountyType: BountyType) => [BountyType.TalentRecommendation, BountyType.RealEstateRecommendation].includes(bountyType);

export const isSubBounty = (bounty: Bounty) => bounty.parentBounty && bounty.parentBounty.id != null;

export const getEffectiveResponseKey = (bounty: Bounty) => bounty.responseKey || bounty.id;

export const isClosable = (type: BountyType) => type !== BountyType.TMOB_Coaching;

export const isSurveyType = (type: BountyType | ResponseType) => SURVEY_TYPES.includes(type as BountyType);

export const hideSurveyOptions = (type: BountyType) => [BountyType.Lottery].includes(type);

export const isResumeSent = (bounty: Bounty) => {
  const { outboundResponses } = bounty;

  if (!outboundResponses || !Object.keys(outboundResponses).length) {
    return false;
  }

  return Object.keys(outboundResponses).find((key) => (
    outboundResponses[key].recommendation && outboundResponses[key].recommendation.resumeUrl
  )) || false;
};

export const isBundle = (type: BountyType) => ([
  BountyType.Bundle,
  BountyType.Quest,
  BountyType.Trivia,
]).includes(type);

export const isContest = (type: BountyType | string) => type === BountyType.Trivia;

export const showScheduleInfo = (type: BountyType) => ([
  BountyType.Trivia,
  BountyType.Banner,
].includes(type));

export const excludeFromBundle = (type: BountyType) => ([
  BountyType.Product,
  BountyType.Banner,
  BountyType.News,
] as BountyType[]).includes(type);

const getRecommendationType = (type: BountyType) => {
  switch (type) {
    case BountyType.TalentSearch:
      return BountyType.TalentRecommendation;
    case BountyType.RealEstateCustomer:
      return BountyType.RealEstateRecommendation;
    case BountyType.Order:
      return BountyType.Order;
    default:
      return StreamListType.None;
  }
};

export const hasRecommendationResponse = (type: BountyType) => getRecommendationType(type) !== StreamListType.None;

const getDefaultRebountyTypeAsEnum = (bountyType: BountyType) => {
  switch (bountyType) {
    case BountyType.RealEstateCustomer:
    case BountyType.TalentSearch:
      return BountyType.Distribution;
    default:
      return BountyType.None;
  }
};

const getRebountyTypeAsEnum = (bounty: Bounty) => {
  const { terms, type } = bounty;

  if (terms && terms.rebountyType) {
    return terms.rebountyType;
  }

  getDefaultRebountyTypeAsEnum(type);
};

export const getEffectiveRebountyTypeAsEnum = (bounty: Bounty) => {
  const { varTerms } = bounty;

  if (!varTerms || varTerms.hopsToLive <= 0) {
    return RebountyType.None;
  }

  return getRebountyTypeAsEnum(bounty);
};

export const hasBadge = (badges?: BadgesInterface, badgeType?: BadgeTypes): boolean => (
  Boolean(badgeType && badges && Badges.hasBadge(badges, badgeType))
);

export const getSurveyTotalCount = (summary?: Summary): number => (summary && summary.surveyResult ? getTotalCount(summary.surveyResult.answers) : 0);

export const cloneBounty = (bounty: Bounty): CloneBounty => ({
  creator: bounty.creator || null,
  owner: bounty.owner || null,
  author: bounty.author || null,
  type: bounty.type || null,
  description: bounty.description || null,
  reward: bounty.reward || null,
  typeCreatedAtSortKey: bounty.typeCreatedAtSortKey || null,
  typePopularitySortKey: bounty.typePopularitySortKey || null,
  typeDiscussedSortKey: bounty.typeDiscussedSortKey || null,
  terms: bounty.terms || null,
  varTerms: bounty.varTerms || null,
  attachments: bounty.attachments || null,
  product: bounty.product || null,
  order: bounty.order || null,
  visibility: bounty.visibility || null,
});

export const getMyResponse = (outboundResponses: OutboundResponsesType, me: User) => {
  if (!outboundResponses) {
    return null;
  }

  return Object.keys(outboundResponses).find((key) => {
    const response = outboundResponses[key];
    return isCreatedByMe(response, me);
  }) || null;
};

export const asBountyInfo = (bounty: Bounty) => ({
  id: bounty.id,
  creator: bounty.creator,
  author: bounty.author || null,
  type: bounty.type,
  description: (bounty.description && bounty.description.text) || null,
});

export const getResponseType = (bounty: Bounty) => {
  switch (bounty.type) {
    case BountyType.TalentSearch:
    case BountyType.RealEstateCustomer:
      return ResponseType.Recommendation;
    default:
      return ResponseType.Note;
  }
};

export const isBountyExpired = (state: BountyState, expiresAt?: number | null) => state === BountyState.Expired || (shouldCheckForExpiry(state) && isExpired(expiresAt));

export const shouldCheckForExpiry = (state: BountyState) => state === BountyState.Active;

export const getSurveyChoices = (outboundResponses: OutboundResponsesType) => {
  const surveyChoices: string[] = [];

  if (outboundResponses) {
    Object.values(outboundResponses).forEach((outboundRes) => {
      if (outboundRes.type === ResponseType.Choice && outboundRes.choice) {
        surveyChoices.push(outboundRes.choice);
      }
    });
  }

  return surveyChoices;
};

export const isSurveyCompleted = (bountyType?: BountyType, summary?: Summary) => {
  if (bountyType === BountyType.Checklist) {
    return false;
  }

  return summary && summary.surveyResult && summary.progressState === SummaryProgressState.Completed;
};

export const isSurveyAnswerable = (bounty: Bounty, isOutbound: boolean) => {
  const {
    state,
    type,
    expiresAt,
    outboundResponses,
    summary,
  } = bounty;

  return !isOutbound
    && !isBountyExpired(state, expiresAt)
    && ![BountyState.Draft, BountyState.Closed].includes(state)
    && (!outboundResponses || (outboundResponses && !isSurveyAnswered(type, outboundResponses)))
    && !isSurveyCompleted(type, summary);
};

export const isSurveyAnswered = (bountyType: BountyType, outboundResponses: OutboundResponsesType) => {
  if (bountyType === BountyType.Checklist) {
    return false;
  }

  return getSurveyChoices(outboundResponses).length > 0;
};

export const isRebounty = (bounty: Bounty) => (bounty ? !!bounty.originBounty : false);

export const isNetworkLinkRequired = (network: BountySocialNetworks) => network !== BountySocialNetworks.InApp;

export const isFavorite = (bounty: Bounty) => typeof bounty.favoritedAt !== 'undefined' && bounty.favoritedAt !== null && bounty.favoritedAt > 0;

export const getDateFromBounty = (date?: number) => {
  if (!date) return;

  const secondsDiff = moment().diff(date, 'seconds');
  const minutesDiff = moment().diff(date, 'minutes');
  const hoursDiff = moment().diff(date, 'hours');
  const daysDiff = moment().diff(date, 'days');

  if (secondsDiff < 60) {
    return 'Just now';
  }

  if (minutesDiff < 60) {
    return `${minutesDiff} min`;
  }

  if (hoursDiff < 24) {
    return `${hoursDiff} h`;
  }

  if (daysDiff === 1) {
    return `${daysDiff} day`;
  }

  if (daysDiff < 7) {
    return `${daysDiff} days`;
  }

  return formatCreateDate(date);
};

export const formatCreateDate = (date: number) => moment(date).format('D MMM YYYY');

const allowAll = () => ({
  allowComments: true,
  allowRating: true,
  allowLike: true,
  allowBookmarks: true,
});

export const initBehaviour = (type: BountyType | ResponseType) => {
  let bountyOps = {
    allowComments: false,
    allowRating: false,
    allowLike: false,
    allowBookmarks: false,
    commentAsIcon: false,
  };

  let responseOps = {
    allowComments: false,
    allowRating: false,
    allowLike: false,
    allowBookmarks: false,
    commentAsIcon: false,
  };

  const behaviour: BountyBehaviour = {} as BountyBehaviour;

  behaviour.defaultResponseSorting = SortBy.BountyCreated;
  behaviour.closable = true;
  behaviour.noShare = true;
  behaviour.onlyPrivateResponses = isSurveyType(type);
  behaviour.noExplicitResponses = false;
  behaviour.approvable = false;

  switch (type) {
    case BountyType.Survey:
    case BountyType.Score:
    case BountyType.Checklist:
    case BountyType.MCQ:
    case BountyType.Funding:
      if (type === BountyType.MCQ) {
        behaviour.immutableResponse = true;
      }
      bountyOps = { ...bountyOps, ...allowAll() };
      bountyOps.commentAsIcon = true;
      break;
    case BountyType.Classified:
      bountyOps = { ...bountyOps, ...allowAll() };
      responseOps = { ...responseOps, ...allowAll() };
      responseOps.allowLike = false;
      behaviour.onlyPrivateResponses = true;
      break;
    case BountyType.Thread:
    case BountyType.Match:
    case BountyType.LiveStream:
      bountyOps = { ...bountyOps, ...allowAll() };
      responseOps = { ...responseOps, ...allowAll() };
      bountyOps.allowComments = false;
      responseOps.commentAsIcon = true;
      behaviour.responseAsComments = true;
      break;
    case BountyType.Job:
      bountyOps = { ...bountyOps, ...allowAll() };
      responseOps = { ...responseOps, ...allowAll() };
      bountyOps.allowComments = false;
      behaviour.noShare = false;
      break;
    case BountyType.Banner:
      bountyOps = { ...bountyOps, ...allowAll() };
      responseOps = { ...responseOps, ...allowAll() };
      bountyOps.allowComments = false;
      break;
    default:
      behaviour.allowResponseSorting = true;

      if (type === BountyType.Question) {
        behaviour.defaultResponseSorting = SortBy.BountyPopularity;
      }
      if (type === BountyType.TMOB_Coaching) {
        behaviour.closable = false;
      }

      if (([
        BountyType.TalentSearch,
        BountyType.TalentRecommendation,
        BountyType.RealEstateCustomer,
        BountyType.RealEstateRecommendation,
      ] as BountyType[]).includes(type as BountyType)) {
        behaviour.onlyPrivateResponses = true;
      }

      bountyOps = { ...bountyOps, ...allowAll() };
      responseOps = { ...responseOps, ...allowAll() };
      break;
  }

  return {
    ...behaviour,
    userBountyOps: bountyOps,
    userResponseOps: responseOps,
  };
};

export const getNrOfSubBounties = (bounty: Bounty) => {
  if (!bounty) {
    return 0;
  }

  const { stats } = bounty;
  return (stats && stats.subBounties) || 0;
};

export const getCompanyFolder = (type: BountyType) => SENTS_FOLDER_BY_TYPE[type] || null;

export const isOutboundPost = (metaInfo?: MetaInfo) => metaInfo && metaInfo.outbound;

export const setOutbound = (metaInfo: MetaInfo, isOutbound: boolean) => {
  if (metaInfo) {
    return {
      ...metaInfo,
      outbound: isOutbound,
    };
  }

  return { outbound: isOutbound };
};

export const isCompanySpecial = (type: BountyType) => [BountyType.Match].includes(type);

export const isEditableByEmployee = (bounty: Bounty | OptimizedJobBounty) => ([
  BountyType.Autosuggestion,
  BountyType.PrivateListing,
  BountyType.Product,
  BountyType.Order,
  BountyType.Promotion,
  BountyType.Job,
  BountyType.News,
  BountyType.Banner,
  BountyType.Match,
] as BountyType[]).includes(bounty?.type as BountyType);

export const fromSameCompany = (user: User | null, companyId: string) => (user?.kind === UserKind.Company && user?.id === companyId);

export const isOwnByMyCompany = (bounty: Bounty | OptimizedJobBounty, userData: UserData) => {
  const myCompanyId = userData?.company?.id;

  if (!myCompanyId) {
    return false;
  }

  return fromSameCompany(bounty?.owner, myCompanyId)
    || fromSameCompany(bounty?.creator, myCompanyId)
    || fromSameCompany(bounty?.author, myCompanyId);
};

export const hasSameOwner = (bounty: Bounty, userData: UserData) => bounty?.owner?.id === userData?.owner?.id;

export const hasSuccessMessage = (bounties: { bounty: Bounty }[]) => {
  const questions = bounties.filter(({ bounty }) => bounty?.type === BountyType.Thread);
  return questions.length > 0;
};

export const getValidBountyType = (type: string): BountyType => {
  if (!type) {
    return BountyType.None;
  }

  return Object.values(BountyType).includes(type as BountyType) ? type as BountyType : BountyType.Unknown;
};

export function updateBadges(bounty: any, badgeType: any, mark: boolean) {
  let newBadges = bounty.badges || {};

  if (mark) {
    const badgeInfo = {
      code: badgeType,
      id: badgeType,
      label: getDisplayName(badgeType),
    };
    newBadges = addBadge(newBadges, badgeInfo);
  } else {
    newBadges = removeBadge(newBadges, badgeType);
  }

  return newBadges;
}

export function getDescription({ bounty }: { bounty: Bounty }) {
  if (bounty?.type && isSurveyType(bounty.type)) {
    return getAsSurvey(bounty)?.question || '';
  }

  if (bounty?.type === BountyType.Promotion) {
    return bounty.description?.text ? getDescriptionText(bounty.description) : '';
  }

  if (typeof bounty?.description?.text === 'string') {
    return bounty?.description?.text;
  }

  return '';
}

export function hasExactlyOneImage(type: any) {
  return [BountyType.News].includes(type);
}

export function hasAtLeastOneAttachment(type: any) {
  return [BountyType.Lottery].includes(type);
}

export function getReplyProps(bounty: Bounty, hasMyResponse: boolean, isResponseAsComments: any) {
  const iconStyle = hasMyResponse ? 'primaryIcon' : 'primaryIcon';

  if (isResponseAsComments) {
    return {
      iconStyle,
      icon: 'chat_bubble_outline',
    };
  }

  if (bounty?.type === BountyType.Classified) {
    return {
      iconStyle,
      icon: 'loyalty',
      label: hasMyResponse ? 'menu_see_my_offer' : 'menu_make_an_offer',
    };
  }

  return {
    iconStyle,
    icon: 'reply',
    label: hasMyResponse ? 'lbl_replied' : 'menu_bounty_reply',
  };
}

export function getPromotionCoverUrl(promotion: { products: { [s: string]: unknown; } | ArrayLike<unknown>; url: any; }) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // ТоDo - typescript
  const productDetails = promotion?.products ? Object.values(promotion.products)[0]?.product : {};
  return promotion?.url || productDetails.imageUrl;
}

// ToDo - typescript
export function getProductCoverUrl(product: { product: any; url: any; }) {
  const productDetails = product?.product || {};
  return product?.url || productDetails.imageUrl;
}

export function getQuestionIndex({ bounty, prevIndex }: { bounty: Bounty, prevIndex: number }) {
  if (bounty.type === BountyType.Thread) {
    return 0;
  }

  return prevIndex + 1;
}

export function generateTimeOptions({ minValue, maxValue, step }: { minValue: number; maxValue: number; step: number; }) {
  const options = [];

  for (let value = minValue; value <= maxValue; value += step) {
    options.push({
      label: <FormattedMessage id="bounty.timeInSec" values={{ value }} />,
      value,
    });
  }

  return options;
}

export const generateShowInOptions = (list: StreamListData[]) => {
  const options: { label: StreamListType; value: StreamListType }[] = [];

  list.forEach(({ name, id }) => {
    options.push({
      label: name,
      value: id,
    });
  });

  return options;
};

export const getVisibilityMode = (responseVisibilityMode: VisibilityModeInterface) => (responseVisibilityMode
  ? getEffectiveResponseVisibilityMode(responseVisibilityMode)
  : VisibilityModeType.Default);

export const getVisibilityType = (responseVisibilityMode: VisibilityModeInterface, hasControlResponsesPrivacy: boolean) => {
  const visibilityMode = getVisibilityMode(responseVisibilityMode);

  return hasControlResponsesPrivacy
    ? getVisibilityTypeAsEnum(visibilityMode) === VisibilityModeType.Private
    : null;
};

export const getExpiryComponents = (selectedBounty: Bounty, bountyType: BountyType) => {
  if (selectedBounty?.expiresAt) {
    const time = getExpiryDate(bountyType, selectedBounty).getTime();
    const timeRefMs = selectedBounty.postAt || new Date().getTime();
    return getDaysHrsMinTimeUtilFormat(timeRefMs, time);
  }

  return null;
};

const getDefaultExpiryDate = (bountyType: BountyType) => new Date().getTime() + (bountyType === BountyType.Survey ? DAY_IN_MILLIS : WEEK_IN_MILLIS);

export const getExpiryDate = (bountyType: BountyType, savedData: any) => {
  const expiryDate = savedData.expiresAt || getDefaultExpiryDate(bountyType);
  return new Date(expiryDate);
};

export const getBountyTypeName = (type: BountyType, intl: IntlShape): string =>
  capitalize(BOUNTY_TYPE_LABELS[type] ? intl.formatMessage({ id: BOUNTY_TYPE_LABELS[type] }) : type);
