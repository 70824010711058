import { useState, useEffect, ChangeEvent } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Typography, Box, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { BountyResponse } from 'Types/bountyResponse.interface';
import UploadManager from 'Containers/UploadManager';
import FilesToUpload from 'Components/FilesToUpload';
import LoaderOverlay from 'Components/LoaderOverlay';
import SwitchStyled from 'Components/SwitchStyled';
import MarkdownEditorControl from 'Components/MarkdownEditorControl';
import ReplyTo from 'Components/ReplyTo';
import { userDataSelector, settingsSelector } from 'Store/settings/selectors';
import { createResponse, updateBountyResponse, getNewResponse } from 'Services/response/ResponseService';
import { getDescription } from 'Utils/bounty';
import { getIdentityMode } from 'Utils/bountyCreation';
import * as OpManage from 'Utils/opManager';
import { IdentityType, VisibilityModeType } from 'Constants/enums';
import { getErrorsByName } from 'Utils/formik';
import { replySchema } from 'Utils/validation/reply';
import { selectedBountySelector } from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';
import { ThunkDispatchType } from 'Types/redux.types';
import { IdentityMode } from 'Types/identityMode.interface';
import { eraseAttachments } from 'Store/attachments/actions';
import { Reply } from 'Types/reply.interface';
import { Attachment } from 'Types/attachment.interface';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const AddReply = () => {
  const [identityMode, setIdentityMode] = useState<IdentityMode|null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const userData = useSelector(userDataSelector)?.data || {};
  const settings = useSelector(settingsSelector) || {};
  const bounty = useSelector(selectedBountySelector) || {};

  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const bountyInfo = bounty?.bountyInfo || bounty;
  const response = bounty?.bountyInfo ? bounty : {};
  const isEditMode = !!(bounty?.bountyInfo && Object.keys(bounty?.bountyInfo).length > 0);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      postAnonymously: bounty?.identityMode?.identityType !== IdentityType.Real,
      note: bounty?.note || '',
    },
    onSubmit: async ({ postAnonymously, note }: Reply) => {
      let newResponse = isEditMode ? cloneDeep(bounty) : await getNewResponse(bounty);

      newResponse = {
        ...newResponse,
        note: note || null,
        identityMode: getIdentityMode({
          settings,
          userData,
          anonymity: postAnonymously,
        }),
      };

      return isEditMode
        ? editReply(newResponse)
        : addReply(newResponse);
    },
    validationSchema: replySchema,
  });

  useEffect(() => {
    if (isEditMode) {
      const mode = OpManage.getIdentityMode(settings, userData);
      setIdentityMode(mode);
    } else {
      initAnonymity();
    }
  }, [bounty.id]);

  const initAnonymity = () => {
    const mode = OpManage.getIdentityMode(settings, userData);
    setIdentityMode(mode);
    formik.setFieldValue('postAnonymously', mode.identityType !== IdentityType.Real);
  };

  const isResponsePrivate = () => {
    const visibilityMode = bountyInfo ? bountyInfo.responseVisibilityMode : null;
    return visibilityMode && visibilityMode.visibilityType === VisibilityModeType.Private;
  };

  const addReply = async (newResponse: BountyResponse) => {
    await dispatch(createResponse(bounty, newResponse, userData, attachments));
    handleDismiss();
  };

  const editReply = async (newResponse: BountyResponse) => {
    await dispatch(updateBountyResponse({
      bounty: bountyInfo,
      oldResponse: bounty,
      newResponse,
      attachments,
      userData,
    }));
    handleDismiss();
  };

  const handleDescriptionChanges = (description: string) => {
    formik.setFieldValue('note', description);
  };

  const handleSwitchAnonymity = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('postAnonymously', checked);
  };

  const handleDismiss = () => {
    dispatch(closeDrawer());
    dispatch(eraseAttachments());
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate className={styles.root}>
      <h3>{intl.formatMessage({ id: 'button.reply' })}</h3>
      <ReplyTo
        description={getDescription({ bounty: bountyInfo })}
        className="mb-10"
        truncated
      />
      <UploadManager attachments={attachments} setAttachments={setAttachments} />
      <Typography className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'placeholder.yourMessage' })}
      </Typography>
      <MarkdownEditorControl
        value={formik?.values?.note}
        validationErrors={getErrorsByName(formik, 'note')}
        onChange={handleDescriptionChanges}
        useShortcuts
      />

      {isResponsePrivate() && (
        <Typography>
          {intl.formatMessage({ id: 'label.yourReplyWillBeVisible' })}
        </Typography>
      )}

      {!identityMode?.forced && (
        <FormControlLabel
          label={(
            <Box display="flex" alignItems="center">
              <span>{intl.formatMessage({ id: 'bounty.postAnonymously' })}</span>
              <Tooltip
                id="tooltip-top-start"
                title={intl.formatMessage({ id: 'bounty.anonymityHelpContent' })}
                placement="top-start"
              >
                <span className="material-symbols-rounded ml-10">info</span>
              </Tooltip>
            </Box>
          )}
          name="postAnonymously"
          control={(
            <SwitchStyled
              checked={!!formik?.values?.postAnonymously}
              onChange={handleSwitchAnonymity}
            />
          )}
        />
      )}

      <FilesToUpload
        bounty={bountyInfo}
        response={response}
        attachments={attachments}
        setAttachments={setAttachments}
      />
      <CustomModalButtons
        onDismiss={handleDismiss}
        onSubmit={formik.handleSubmit}
        submitName="button.send"
      />
      {formik.isSubmitting && <LoaderOverlay />}
    </form>
  );
};

export default AddReply;
