import { useIntl } from "react-intl";
import { ChangeEvent, FC } from "react";
import classnames from "classnames";
import { Grid2 as Grid, MenuItem, SelectChangeEvent } from "@mui/material";

import CustomInput from "Components/CustomInput";
import StyledSelect from "Components/StyledSelect";

import { TextLine } from "Types/attachment.interface";
import { FONT_SIZE_OPTIONS, FONT_WEIGHT_OPTIONS } from "Constants/dictionaries";
import { BannerType, TextAlignment } from "Constants/enums";
import * as labelStyles from "Assets/scss/modules/label.module.scss";
import * as styles from "./index.module.scss";

interface TextLineFormProps {
  textLine: TextLine;
  title?: string;
  parentName?: string;
  onChange: (name: string, value: string) => void;
  bannerType: BannerType;
}

const TextLineForm: FC<TextLineFormProps> = ({
  textLine,
  title,
  onChange,
  parentName,
  bannerType,
}) => {
  const intl = useIntl();
  const isHero = bannerType === BannerType.Hero;

  const handleChangeEvent = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    onChange(name, value);
  };

  return (
    <div>
      {title && <h3 className="mb-10">{title}</h3>}
      <Grid container spacing={2}>
        <Grid size={{ xs: 6, lg: isHero ? 3 : 4 }}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.text' })}
          </label>
          <CustomInput
            name={`${parentName}.text`}
            value={textLine?.text || ''}
            onChange={handleChangeEvent}
          />
        </Grid>
        <Grid size={{ xs: 6, lg: isHero ? 3 : 4 }}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.fontSize' })}
          </label>
          <StyledSelect
            name={`${parentName}.fontSize`}
            value={textLine?.fontSize}
            onChange={(e) => handleChangeEvent(e as SelectChangeEvent)}
          >
            {FONT_SIZE_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        <Grid size={{ xs: 6, lg: isHero ? 3 : 4 }}>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.fontWeight' })}
          </label>
          <StyledSelect
            name={`${parentName}.fontWeight`}
            value={textLine?.fontWeight}
            onChange={(e) => handleChangeEvent(e as SelectChangeEvent)}
          >
            {FONT_WEIGHT_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        {isHero && (
          <Grid size={{ xs: 6, lg: 3 }}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'label.alignment' })}
            </label>
            <div className={styles.alignmentSection}>
              <div
                className={classnames(
                  styles.button,
                  { [styles.activeAlignment]: textLine?.alignment === TextAlignment.Left }
                )}
                onClick={() => onChange(`${parentName}.alignment`, TextAlignment.Left)}
              >
                {intl.formatMessage({ id: 'button.left' })}
              </div>
              <div
                className={classnames(
                  styles.button,
                  "text-align-center",
                  { [styles.activeAlignment]: textLine?.alignment === TextAlignment.Center }
                )}
                onClick={() => onChange(`${parentName}.alignment`, TextAlignment.Center)}
              >
                {intl.formatMessage({ id: 'button.center' })}
              </div>
              <div
                className={classnames(
                  styles.button,
                  "text-align-right",
                  { [styles.activeAlignment]: textLine?.alignment === TextAlignment.Right }
                )}
                onClick={() => onChange(`${parentName}.alignment`, TextAlignment.Right)}
              >
                {intl.formatMessage({ id: 'button.right' })}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TextLineForm;