import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

//  Component

//  Actions/Selectors
import { closeModal } from 'Store/modal/actions';
import { dismissBounty } from 'Services/bounty/BountyService';
import { authLoggedUserSelector } from 'Store/auth/selectors';

//  Other resources
import { DismissReasons } from 'Constants/enums';
import { Bounty } from 'Types/bounty.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

interface DismissBountyProps {
  bounty: Bounty;
}

const DismissBounty:FC<DismissBountyProps> = ({ bounty }) => {
  const user = useSelector(authLoggedUserSelector)?.data || {};

  const [reason, setReason] = useState<DismissReasons | ''>('');
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const handleChanges = (value: DismissReasons) => {
    setReason(value);
  };

  const handleSubmit = async () => {
    if (!reason) {
      return notificationToast.info(intl.formatMessage({ id: 'info.pleaseSelectReason' }));
    }

    const isMine = bounty.creator && bounty.creator.id === user.uid;

    if (isMine) {
      return notificationToast.warning(intl.formatMessage({ id: 'label.dismissOwnPost' }));
    }

    try {
      await dismissBounty({ bounty, reason });
      if (reason === DismissReasons.TooSmall) {
        notificationToast.info(intl.formatMessage({ id: 'info.notifyAuthor' }));
      }
      dispatch(closeModal());
    } catch (error: any) { // TODO: [A] Define FB error later
      const errorMessage = error?.message || intl.formatMessage({ id: 'errors.somethingWentWrong' });
      notificationToast.error(errorMessage);
    }
  };

  return (
    <div>
      <div className={styles.root}>
        <div
          role="presentation"
          className={classnames(styles.item, {
            [styles.active]: reason === DismissReasons.DontKnow,
          })}
          onClick={() => handleChanges(DismissReasons.DontKnow)}
        >
          <span className={classnames('icon-message-question', styles.icon)} />
          <span>{intl.formatMessage({ id: 'label.dismissDontKnowTheAnswer' })}</span>
        </div>

        <div
          role="presentation"
          className={classnames(styles.item, {
            [styles.active]: reason === DismissReasons.TooSmall,
          })}
          onClick={() => handleChanges(DismissReasons.TooSmall)}
        >
          <span className={classnames('icon-info-circle', styles.icon)} />
          <span>{intl.formatMessage({ id: 'label.dismissRewardTooSmall' })}</span>
        </div>

        <div
          role="presentation"
          className={classnames(styles.item, {
            [styles.active]: reason === DismissReasons.NoInterest,
          })}
          onClick={() => handleChanges(DismissReasons.NoInterest)}
        >
          <span className={classnames('icon-slash', styles.icon)} />
          <span>{intl.formatMessage({ id: 'label.dismissNotInterested' })}</span>
        </div>
      </div>

      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default DismissBounty;
