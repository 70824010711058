import { BannerType, FontSize, ObjectFit, TextAlignment } from "Constants/enums";
import { Attachment, Background, Overlay, TextLine } from "Types/attachment.interface";

export const getInitialValues = (attachment: Attachment, bannerType: BannerType) => {
  const isHero = bannerType === BannerType.Hero;
  const defaultHeadlineText1 = isHero ? '' : 'Half Oz for $99';
  const defaultHeadlineText2 = isHero ? '' : 'FLOWER';

  return {
    ...(isHero
      ? {
        background: {
          color: attachment?.background?.color || '#ffffff',
          objectFit: attachment?.background?.objectFit || ObjectFit.Cover,
        },
        overlay: {
          color: attachment?.overlay?.color || '#ffffff',
          opacity: attachment?.overlay?.opacity || 0,
        },
      } : {}),
    headline1: {
      text: attachment?.headline1?.text || defaultHeadlineText1,
      fontSize: attachment?.headline1?.fontSize || FontSize.Large,
      fontWeight: attachment?.headline1?.fontWeight || 700,
      alignment: isHero
        ? attachment?.headline1?.alignment || TextAlignment.Left
        : TextAlignment.Center,
    },
    headline2: {
      text: attachment?.headline2?.text || defaultHeadlineText2,
      fontSize: attachment?.headline2?.fontSize || FontSize.Medium,
      fontWeight: attachment?.headline2?.fontWeight || 400,
      alignment: isHero
        ? attachment?.headline2?.alignment || TextAlignment.Left
        : TextAlignment.Center,
    },
    ...(bannerType === BannerType.Specials
      ? {
        action: {
          ...attachment?.action,
          btnLabel: attachment?.action?.btnLabel || 'Buy Now',
        }
      } : {})
  };
};

export const getFontSizeEquivalent = (fontSize: FontSize) => {
  if (fontSize === FontSize.Small) {
    return {
      fontSize: '16px',
      lineHeight: '21px',
    };
  }

  if (fontSize === FontSize.Medium) {
    return {
      fontSize: '24px',
      lineHeight: '34px',
    };
  }

  if (fontSize === FontSize.Large) {
    return {
      fontSize: '40px',
      lineHeight: '52px',
    };
  }

  return {
    fontSize: '16px',
    lineHeight: '21px',
  };
};

export const getHeadlineStyle = (headline: TextLine)=> ({
  ...getFontSizeEquivalent(headline.fontSize),
  fontWeight: headline?.fontWeight,
  ...(headline?.alignment ? { textAlign: headline?.alignment } : {}),
});

export const getImageStyle = (image?: Background) => (
  image
    ? {
      backgroundColor: image?.color,
      objectFit: image?.objectFit,
    } : {}
);

export const getOverlayStyle = (overlay?: Overlay) => (
  overlay
    ? {
      backgroundColor: overlay?.color,
      opacity: +overlay?.opacity,
    } : {}
);