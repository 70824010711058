import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { sendBountyToUser } from 'Services/bounty/BountyService';
import { closeModal } from 'Store/modal/actions';
import { notificationToast } from 'Utils/notificationToaster';
import { ThunkDispatchType } from 'Types/redux.types';
import LoaderOverlay from 'Components/LoaderOverlay';
import { firebaseGetSentRef } from 'Services/FirebaseService';
import { convertObjToArray } from 'Utils/helpers';
import { Bounty } from 'Types/bounty.interface';
import * as userSelectors from 'Store/auth/selectors';
import { getDescription } from 'Utils/bounty';
import { BountyState, BountyCardType } from 'Constants/enums';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const BOUNTIES_LIMIT = 150;

const SendBounty = () => {
  const loggedUserId = useSelector(userSelectors.authUserUidSelector) || '';
  const [userSents, setUserSents] = useState<{ bounty: Bounty }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBounties, setSelectedBounties] = useState<{ [key: string]: Bounty }>({});
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();
  const { userId } = useParams();

  useEffect(() => {
    setIsLoading(true);

    firebaseGetSentRef(loggedUserId)
      .limitToFirst(BOUNTIES_LIMIT)
      .orderByChild('bounty/state')
      .equalTo(BountyState.Active)
      .once('value', (dataSnapshot) => {
        const sents = convertObjToArray(dataSnapshot.val());
        const bounties: { bounty: Bounty }[] = [];

        sents.forEach((card) => {
          const cardType = Object.keys(card)[0];

          if (cardType === BountyCardType.bounty && card[cardType].id) {
            bounties.push(card);
          }
        });

        setUserSents(bounties);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (bounty: Bounty) => () => {
    setSelectedBounties((prevSelectedBounties) => {
      const updatedBounties = { ...prevSelectedBounties };

      if (updatedBounties[bounty.id]) {
        delete updatedBounties[bounty.id];
      } else {
        updatedBounties[bounty.id] = bounty;
      }

      return updatedBounties;
    });
  };

  const sendBountiesToUser = () => {
    const selectedBountiesList: Bounty[] = Object.values(selectedBounties);

    if (selectedBountiesList.length) {
      selectedBountiesList.forEach((bounty) => sendBountyToUser({ bounty, userId }));
      dispatch(closeModal());
      notificationToast.success(intl.formatMessage({ id: 'notification.success.postSent' }));
    }
  };

  return (
    <div>
      <div className={`${styles.root} mb-10`}>
        {!isLoading && userSents?.map((card) => {
          const bountyDescription = getDescription({ bounty: card.bounty });

          return (
            <div key={card.bounty.id}>
              <FormControlLabel
                control={(
                  <Checkbox
                    name={card.bounty.id}
                    checked={!!selectedBounties[card.bounty.id]}
                    onChange={handleChange(card.bounty)}
                    color="primary"
                  />
                )}
                label={bountyDescription}
              />
            </div>
          );
        })}
        {isLoading && <LoaderOverlay />}
      </div>
      <div className={styles.navigation}>
        <CustomModalButtons
          onDismiss={() => dispatch(closeModal())}
          onSubmit={sendBountiesToUser}
        />
      </div>
    </div>
  );
};

export default SendBounty;
