import { FC, useCallback, useEffect } from 'react';
import { IconButton, ImageList, ImageListItem } from '@mui/material';
import classnames from 'classnames';

import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { Attachment } from 'Types/attachment.interface';
import { BountyType, AttachmentType } from 'Constants/enums';
import Image from 'Components/Image';
import Pdf from 'Components/Pdf';
import { getAttachmentsWithoutCustomMeaning } from 'Utils/attachments';
import Media from 'Components/Media';

import * as styles from './index.module.scss';

interface RenderFilesToUploadProps {
  bounty?: Bounty;
  response?: BountyResponse;
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
  onRemove?: (attachment: Attachment) => void;
  cols?: number;
}

const RenderFilesToUpload:FC<RenderFilesToUploadProps> = ({
  bounty,
  response,
  attachments = [],
  setAttachments,
  onRemove,
  cols = 2,
}) => {
  const setInitialAttachments = useCallback(() => {
    let initialAttach: Attachment[] = [];

    if (response) {
      initialAttach = response.attachments && response.attachments.attachments
        ? Object.values(response.attachments.attachments)
        : [];
    } else if (bounty) {
      initialAttach = getAttachmentsWithoutCustomMeaning(bounty);

      if (bounty.type === BountyType.Product && bounty.product && bounty.product.imageUrl) {
        const isAlreadyAttach = initialAttach.find((attach) => attach.url === bounty.product?.imageUrl) || null;

        if (!isAlreadyAttach) {
          initialAttach.push({
            type: AttachmentType.Image,
            url: bounty.product.imageUrl,
            onlyAsUrl: true,
          } as Attachment);
        }
      }
    }

    setAttachments(initialAttach);
  }, [bounty, response]);

  useEffect(() => {
    if (bounty || response) {
      setInitialAttachments();
    }
  }, []);

  const handleRemoveAttachment = (index:number) => {
    if (onRemove) {
      onRemove(attachments[index]);
    }

    setAttachments(attachments?.filter((_, i) => i !== index));
  };

  return (
    <ImageList
      sx={{ maxHeight: 250 }}
      cols={cols}
      rowHeight={150}
      gap={10}
      className={classnames({ 'mt-10 mb-10': attachments.length, 'mb-0': !attachments.length })}
    >
      {attachments.map((attachment, key) => {
        const fileUrl = attachment?.cdnUrl || attachment?.url || attachment?.localUri || attachment?.fileBlob;

        if (!fileUrl) {
          return null;
        }

        return (
          <ImageListItem key={key}>
            {(attachment.type === AttachmentType.Image || attachment.type === AttachmentType.GIF) && (
              <Image
                className={classnames('image-post-v2', styles.imageItem)}
                alt="cropped_img"
                src={fileUrl}
              />
            )}
            {attachment.type === AttachmentType.PDF && <Pdf url={fileUrl} width={250} />}
            {[AttachmentType.Video, AttachmentType.Audio].includes(attachment.type) && <Media url={fileUrl} controls />}
            {!attachment.onlyAsUrl && (
              <IconButton
                color="secondary"
                className={styles.removeButton}
                size="small"
                onClick={() => handleRemoveAttachment(key)}
              >
                <span className={`icon-add ${styles.closeIcon}`} />
              </IconButton>
            )}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default RenderFilesToUpload;
