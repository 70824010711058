import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';

import { notificationToast } from 'Utils/notificationToaster';
import { userProfilePINSchema } from 'Utils/validation/userProfilePIN';
import { getErrorsByName } from 'Utils/formik';

import { getUserSelector } from 'Store/user/selectors';
import { updatePIN } from 'Store/user/actions';
import { closeModal } from 'Store/modal/actions';

import CustomInput from 'Components/CustomInput';

import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';

const UserProfileEditPIN = () => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const { id, systemInfo: { pin } } = useSelector(getUserSelector);

  const handlePINChange = ({ pin: newPin }: { pin: string }) => {
    if (newPin !== pin) {
      dispatch(updatePIN(id, newPin));
    } else {
      notificationToast.error(intl.formatMessage({ id: 'validation.message.pin.samePIN' }));
    }
    dispatch(closeModal());
  };

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, value);
  };

  const formik = useFormik({
    initialValues: {
      pin,
    },
    validationSchema: userProfilePINSchema,
    onSubmit: handlePINChange,
  });

  return (
    <>
      <CustomInput
        name="pin"
        value={formik?.values?.pin}
        placeholder={intl.formatMessage({ id: 'label.enterThePin' })}
        validationErrors={getErrorsByName(formik, 'pin')}
        onChange={handleChanges}
      />
      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={formik.handleSubmit}
      />
    </>
  );
};

export default UserProfileEditPIN;
