/**
 * Helpers Functions
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import { QUEUES_BASE, QUEUES_INDEX } from 'Constants/queues';
import User = firebase.User;

const { auth, database } = firebase;

/**
 * Firebase Get Chat Messages Ref
 */
export const firebaseGetChatMessagesRef = (roomId: string) => database().ref(`chatMessages/${roomId}`);
export const firebaseGetMessageRef = (roomId: string, messageId: string) => database().ref(`chatMessages/${roomId}/${messageId}`);

/**
 * Firebase Get User Chat Ref
 */
export const firebaseGetUserChatRef = (userId: string) => database().ref(`userChats/${userId}`);

/**
 * Firebase Get User Chat Room Ref
 */
export const firebaseGetUserChatRoomRef = (userId: string) => database().ref(`userChats/${userId}`);
export const firebaseGetUserDataRef = (userId: string) => database().ref(`userData/${userId}`);

export const getMyUserAddressesRef = (userId: string) => firebaseGetUserDataRef(userId).child('addresses');
export const getMyUserPaymentsRef = (userId: string) => firebaseGetUserDataRef(userId).child('payments');
export const getUserChatRoomRef = (userId: string, roomId: string) => firebaseGetUserChatRoomRef(userId).child(roomId);

/**
 * Firebase Get User Chat Ref
 */
export const firebaseGetClientsRef = (userPin: string) => database().ref('clients').child(userPin);

/**
 * Firebase Get User Streams Ref
 */
export const firebaseGetNotificationsRef = (userId: string) => database().ref(`userNotifications/${userId}`);

/**
 * Firebase Get User Streams Ref
 */
export const firebaseGetSentRef = (userId: string | undefined) => database().ref(`userSents/${userId}`);
export const firebaseGetSentSubsRef = (userId: string) => database().ref(`userSentSubs/${userId}`);

/**
 * Firebase Get Bounty Comments Ref
 */
export const firebaseGetUserCommentsRef = () => database().ref('userComments');

/**
 * Firebase Get Bounty Responses Ref
 */
export const firebaseGetUserResponsesRef = (ownerId: string) => database().ref(`bountyResponses/${ownerId}`);

export const firebaseGetCurrentUser: () => User = () => auth().currentUser || {} as User;

/**
 * Firebase Get currently signed in user
 * The recommended way to get the current user is by setting an observer on the Auth object
 * Note: currentUser might also be null because the auth object has not finished initializing.
 * If you use an observer to keep track of the user's sign-in status, you don't need to handle this case
 */
export const firebaseGetCurrentlySignedInUser = async () => (
  new Promise((resolve, reject) => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        return user.getIdToken()
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      }

      return resolve(null);
    });
  })
);

// ToDo: Typescript write an enum collection for all queues
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
export const firebaseQueuesRef = (queueName: string) =>
  database().ref(QUEUES_BASE).child(queueName).child(QUEUES_INDEX);

export const firebaseGetMyAccountRef = (userId: string) => database().ref(`userAccounts/${userId}`);

export const firebaseGetUserListsRef = ({ ownerId, userId }: {ownerId: string, userId: string}) => database().ref(`userLists/${ownerId}/${userId}`);
export const firebaseGetMyFavorites = ({ ownerId, userId }: {ownerId: string, userId: string}) => (
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites'));

export const firebaseGetMyProfileRef = (userId: string) => database().ref('userProfiles').child(userId);
export const firebaseGetUserRef = (userId: string) => database().ref('users').child(userId);

export const firebaseGetProductsRef = (promotionId: string) => (
  database().ref(`promotionalOffers/${promotionId}/products`)
);

export const firebaseGetPromotionalOffersRef = () => database().ref('promotionalOffers');

export const firebaseGetCurrentUserProviderId = () =>
  (firebaseGetCurrentUser() as User)?.providerData?.[0]?.providerId || '';

/**
 * Firebase Company Refs
 */
export const firebaseGetMyCompanyRef = (companyId: string) => database().ref('companies').child(companyId);
export const firebaseGetMyCompanySettingsRef = (companyId: string) => (
  firebaseGetMyCompanyRef(companyId).child('settings'));
export const firebaseGetCompanySents = (ownerId: string, type: string) => database().ref(`companySents/${ownerId}/${type}`);
export const firebaseGetCompanySentSubsRef = (ownerId: string, type: string) => database().ref(`companySentSubs/${ownerId}/${type}`);
export const firebaseGetOutboundBountyRef = (ownerId: string, type: string, bountyId: string) =>
  database().ref(`companySents/${ownerId}/${type}`)
    .orderByChild('bounty/id')
    .equalTo(bountyId);
export const firebaseGetCompanyDataRef = (companyId: string) => database().ref(`companyData/${companyId}`);
export const getCompanyPaymentsRef = (companyId: string) => firebaseGetUserDataRef(companyId).child('payments');

/**
 * Firebase User Refs
 */
export const getUserSpecialsRef = (userId: string) => database().ref(`userSpecials/${userId}`);
export const getPersonalBountyRef = (userId: string, bountyId: string) => database().ref(`personal/users/${userId}/bounties/${bountyId}/personal`);
export const getPersonalResponseRef = (userId: string, responseId: string) => database().ref(`personal/users/${userId}/responses/${responseId}/personal`);

/**
 * Firebase Common Refs
 */
export const getCommonBountyStatsRef = (bountyId: string) => database().ref(`common/bounties/${bountyId}/stats`);
export const getCommonResponseStatsRef = (responseId: string, bountyId: string) => database().ref(`common/responses/${bountyId}/${responseId}/stats`);
export const getCommonBountyContentRef = (bountyId: string) => database().ref(`/common/bounties/${bountyId}/content`);

/**
 * Firebase subcards refs
 */
export const getMySubCardsRef = (userId: string) => database().ref(`userSubCards/${userId}`);

export const getCompanyOrderStats = (companyId: string) => database().ref(`orderStats/${companyId}/count`);
export const getCompanyOrders = (companyId: string) => database().ref(`orders/${companyId}`);
export const getCompanySubCardsRef = (ownerId: string) => database().ref(`companySubCards/${ownerId}`);
export const getCompanySpecialsRef = (ownerId: string) => database().ref(`companySpecials/${ownerId}`);
export const getCompanyOrdersRef = (ownerId: string) => getCompanySpecialsRef(ownerId).child('orders');
export const getCompanyOrderRef = (ownerId: string, orderId: string) => (
  getCompanySpecialsRef(ownerId)
    .child(`orders/${orderId}`)
    .child('bounty'));

export const getListStreamSubRef = (ownerId: string, bountyId: string, listId: string) => (
  database().ref(`listStreamSubs/${ownerId}/${listId}/${bountyId}`)
);

/**
 * Firebase Order Refs refs
 */
export const firebaseGetOrdersRefs = (userId: string) => getUserSpecialsRef(userId).child('orders');
export const firebaseGetOrderDetailsRef = (userId: string, bountyId: string) => (
  getUserSpecialsRef(userId)
    .child(`orders/${bountyId}`)
    .child('bounty'));

/**
 * Firebase responses refs
 */
export const getInboundResponsesRef = (ownerId: string, responseKey: string) => (
  database().ref(`bountyResponses/${ownerId}/${responseKey}`));

export const getSocialActionsRef = (userId: string) => {
  const currentUser: Partial<User> = firebaseGetCurrentUser();
  return database().ref('/userSocials').child(currentUser.uid as string).child(userId);
};

export const getSocialActionStateRef = (userId: string, socialActionType: string) => (
  getSocialActionsRef(userId).child(socialActionType)
);

export const getMyBlocksRef = (userId: string) => (database().ref(`/userBlocks/${userId}`));

export const getUserShoppingCartsRef = (userId: string) => database().ref(`shoppingCarts/${userId}`);

export const firebaseGetGroupMembers = (groupId: string) => database().ref('groupMembers').child(groupId);
export const firebaseGetGroupMemberRef = (groupId: string, memberId: string) => firebaseGetGroupMembers(groupId).child(memberId);
export const firebaseGetGroupsRef = () => database().ref('groups');
export const firebaseGetGroupRef = (groupId: string) => firebaseGetGroupsRef().child(groupId);
export const firebaseGetGroupSubCardsRef = () => database().ref('groupSubCards');

export const firebaseGetSystemSettingsRef = () => database().ref('system/system/settings');
export const firebaseGetSystemInfoRef = () => database().ref('system/info');
export const firebaseGetOrgTypeSettingsRef = (orgType: string) => (
  database().ref('/system/orgTypes')
    .child(orgType)
    .child('settings')
);

export const firebaseGetTimestamp = () => database.ServerValue.TIMESTAMP;

export const getMyDealsRef = (userId: string) => getUserSpecialsRef(userId).child('deals');

export const getDealBounty = (userId: string, bountyId: string) => getMyDealsRef(userId).child(bountyId).child('bounty');

/**
 * Firebase sync ref
 */
export const getCompanySyncReportsRef = (companyId: string, syncType: string) => (
  firebaseGetCompanyDataRef(companyId).child('syncReports').child(syncType)
);
