import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { Rating } from '@mui/material';
import { ChangeEvent, FC, SyntheticEvent } from 'react';

import ReplyTo from 'Components/ReplyTo';
import CustomTextarea from 'Components/CustomTextarea';

import { closeModal } from 'Store/modal/actions';
import { onReviewProduct } from 'Services/ProductService';

import { getErrorsByName } from 'Utils/formik';
import { reviewProductSchema } from 'Utils/validation/product';
import { notificationToast } from 'Utils/notificationToaster';
import { Bounty } from 'Types/bounty.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';

interface ProductReviewProps {
  bounty: Bounty;
}

const ProductReview: FC<ProductReviewProps> = (props) => {
  const { bounty } = props;
  const { product } = bounty;
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const handleSubmit = () => {
    const { reviewDescription, rating } = formik?.values || {};

    if (!rating) {
      notificationToast.warning(intl.formatMessage({ id: 'lbl_give_a_rating' }));
      return;
    }

    dispatch(onReviewProduct(bounty, reviewDescription, rating));
    dispatch(closeModal());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reviewDescription: '',
      postAnonymously: false,
      rating: 0,
    },
    onSubmit: handleSubmit,
    validationSchema: reviewProductSchema,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldValue(name, value);
  };

  const handleRatingChanges = (e: SyntheticEvent<Element, Event>, value: number | null) => {
    formik.setFieldValue('rating', value);
  };

  return (
    <div>
      {product?.name && <ReplyTo description={product.name} className="mb-30" />}
      <Rating
        name="rating"
        size="large"
        value={Math.round(formik?.values?.rating || 0)}
        onChange={handleRatingChanges}
      />
      <CustomTextarea
        name="reviewDescription"
        value={formik?.values?.reviewDescription}
        placeholder={intl.formatMessage({ id: 'placeholder.write.product.review' })}
        onChange={handleChanges}
        validationErrors={getErrorsByName(formik, 'reviewDescription')}
      />
      <CustomModalButtons
        onDismiss={() => dispatch(closeModal())}
        onSubmit={formik.handleSubmit}
      />
    </div>
  );
};

export default ProductReview;
