const jobTitleRegex = /^[\p{Letter}0-9!_+ \-/[\]{};()':"\\|,.]*$/gum;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const colorHexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/i;
const phoneRegex = /^[+0-9 .]+$/;
const slugRegex = /^[a-z][a-z0-9-]{3,32}$/;

export {
  jobTitleRegex,
  emailRegex,
  colorHexRegex,
  phoneRegex,
  slugRegex,
};
