import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { cloneDeep, merge, set } from "lodash";

import CustomDropzone from "Components/CustomDropzone";
import FilesToUpload from "Components/FilesToUpload";
import StyledSelect from "Components/StyledSelect";
import LoaderOverlay from "Components/LoaderOverlay";
import CategorySelection from "Containers/BannerCreation/CategorySelection";
import ProductsSelection from "Containers/BannerCreation/ProductsSelection";
import SimpleButton from "Components/SimpleButton";
import AttachmentConfigurationDialog from "Containers/AttachmentConfigurationDialog";

import { VIDEO_MIME_TYPES } from "Constants/attachment";
import { fetchClient, fetchParentClient } from "Store/client/actions";
import { Attachment } from "Types/attachment.interface";
import { setAttachments, setInlineAttachments, updateAttachment, } from "Store/attachments/actions";
import { notificationToast } from "Utils/notificationToaster";
import { ThunkDispatchType } from "Types/redux.types";
import { orderAttachments, prepareAcceptType } from "Utils/attachments";
import { attachmentsListSelector } from "Store/attachments/selectors";
import { AdvertPointerType, AttachmentAction, BannerType, BountyType, CompanyType } from "Constants/enums";
import { Bounty } from "Types/bounty.interface";
import { ADVERT_POINTER_OPTIONS } from "Constants/dictionaries";
import { companySettingsSelector, userDataSelector } from "Store/settings/selectors";
import { clientSelector, parentClientSelector } from "Store/client/selectors";
import { Client, ClientInfo } from "Types/client.interface";
import * as labelStyles from "Assets/scss/modules/label.module.scss";
import * as styles from "./index.module.scss";

interface SpecialsManagementProps {
  selectedCompanyId?: string;
  bounty: Bounty;
}

const SpecialsManagement: FC<SpecialsManagementProps> = ({
  selectedCompanyId,
  bounty,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();
  const attachments: Attachment[] = useSelector(attachmentsListSelector) || [];
  const userData = useSelector(userDataSelector)?.data || {};
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const client = useSelector(clientSelector) || {} as Client;
  const parentClient = useSelector(parentClientSelector) || {} as ClientInfo;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pointersType, setPointersType] = useState<AdvertPointerType[]>([]);

  const isMerchant = currentOrgType === CompanyType.Merchant;
  const companyId = isMerchant ? userData?.company?.id : selectedCompanyId;
  const parentId = client?.clientInfo?.parentId || '';

  useEffect(() => {
    if (companyId) {
      dispatch(fetchClient(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    if (parentId) {
      dispatch(fetchParentClient(parentId));
    }
  }, [parentId]);

  useEffect(() => {
    if (bounty) {
      const existingAttachments = bounty?.attachments?.attachments || {};
      const newPointersType: AdvertPointerType[] = [];
      const orderedAttachments = orderAttachments(Object.values(existingAttachments));

      dispatch(setAttachments({
        attachments: existingAttachments,
        inlineAttachments: orderedAttachments,
      }));
      Object.values(orderedAttachments)?.map((attachment) => {
        newPointersType.push(attachment?.action?.bountyIds
          ? AdvertPointerType.Products
          : AdvertPointerType.Category);
      });
      setPointersType(newPointersType);
    }
  }, [bounty]);

  const handlePickFile = (data: Attachment[], index: number) => {
    dispatch(updateAttachment(merge(cloneDeep(attachments[index]), data[0]), index));
    notificationToast.success(<FormattedMessage id="notification.success.videoUploadedSuccessfully" />);
  };

  const handleChangeAttachments = (newAttachments: Attachment[], index: number) => {
    const updatedAttachments = cloneDeep(attachments);
    updatedAttachments[index] = newAttachments[0];
    dispatch(setInlineAttachments(updatedAttachments));
  };

  const handleUpdate = (attach: Partial<Attachment>, index: number) => {
    dispatch(updateAttachment(attach, index));
  };

  const handleSelect = ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => {
    setPointersType((prevState) => {
      const newState = [...prevState];
      newState[index] = value as AdvertPointerType;
      return newState;
    });
    const newAttachment = set(cloneDeep(attachments?.[index]), 'action', { actionType: AttachmentAction.Search });
    handleUpdate(newAttachment, index);
  };

  const handleSelectProduct = (newSelectedProducts: Bounty[], index: number) => {
    const action = {
      ...attachments?.[index]?.action,
      bountyIds: newSelectedProducts?.map(({ id }) => id).join(','),
      bountyType: BountyType.Product,
    };
    const newAttachment = set(cloneDeep(attachments?.[index]), 'action', action);

    dispatch(updateAttachment(newAttachment, index));
  };

  const handleAddSpecial = () => {
    dispatch(setInlineAttachments([...attachments, { action: { actionType: AttachmentAction.Search } }]));
    setPointersType((prevState) => [...prevState, AdvertPointerType.Category]);
  };

  return (
    <div>
      <h4>{intl.formatMessage({ id: 'label.specials' })}</h4>

      <SimpleButton
        label={intl.formatMessage({ id: 'button.addSpecial' })}
        onClick={handleAddSpecial}
        className={styles.addButton}
      />

      {attachments?.length > 0 && (
        <div className={styles.content}>
          {attachments?.map((attachment, index) => {
            const bountyIds = attachment?.action?.bountyIds;
            const selectedProducts = bountyIds
              ? bountyIds?.split(',')?.map((id) => ({ id }) as Bounty)
              : [];

            return (
              <div key={`${attachment?.filename}-${index}`} className={styles.specialWrapper}>
                <h4>
                  {`${intl.formatMessage({ id: 'label.special' })} - ${index + 1}`}
                </h4>

                {!(attachment?.localUri || attachment?.url) && (
                  <CustomDropzone
                    accept={prepareAcceptType(VIDEO_MIME_TYPES)}
                    onFilePick={(attach: Attachment[]) => handlePickFile(attach, index)}
                  >
                    <div className={styles.uploadButton}>
                      <span className="material-symbols-rounded font-24 mr-5">
                        <span className="upload" />
                      </span>
                      <b>{intl.formatMessage({ id: 'button.uploadVideo' })}</b>
                    </div>
                  </CustomDropzone>
                )}

                <FilesToUpload
                  setAttachments={(attachments) => handleChangeAttachments(attachments, index)}
                  attachments={[attachment]}
                  cols={1}
                />

                <div>
                  <label className={labelStyles.fieldLabel}>
                    {intl.formatMessage({ id: 'label.target' })}
                  </label>
                  <StyledSelect
                    name="pointerType"
                    value={pointersType[index]}
                    onChange={(e) => handleSelect(e, index)}
                  >
                    {ADVERT_POINTER_OPTIONS.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>

                {pointersType[index] === AdvertPointerType.Category && (
                  <CategorySelection
                    companyId={companyId}
                    onChange={(attach) => handleUpdate(attach, index)}
                    attachment={attachment}
                    onLoading={setIsLoading}
                  />
                )}

                {pointersType[index] === AdvertPointerType.Products && (
                  <ProductsSelection
                    companyId={companyId}
                    className="mt-15"
                    onSelectProducts={(bounties) => handleSelectProduct(bounties, index)}
                    selectedProducts={selectedProducts}
                    hasMultiSelect
                  />
                )}

                <div className="mt-15">
                  <AttachmentConfigurationDialog
                    attachment={attachment}
                    onChange={(attach) => handleUpdate(attach, index)}
                    bannerType={BannerType.Specials}
                    branding={client?.clientInfo?.branding || parentClient?.branding}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {isLoading && <LoaderOverlay />}
    </div>
  );
};

export default SpecialsManagement;