import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { FC } from "react";

import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';

interface CustomModalButtonsProps {
  dismissName?: string;
  submitName?: string;
  className?: string;
  btnClassName?: string;
  onDismiss: () => void;
  onSubmit: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const CustomModalButtons: FC<CustomModalButtonsProps> = ({
  disabled = false,
  isLoading = false,
  dismissName = 'button.cancel',
  onDismiss,
  onSubmit,
  submitName = 'button.save',
  className = '',
  btnClassName = '',
}) => {
  const intl = useIntl();
  const translatedDismissName = intl.formatMessage({ id: dismissName });
  const translatedSubmitName = intl.formatMessage({ id: submitName });

  return (
    <div className={classnames('text-right mt-20 d-flex', className)}>
      <SimpleButton
        label={translatedDismissName}
        onClick={onDismiss}
        disabled={isLoading}
        decoration={SimpleButtonDecoration.Stroke}
      />
      <SimpleButton
        label={translatedSubmitName}
        onClick={onSubmit}
        disabled={disabled}
        isLoading={isLoading}
        className={classnames(btnClassName, 'ml-10')}
      />
    </div>
  );
};

export default CustomModalButtons;
