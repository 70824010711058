import * as Yup from 'yup';
import { FormattedMessage } from "react-intl";
import { colorHexRegex } from "Utils/validation/regexCollection";
import { BannerType } from "Constants/enums";

export const heroBannerSchema = Yup.object({
  background: Yup.object({
    color: Yup
      .string()
      .matches(colorHexRegex, { message: <FormattedMessage id="validate.hex_color" /> }),
    objectFit: Yup.string(),
  }),
  overlay: Yup.object({
    color: Yup
      .string()
      .matches(colorHexRegex, { message: <FormattedMessage id="validate.hex_color" /> }),
    opacity: Yup
      .number()
      .min(0, <FormattedMessage id="validate.greaterOrEqualValue" values={{ value: 0 }} />)
      .max(1, <FormattedMessage id="validate.lessOrEqualValue" values={{ value: 1 }} />)
  }),
  headline1: Yup.object({
    text: Yup.string(),
    fontSize: Yup.string(),
    fontWeight: Yup.number(),
    alignment: Yup.string(),
  }),
  headline2: Yup.object({
    text: Yup.string(),
    fontSize: Yup.string(),
    fontWeight: Yup.number(),
    alignment: Yup.string(),
  }),
});

export const specialBannerSchema = Yup.object({
  headline1: Yup.object({
    text: Yup.string(),
    fontSize: Yup.string(),
    fontWeight: Yup.number(),
    alignment: Yup.string(),
  }),
  headline2: Yup.object({
    text: Yup.string(),
    fontSize: Yup.string(),
    fontWeight: Yup.number(),
    alignment: Yup.string(),
  }),
  action: Yup.object({
    btnLabel: Yup.string().required(<FormattedMessage id="validate.required_field" />),
  }),
});

export const getAttachmentConfigurationSchema = (bannerType) => {
  if (bannerType === BannerType.Hero) {
    return heroBannerSchema;
  }

  return specialBannerSchema;
};
