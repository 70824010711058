// STANDARD / AUTH
export const NOT_FOUND = '/not-found';
export const HOME_ROUTE = '/';
export const V2_HOME_ROUTE = '/home';
export const SIGN_UP_ROUTE = '/signup';
export const SIGN_IN_ROUTE = '/signin';
export const ONBOARDING_ROUTE = '/onboarding';
export const INVITATION_ROUTE = '/invitation/:invitationId';
export const ADMIN_LOGIN_ROUTE = '/admin/sign-in';

export const VISIT_ROUTE = '/visit';

// EMPLOYER
export const EMPLOYER_ROUTE = '/employer';
export const EMPLOYER_AUTH_ROUTE = `${EMPLOYER_ROUTE}/auth`;
export const EMPLOYER_SUCCESS_ROUTE = `${EMPLOYER_ROUTE}/success`;

// LANDING
export const LANDING_ROUTE = '/landing';
export const LANDING_REGISTRATION_ROUTE = `${LANDING_ROUTE}/registration`;
export const LANDING_WELCOME_ROUTE = `${LANDING_ROUTE}/welcome`;
export const LANDING_ADDRESS_ROUTE = `${LANDING_ROUTE}/address`;
export const LANDING_PAYMENT_ROUTE = `${LANDING_ROUTE}/place-order`;
export const LANDING_ORDER_CONFIRMATION_ROUTE = `${LANDING_ROUTE}/order-confirmation`;
export const LANDING_STEP_ROUTE = `${LANDING_ROUTE}/:stepName`;
export const CANDIDATES_ROUTE = '/candidates';

// USER
export const USER_ROUTE = '/user';
export const USER_DETAILS_ROUTE = `${USER_ROUTE}/:userId`;
export const MY_ACCOUNT_ROUTE = '/myAccount';
export const LOGGED_OUT_PROFILE_ROUTE = '/myProfile';
export const USER_ADDRESSES_ROUTE = '/userAddresses';
export const USER_CARDS_ROUTE = '/userCards';
export const USER_EDIT_ROUTE_PREFIX = `${USER_ROUTE}/edit`;
export const USER_EDIT_ROUTE = `${USER_EDIT_ROUTE_PREFIX}/:userId`;

// MERCHANT
export const MERCHANT_ROUTE = '/merchant';
export const MERCHANT_LANDING_ROUTE = `${MERCHANT_ROUTE}/landing`;
export const MERCHANT_SIGNUP_ROUTE = `${MERCHANT_ROUTE}/signup`;
export const MERCHANT_REGISTER_COMPANY_ROUTE = `${MERCHANT_ROUTE}/registerCompany`;
export const MERCHANT_URL_COMPANY_ROUTE = `${MERCHANT_ROUTE}/urlCompany`;

// CLIENT
export const COMPANY_ROUTE = '/company';
export const COMPANY_ACCOUNT_ROUTE = '/companyAccount';
export const COMPANY_PROFILE_ROUTE = `${COMPANY_ROUTE}/:userId`;
export const COMPANY_CREATE_ROUTE = `${COMPANY_ROUTE}/create/:orgType/*`;
export const COMPANY_CREATE_BASE_ROUTE = `${COMPANY_ROUTE}/create`;
export const COMPANIES_BY_ORG_TYPE = `${COMPANY_ROUTE}/org/:orgType/*`;
export const CLIENTS_ROUTE = '/clients';
export const CLIENT_DETAILS_ROUTE = '/clients/:companyId';

export const COMPANY_SENTS_ROUTE = '/companySents';
export const COMPANY_PIN_ROUTE = '/c/:pin';
export const MERCHANT_PROFILE_ROUTE = '/merchant/:id';

// STREAM
export const STREAM_ROUTE = '/stream';
export const STREAM_DETAILS_ROUTE = `${STREAM_ROUTE}/:bountyId`;
export const STREAM_DETAILS_WITH_TYPE_ROUTE = `${STREAM_ROUTE}/:bountyType/:bountyId`;
export const STREAM_SUB_DETAILS_ROUTE = `${STREAM_DETAILS_WITH_TYPE_ROUTE}/:subBountyId`;
export const BANNERS_ROUTE = '/banners';

//  USER SENTS
export const USER_SENTS_ROUTE = '/userSents';
export const USER_SENTS_DETAILS_ROUTE = `${USER_SENTS_ROUTE}/:bountyType/:bountyId`;
export const USER_SENTS_SUB_DETAILS_ROUTE = `${USER_SENTS_DETAILS_ROUTE}/:subBountyId`;

//  STATS
export const RESPONSE_DETAILS_ROUTE = `${STREAM_ROUTE}/bounty/:bountyId/responses/:responseId`;

// export const CLASSIFIED_DETAILS_ROUTE = `${STREAM_DETAILS_WITH_TYPE_ROUTE}/:type`;

// SEARCH
export const BOUNTY_SEARCH_BY_HASHTAGS = '/bountySearch';
export const DOCUMENTS_SEARCH_ROUTE = '/documentsSearch';

// CHAT
export const CHAT_ROUTE = '/chats';
export const CHAT_ROOM_ROUTE = `${CHAT_ROUTE}/:roomId`;
export const CHAT_AI_ROUTE = '/chatAiBot';

// PRODUCTS
export const PRODUCTS_ROUTE = '/products';
export const PRODUCTS_DETAIL_ROUTE = '/products/details/:productId';
export const UPLOAD_PRODUCTS_ROUTE = '/uploadProducts';
export const MY_PRODUCTS_ROUTE = '/myProducts';

// CART
export const CART_ROUTE = '/cart';

// ORDERS
export const ORDER_LIST_ROUTE = '/orders';
export const RECEIVED_ORDER_LIST_ROUTE = '/receivedOrders';
export const ORDER_ROUTE = `${ORDER_LIST_ROUTE}/:bountyId`;
export const RECEIVED_ORDER_ROUTE = `${RECEIVED_ORDER_LIST_ROUTE}/:bountyId`;
export const ORDER_MERCHANT_ROUTE = `${ORDER_ROUTE}/merchant/:merchantId`;

// LEADERBOARD
export const LEADERBOARD_ROUTE = '/leaderboard';

// REPORTS
export const REPORTS_ROUTE = '/reports';

// GROUPS
export const GROUPS_ROUTE = '/groups';
export const GROUPS_PROFILE_ROUTE = `${GROUPS_ROUTE}/:groupId`;

// PROMOTIONS
export const PROMOTIONS_ROUTE = '/promotions';
export const PROMOTION_DETAILS_ROUTE = `${PROMOTIONS_ROUTE}/:bountyId`;

// BADGES
export const BADGE_COLLECTIONS_ROUTE = '/badges';
export const BADGE_COLLECTION_DETAILS_ROUTE = `${BADGE_COLLECTIONS_ROUTE}/:collectionId`;

//  NOTIFICATIONS
export const CREATE_NOTIFICATION_ROUTE = '/notification';

//  APPLICANTS
export const APPLICANTS_ROUTE = '/applicants';
export const APPLICATIONS_ROUTE = '/applications';
export const APPLICATION_DETAILS_ROUTE = `${APPLICATIONS_ROUTE}/:userId/:applicationId`;

//  MATCHES
export const MATCHES_ROUTE = '/matches';
export const MATCH_DETAILS_ROUTE = '/matches/:id';

// TERMINAL
export const TERMINAL_ROUTE = '/terminal';

// JOB PREVIEW
export const JOBS_ROUTE = '/jobs';

// USERS
export const USER_MANAGEMENT_ROUTE = '/users';

// COMPANY USERS
export const COMPANY_USERS = '/companyUsers/:typeAlias';
export const COMPANY_MEMBERS_ROUTE = '/members';

// Job details
export const JOB_PREVIEW_ROUTE_PREFIX = `${JOBS_ROUTE}/preview`;
export const JOB_EDIT_ROUTE_PREFIX = `${JOBS_ROUTE}/edit`;
export const JOB_DETAILS_ROUTE = `${JOB_PREVIEW_ROUTE_PREFIX}/:bountyId`;
export const JOB_EDIT_ROUTE = `${JOB_EDIT_ROUTE_PREFIX}/:bountyId`;
export const JOB_ADD_ROUTE = `${JOBS_ROUTE}/new`;

// INVITES
export const SEND_INVITES_ROUTE = '/send-invites';

// SHOP
export const SHOP_SETTINGS_ROUTE = '/shop-settings';
export const SHOP_SETTINGS_GENERAL_ROUTE = '/shop-settings/general';
export const SHOP_SETTINGS_EMBED_ROUTE = '/shop-settings/embed';
export const SHOP_SETTINGS_SHOP_ITEMS_ROUTE = '/shop-settings/shopItems';
export const SHOP_SETTINGS_BRANDING_ROUTE = '/shop-settings/branding';
export const SHOP_SETTINGS_LAYOUT_AND_SORTING_ROUTE = '/shop-settings/layoutAndSorting';
export const SHOP_SETTINGS_LAB_RESULTS_ROUTE = '/shop-settings/labResults';
export const SHOP_SETTINGS_ONBOARDING_ROUTE = '/shop-settings/onboarding';
export const SHOP_SETTINGS_SUPPORT_ROUTE = '/shop-settings/support';
export const SHOP_SETTINGS_SEO_ROUTE = '/shop-settings/seo';
export const SHOP_SETTINGS_CART_ROUTE = '/shop-settings/cart';
export const SHOP_SETTINGS_CHECKOUT_ROUTE = '/shop-settings/checkout';
export const SHOP_SETTINGS_HOURS_OF_OPERATION_ROUTE = '/shop-settings/hoursOfOperation';
export const SHOP_SETTINGS_EMAILS_ROUTE = '/shop-settings/emails';
export const SHOP_SETTINGS_KIOSKS_ROUTE = '/shop-settings/kiosks';
export const SHOP_SETTINGS_SITE_SETTINGS_ROUTE = '/shop-settings/siteSettings';
export const SHOP_SETTINGS_CHANGE_LOG_ROUTE = '/shop-settings/changeLog';
export const SHOP_SETTINGS_INVENTORY_ROUTE = '/shop-settings/inventory';
export const SHOP_SETTINGS_ITEM_CATEGORIES_ROUTE = '/shop-settings/itemCategories';
export const SHOP_LOCATIONS_ROUTE = '/shop-locations';
export const ANALYTICS_ROUTE = '/analytics';
export const SHOP_LOCATION_DETAILS_ROUTE = '/shop-locations/:companyId/:type';
export const MY_SHOP_ROUTE = '/myShop';
export const USER_SHOP_POLICIES = '/shopPolicies/:userId';

// TOKENS
export const PURCHASE_TOKENS_ROUTE = '/purchase-tokens';

// SUPPORT
export const TALK_TO_SUPPORT_ROUTE = '/support';

// TOKENS
export const CUSTOM_TOKENS_ROUTE = '/custom-tokens';

// RESERVATIONS
export const DASHBOARD_RESERVATIONS_ROUTE = '/dashboard-reservations';
export const RESERVATION_DETAILS_ROUTE = '/reservations/:reservationId';

// REVIEWS
export const REVIEWS_ROUTE = '/reviews';

// CAMPAIGNS
export const CAMPAIGNS_ROUTE = '/campaigns';
export const CREATE_CAMPAIGN_ROUTE = '/create/campaign';
