import { RootState } from 'Types/redux.types';
import { UserRole } from 'Constants/enums';
import { CompanyPrivateData } from 'Types/companyPrivateData.interface';
import { UserResponse } from 'Types/userResponse.interface';
import { SystemInfo } from 'Types/systemInfo.interface';
import { UserData } from 'Types/userData.interface';
import { isCompany } from 'Utils/settings';

export const getUserSelector = (state: RootState): UserResponse => state.userReducer.data;
export const getUserSystemInfoSelector = (state: RootState): SystemInfo => getUserSelector(state).systemInfo;
export const getUserCompanyPrivateDataSelector = (state: RootState): CompanyPrivateData => state.userReducer.companyPrivateData;
export const getUserLoadingSelector = (state: RootState): boolean => state.userReducer.isLoading;
export const getUserRolesLoadingSelector = (state: RootState): boolean => state.userReducer.isRolesLoading;
export const getUserSourceSelector = (state: RootState): UserData | {} => state.userReducer.source;
export const isUserBlacklistedSelector = (state: RootState): boolean => getUserSystemInfoSelector(state).blacklisted;
export const getUserAllowedRolesSelector = (state: RootState): UserRole[] => getUserSystemInfoSelector(state).allowedRoles || [];
export const getUserAppliedRolesSelector = (state: RootState): UserRole[] => getUserSystemInfoSelector(state).appliedRoles;
export const getUserCompanyIdSelector = (state: RootState): string => getUserSystemInfoSelector(state).companyId;
export const isCompanySelector = (state: RootState): boolean => isCompany(getUserSelector(state)?.kind);
