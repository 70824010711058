// TODO: move enums to a proper places as soon as ts implemented

export enum SortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
}

export enum ApplicationStatus {
  Accepted = 'ACCEPTED',
  AcceptedEurope = 'ACCEPTED_EUROPE',
  AcceptedGlobal = 'ACCEPTED_GLOBAL',
  AcceptedLocal = 'ACCEPTED_LOCAL',
  All = 'ALL',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Incomplete = 'INCOMPLETE',
  KYCRejected = 'KYC_REJECTED',
  New = 'NEW',
  None = 'NONE',
  NotVerified = 'NOT_VERIFIED',
  Pending = 'PENDING',
  ReadyForKYC = 'READY_FOR_KYC',
  Rejected = 'REJECTED',
  Required = 'REQUIRED',
  Verified = 'VERIFIED',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN',
  Moderated = 'MODERATED',
}

// TODO: seems this values match with ApplicationStatus (except missing NEW, UNAVAILABLE statuses).
// Need to check can we use ApplicationStatus instead.
export enum UserIdentityConfigState {
  Accepted = 'ACCEPTED',
  AcceptedEurope = 'ACCEPTED_EUROPE',
  AcceptedGlobal = 'ACCEPTED_GLOBAL',
  AcceptedLocal = 'ACCEPTED_LOCAL',
  All = 'All',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  NotVerified = 'NOT_VERIFIED',
  ReadyForKYC = 'READY_FOR_KYC',
  Required = 'REQUIRED',
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

// TODO: remove duplicate object
export enum UserReputation {
  None = 'NONE',
  VIP = 'VIP',
  Pro = 'PRO',
  Custom = 'CUSTOM',
  Verified = 'VERIFIED',
}

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER',
  Operator = 'OPERATOR',
  CampaignAdmin = 'CAMPAIGN_ADMIN',
}

export enum UserStatus {
  Active = 'ACTIVE',
  BlackListed = 'BLACKLISTED',
  Inactive = 'INACTIVE',
  Suspended = 'SUSPENDED',
  Unknown = 'UNKNOWN',
  Test = 'TEST',
}

export enum AuthProvider {
  Email = 'EMAIL',
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Apple = 'APPLE',
}

export enum Benefit {
  HealthInsurance = 'HEALTH_INSURANCE',
  Housing = 'HOUSING',
  Meals = 'MEALS',
  None = 'NONE',
  PaidVacation = 'PAID_VACATION',
  PaidSickLeave = 'PAID_SICK_LEAVE',
  DentalInsurance = 'DENTAL_INSURANCE',
  VisionInsurance = 'VISION_INSURANCE',
  LifeInsurance = 'LIFE_INSURANCE',
  TuitionReimbursement = 'TUITION_REIMBURSEMENT',
  RetirementPlan = 'RETIREMENT_PLAN',
  Transportation = 'TRANSPORTATION',
  FlexibleHours = 'FLEXIBLE_HOURS',
  Travel = 'TRAVEL',
  Unknown = 'UNKNOWN',
}

export enum BadgeTypes {
  None = 'NONE',
  Official = 'OFFICIAL',
  RequestOfficialResponse = 'REQUEST_OFFICIAL_RESPONSE',
}

export enum BountyType {
  Album = 'ALBUM',
  Autosuggestion = 'AUTOSUGGESTION',
  BadgeCollection = 'BADGE_COLLECTION',
  Banner = 'BANNER',
  Bundle = 'BUNDLE',
  Checklist = 'CHECKLIST',
  Classified = 'CLASSIFIED',
  Funding = 'FUNDING',
  Job = 'JOB',
  LiveStream = 'LIVE_STREAM',
  Lottery = 'LOTTERY',
  Match = 'MATCH',
  MCQ = 'MCQ',
  News = 'NEWS',
  None = 'NONE',
  Order = 'ORDER',
  PrivateListing = 'PRIVATE_LISTING',
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
  Quest = 'QUEST',
  Question = 'QUESTION',
  RealEstateCustomer = 'REALESTATE_CUSTOMER',
  Reservation = 'RESERVATION',
  RealEstateRecommendation = 'REALESTATE_RECOMMENDATION',
  Score = 'SCORE',
  Survey = 'SURVEY',
  Poll = 'POLL',
  TalentRecommendation = 'TALENT_RECOMMENDATION',
  TalentSearch = 'TALENT_SEARCH',
  Thread = 'THREAD',
  TMOB_Challenge = 'TMOB_CHALLENGE',
  TMOB_Coaching = 'TMOB_COACHING',
  Trivia = 'TRIVIA',
  Deal = 'DEAL',
  Unknown = 'UNKNOWN',
  Post = 'POST',
  Distribution = 'DISTRIBUTION',
  Campaign = 'CAMPAIGN',
  Currency = 'CURRENCY',
  ExpressCampaign = 'EXPRESS_CAMPAIGN',
}

export enum BountyState {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  New = 'NEW',
  None = 'NONE',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Rejected = 'REJECTED',
  Retracted = 'RETRACTED',
  Sealed = 'SEALED',
  Unknown = 'UNKNOWN',
}

export enum PayPeriodUnit {
  CubicFoot = 'CUBIC_FOOT',
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  Hour = 'HOUR',
  Job = 'JOB',
  Kilogram = 'KILOGRAM',
  LinearFoot = 'LINEAR_FOOT',
  LinearMeter = 'LINEAR_METER',
  Month = 'MONTH',
  NonePeriod = 'NONE_PERIOD',
  Pound = 'POUND',
  SquareFoot = 'SQ_FOOT',
  SquareMeter = 'SQ_METER',
  UnknownPeriod = 'UNKNOWN_PERIOD',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum BonusType {
  Loyalty = 'LOYALTY',
  Project = 'PROJECT',
  Other = 'OTHER',
}

export enum SearchMode {
  Default = '',
  All = 'ALL',
}

export enum CompanyType {
  Ambassador = 'AMBASSADOR',
  Applicant = 'APPLICANT',
  Author = 'AUTHOR',
  Charity = 'CHARITY',
  Employer = 'EMPLOYER',
  Family = 'FAMILY',
  Merchant = 'MERCHANT',
  Restaurant = 'RESTAURANT',
  Corporate = 'CORPORATE',
  None = 'NONE',
  Partner = 'PARTNER',
  Unknown = 'UNKNOWN',
  Special = 'SPECIAL',
  Default = 'DEFAULT',
  Vulgus = 'VULGUS',
}

export enum WorkExperienceDuration {
  UpToOne = 'UP_TO_ONE',
  OneToThree = 'ONE_TO_THREE',
  ThreeToFive = 'THREE_TO_FIVE',
  OverFive = 'OVER_FIVE',
  OverTen = 'OVER_TEN',
  None = 'NONE',
  Unknown = 'UNKNOWN',
}

export enum WorkExperienceLevel {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Unknown = 'UNKNOWN'
}

export enum VaccinationStatus {
  Booster = 'BOOSTER',
  OneDose = 'ONE_DOSE',
  TwoDose = 'TWO_DOSE'
}

export enum VaccinationVendor {
  Johnson = 'JOHNSON',
  Moderna = 'MODERNA',
  PfizerBiontech = 'PFIZER_BIONTECH',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export enum UserSocialNetwork {
  Viber = 'VIBER',
  WhatsApp = 'WHATSAPP',
  Telegram = 'TELEGRAM',
}

export enum EducationDegree {
  AssociateDegree = 'ASSOCIATE_DEGREE',
  CollegeDegree = 'COLLEGE_DEGREE',
  BachelorsDegree = 'BACHELORS_DEGREE',
  VocationalSchool = 'VOCATIONAL_SCHOOL',
  MiddleSecondary = 'MIDDLE_SECONDARY',
  Higher = 'HIGHER',
  None = 'NONE',
  Unknown = 'UNKNOWN',
}

export enum UserSocial {
  Follow = 'follow',
  Endorse = 'endorse',
}

export enum UserKind {
  Company = 'COMPANY',
  Group = 'GROUP',
  Unknown = 'UNKNOWN',
  User = 'USER',
}

export enum UserType {
  Anon = 'ANON',
  Applicants = 'APPLICANTS',
  Company = 'COMPANY',
  Employee = 'EMPLOYEE',
  Employees = 'EMPLOYEES',
  None = 'NONE',
  Individual = 'INDIVIDUAL',
  Influencers = 'INFLUENCERS',
  Group = 'GROUP',
  GroupsRoot = 'GROUPS_ROOT',
  Unknown = 'UNKNOWN',
}

export enum StreamListType {
  None = 'NONE',
  Stream = 'STREAM',
  EdgeCoachings = 'EDGE_COACHINGS',
  EdgeInFocus = 'EDGE_IN_FOCUS',
  EdgeChallenges = 'EDGE_CHALLENGES',
  BuySell = 'BUY_SELL',
  Market = 'MARKET',
  Academia = 'ACADEMIA',
  Custom = 'CUSTOM',
  WebView = 'WEBVIEW',
  Favorites = 'FAVORITES',
  Autosuggestions = 'AUTOSUGGESTIONS',
  Jobs = 'JOBS',
  Applications = 'APPLICATIONS',
  MatchDetails = 'MATCH_DETAILS',
}

export enum StreamCodes {
  Regular = 'GG',
  Custom = 'HH',
  InFocus = 'TT',
  Stream = 'STREAM',
}

export enum Visibility {
  Global = 'GLOBAL',
  UserInternal = 'USER_INTERNAL',
}

export enum MarketMode {
  UserInternal = 'USER_INTERNAL',
}

export enum StreamListKind {
  Inbox = 'INBOX',
  Refs = 'REFS',
  Bucket = 'BUCKET',
  FilterByBountyType = 'FILTER_BY_BOUNTY_TYPE',
  RestCall = 'REST_CALL',
  Special = 'SPECIAL',
  WebView = 'WEBVIEW',
  Multi = 'MULTI',
  Stats = 'STATS',
  Unknown = 'UNKNOWN',
  Target = 'TARGET',
  ListStream = 'LIST_STREAM'
}

export enum ResponseType {
  None = 'NONE',
  Recommendation = 'RECOMMENDATION',
  Note = 'NOTE',
  Choice = 'CHOICE',
  Order = 'ORDER',
  TimelineEvent = 'TIMELINE_EVENT',
}

export enum BountyResponseState {
  None = 'NONE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Acked = 'ACKED',
  Progressing = 'PROGRESSING',
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
  Paying = 'PAYING',
  Paid = 'PAID',
  Retracted = 'RETRACTED',
  Rejected = 'REJECTED',
  Deleted = 'DELETED',
  New = 'NEW',
  Declined = 'DECLINED',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export enum VaccinationDisease {
  Covid19 = 'COVID-19',
}

export enum SummaryProgressState {
  None = 'NONE',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Unknown = 'UNKNOWN',
}

export enum RebountyType {
  None = 'NONE',
  Distribution = 'DISTRIBUTION',
  Intermediation = 'INTERMEDIATION',
  Unknown = 'UNKNOWN',
}

export enum BountyDrawerType {
  Bounty = 'BOUNTY',
  Promotion = 'PROMOTION',
  Banner = 'BANNER',
  Product = 'PRODUCT',
  Reply = 'REPLY',
  Comment = 'COMMENT',
  Reservation = 'RESERVATION',
  BadgeCollection = 'BADGE_COLLECTION',
}

export enum OrderSectionType {
  InApp = 'IN_APP',
  OnDelivery = 'ON_DELIVERY',
  PrivateUserPayment = 'PRIVATE_USER_PAYMENT',
  InAppResellerPayment = 'IN_APP_RESELLER_PAYMENT',
  OnDeliveryResellerPayment = 'ON_DELIVERY_RESELLER_PAYMENT',
}

export enum Currency {
  None = 'NONE',
  Unknown = 'UNKNOWN',
  ALL = 'ALL',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  RON = 'RON',
  MDL = 'MDL',
  GBP = 'GBP',
  PLN = 'PLN',
  GEL = 'GEL',
  ARS = 'ARS',
  AED = 'AED',
}

export enum ApplicationJourneyPhaseStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
}

export enum ApplicationHrType {
  ApplicationHrStatus = 'APPLICATION_HR_STATUS',
  ApplicationHrStatusInterview = 'APPLICATION_HR_STATUS_INTERVIEW',
}

export enum ApplicationHrStatus {
  None = 'NONE',
  OfferLetterSigned = 'OFFER_LETTER_SIGNED',
  TravelArrangement = 'TRAVEL_ARRANGEMENT',
  MedExam = 'MED_EXAM',
  WaitingToBeApproved = 'WAITING_TO_BE_APPROVED',
  WaitingForJob = 'WAITING_FOR_JOB',
  WaitingForHousing = 'WAITING_FOR_HOUSING',
  WaitingList = 'WAITING_LIST',
  HealthIssues = 'HEALTH_ISSUES',
  NoCovid19Vaccination = 'NO_COVID_19_VACCINATION',
  PoorEnglish = 'POOR_ENGLISH',
  DayShiftOnly = 'DAY_SHIFT_ONLY',
  NightShiftOnly = 'NIGHT_SHIFT_ONLY',
  InterestedInPtJob = 'INTERESTED_IN_PT_JOB',
  NoJobsFromClient = 'NO_JOBS_FROM_CLIENT',
  Overqualified = 'OVERQUALIFIED',
  RiskProfile = 'RISK_PROFILE',
  DoesNotMeetTheRequirements = 'DOES_NOT_MEET_THE_REQUIREMENTS',
  Unknown = 'UNKNOWN',
}

export enum ApplicationHrStatusInterview {
  None = 'NONE',
  ClosedHire = 'CLOSED_HIRE',
  NoShowRefused1StInterview = 'NO_SHOW_REFUSED_1ST_INTERVIEW',
  Failed1StInterview = 'FAILED_1ST_INTERVIEW',
  ApprovedAt1StInterview = 'APPROVED_AT_1ST_INTERVIEW',
  ApprovedAt2NdInterview = 'APPROVED_AT_2ND_INTERVIEW',
  NoShowRefused2NdInterview = 'NO_SHOW_REFUSED_2ND_INTERVIEW',
  Failed2NdInterview = 'FAILED_2ND_INTERVIEW',
  ApprovedButRefusedToBeHired = 'APPROVED_BUT_REFUSED_TO_BE_HIRED',
  Quit = 'QUIT',
  Unknown = 'UNKNOWN',
}

export enum ApplicationJourneyPhase {
  ApplicationAccepted = 'APPLICATION_ACCEPTED',
  AdditionalDocumentsSupplied = 'ADDITIONAL_DOCUMENTS_SUPPLIED',
  EmploymentContractExecuted = 'EMPLOYMENT_CONTRACT_EXECUTED',
  PermitApplicationSubmitted = 'PERMIT_APPLICATION_SUBMITTED',
  WorkPermitReceived = 'WORK_PERMIT_RECEIVED',
  VisaApplicationPackageCompiled = 'VISA_APPLICATION_PACKAGE_COMPILED',
  VisaApplicationSubmitted = 'VISA_APPLICATION_SUBMITTED',
  VisaReceived = 'VISA_RECEIVED',
  TravelArrangements = 'TRAVEL_ARRANGEMENTS',
  EnRouteToLocation = 'EN_ROUTE_TO_LOCATION',
  ArrivedAtLocation = 'ARRIVED_AT_LOCATION',
  Situated = 'SITUATED',
  Working = 'WORKING',
  EmploymentConcluded = 'EMPLOYMENT_CONCLUDED'
}

export enum AttachmentDisplayStatus {
  Inline = 'INLINE',
  Attach = 'ATTACH',
}

export enum AttachmentVisibility {
  All = 'ALL',
  Participants = 'PARTICIPANTS',
  Creator = 'CREATOR',
  None = 'NONE',
}

export enum AttachmentType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  PDF = 'PDF',
  File = 'FILE',
  YouTube = 'YOUTUBE',
  Website = 'WEBSITE',
  Other = 'OTHER',
  GIF = 'GIF',
  NotFile = 'NOT_FILE',
}

export enum AttachmentMeaning {
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
  Resume = 'RESUME',
  Contract = 'CONTRACT',
  Covid19 = 'COVID19_VAXX',
  WorkPermit = 'WORK_PERMIT',
  Other = 'OTHER',
  OtherDoc = 'OTHER_DOC',
  CriminalBgCheck = 'CRIMINAL_BKGND_CHECK',
  ProofOfSkills = 'PROOF_OF_SKILLS',
  BadgeIcon = 'badgeIcon',
  BadgeImage = 'badgeImage',
  Cover = 'cover',
}

export enum AttachmentStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum SentTypes {
  MyResponses = 'MY_RESPONSES',
  MyDrafts = 'MY_DRAFTS',
  Posted = 'POSTED',
  Scheduled = 'SCHEDULED',
  Closed = 'CLOSED',
}

export enum SponsorState {
  none = 'NONE',
  sent = 'SENT',
  rejected = 'REJECTED',
  accepted = 'ACCEPTED',
  notAssigned = 'NOT_ASSIGNED',
  unknown = 'UNKNOWN',
}

export enum CommentType {
  Bounty = 'BOUNTY',
  Response = 'RESPONSE',
  User = 'USER',
}

export enum IdentityType {
  Anon = 'ANON',
  Real = 'REAL',
  Default = 'DEFAULT',
}

export enum CtrlTypes {
  Shown = 'A',
}

export enum BountySocialNetworks {
  InApp = 'IN_APP',
  Rebounty = 'REBOUNTY',
}

export enum AccessMode {
  PIN = 'PIN',
}

export enum MatchStatus {
  None = 'NONE',
  NotStarted = 'NOT_STARTED',
  FirstHalf = 'FIRST_HALF',
  HalfTimeBreak = 'HALF_TIME_BREAK',
  SecondHalf = 'SECOND_HALF',
  ExtraTime = 'EXTRA_TIME',
  Penalty = 'PENALTY',
  Finished = 'FINISHED',
  Cancelled = 'CANCELLED',
  TBD = 'TBD',
  Terminated = 'TERMINATED',
  Interrupted = 'INTERRUPTED',
  Postponed = 'POSTPONED',
  Unknown = 'UNKNOWN',
}

export enum ProgressState {
  None = 'NONE',
  Completed = 'COMPLETED',
  Unknown = 'UNKNOWN'
}

export enum PermissionType {
  Hide = 'HIDE',
  ReadOnly = 'READ_ONLY',
  WriteOnly = 'WRITE_ONLY',
  ReadWrite = 'READ_WRITE',
  Unknown = 'UNKNOWN',
}

export enum CheckoutFieldType {
  Hidden = 'HIDDEN',
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
}

export enum BountyUserState {
  None = 'NONE',
  Unknown = 'Unknown',
  InActive = 'IN_ACTIVE',
  InWIP = 'IN_WIP',
  InRebountied = 'IN_REBOUNTIED',
  InPaused = 'IN_PAUSED',
  InClosed = 'IN_CLOSED',
  InReceived = 'IN_RECEIVED',
  InTalentPending = 'IN_TALENT_PENDING',
  InTalentRejected = 'IN_TALENT_REJECTED',
  InTalentInterested = 'IN_TALENT_INTERESTED',
  InExpired = 'IN_EXPIRED',
  OutDraft = 'OUT_DRAFT',
  OutActive = 'OUT_ACTIVE',
  OutPosted = 'OUT_POSTED',
  OutPaused = 'OUT_PAUSED',
  OutClosed = 'OUT_CLOSED',
  OutRejected = 'OUT_REJECTED',
  OutRetracted = 'OUT_RETRACTED',
  OutArchived = 'OUT_ARCHIVED',
  OutExpired = 'OUT_EXPIRED',
}

export enum CompanySentsFolder {
  Autosuggestion = 'AUTOSUGGESTION_FOLDER',
  Listings = 'listings',
  Products = 'products',
  Orders = 'orders',
  BadgeCollections = 'badgeCollections',
  Promotions = 'promotions',
  Jobs = 'jobs',
  News = 'news',
  Banners = 'banners',
  Matches = 'matches',
  Currencies = 'currencies',
}

export enum DismissReasons {
  TooSmall = 'TOO_SMALL',
  DontKnow = 'DONT_KNOW',
  NoInterest = 'NO_INTEREST',
}

export enum BountyActions {
  None = 'NONE',
  Edit = 'EDIT',
  Activate = 'ACTIVATE',
  Share = 'SHARE',
  Post = 'POST',
  PostRebounty = 'POST_REBOUNTY',
  Pause = 'PAUSE',
  Resume = 'RESUME',
  Close = 'CLOSE',
  Pay = 'PAY',
  Reply = 'REPLY',
  ReplyAsComment = 'REPLY_AS_COMMENT',
  Message = 'MESSAGE',
  Onit = 'ONIT',
  Delete = 'DELETE',
  Interested = 'INTERESTED',
  NotInterested = 'NOT_INTERESTED',
  Comment = 'COMMENT',
  GainInsights = 'GAIN_INSIGHTS',
  AddPosts = 'ADD_POSTS',
  Reorder = 'REORDER',
  SendResume = 'SEND_RESUME',
  Rate = 'RATE',
  Respond = 'RESPOND',
  Rebounty = 'REBOUNTY',
  Recommend = 'RECOMMEND',
  Like = 'LIKE',
  Bookmark = 'BOOKMARK',
  Chat = 'CHAT',
  Open = 'OPEN',
  OpenTrivia = 'OPEN_TRIVIA',
  RegisterToWin = 'REGISTER_TO_WIN',
  Dismiss = 'DISMISS',
  Report = 'REPORT',
  CopyId = 'COPY_ID',
  Duplicate = 'DUPLICATE',
  Deactivate = 'DEACTIVATE',
  Retract = 'RETRACT',
  SendPayment = 'SEND_PAYMENT',
  RemindLater = 'REMIND_LATER',
  Simulate = 'SIMULATE',
  RequestOfficialResponse = 'REQUEST_OFFICIAL_RESPONSE',
  Reject = 'REJECT',
  PinToTop = 'PIN_TO_TOP',
  UnpinFromTop = 'UNPIN_FROM_TOP',
  ChangeRatingValue = 'CHANGE_RATING_VALUE',
  MoveToList = 'MOVE_TO_LIST',
  MarkOfficial = 'MARK_OFFICIAL',
  UnmarkOfficial = 'UNMARK_OFFICIAL',
  CancelROR = 'CancelROR',
  AddQuestion = 'ADD_QUESTION',
  AddSuccessMessage = 'ADD_SUCCESS_MESSAGE',
}

export enum ResponseActions {
  None = 'NONE',
  Publish = 'PUBLISH',
  Edit = 'EDIT',
  Rate = 'RATE',
  ChangeOffer = 'CHANGE_OFFER',
  Comment = 'COMMENT',
  Demote = 'DEMOTE',
  Delete = 'DELETE',
  Retract = 'RETRACT',
  ACK = 'ACK',
  Progress = 'PROGRESS',
  Accept = 'ACCEPT',
  Refuse = 'REFUSE',
  Message = 'MESSAGE',
  Report = 'REPORT',
  ChangeRatingValue = 'CHANGE_RATING_VALUE',
  PinToTop = 'PIN_TO_TOP',
  UnpinToTop = 'UNPIN_FROM_TOP',
  Reject = 'REJECT',
  Pay = 'PAY',
  Bookmark = 'BOOKMARK',
  MarkOfficial = 'MARK_OFFICIAL',
  UnmarkOfficial = 'UNMARK_OFFICIAL',
  Like = 'LIKE',
  MarkAsHighlight = 'MARK_AS_HIGHLIGHT',
  UnmarkAsHighlight = 'UNMARK_HIGHLIGHT',
  MessageApplicant = 'MESSAGE_APPLICANT',
  MessageResponder = 'MESSAGE_RESPONDER',
}

export enum ContentType {
  Stream = 'STREAM',
  Posts = 'POSTS',
}

export enum ShowInType {
  Home = 'HOME',
  Explicit = 'EXPLICIT',
  Unknown = 'UNKNOWN',
}

export enum AuthorPref {
  Auto = 'AUTO',
  External = 'EXTERNAL',
  Creator = 'CREATOR',
  Owner = 'OWNER',
}

export enum ProductKind {
  Product = 'PRODUCT',
  GiftCard = 'GIFTCARD',
  Food = 'FOOD',
  Ticket = 'TICKET',
  Token = 'TOKEN',
  Unknown = 'UNKNOWN',
}

export enum VisibilityModeType {
  Default = 'DEFAULT',
  Visible = 'VISIBLE',
  Private = 'PRIVATE',
  User = 'USER',
  Unknown = 'UNKNOWN',
}

export enum KYCLevel {
  Europe = 'EUROPE',
  International = 'INTERNATIONAL',
  Local = 'LOCAL',
}

export enum PlatformLabels {
  Android = 'ANDROID',
  IOS = 'IOS',
  Console = 'WEB',
  JoinWeb = 'JOIN_WEB',
  Server = 'SERVER',
  Unknown = 'UNKNOWN',
}

export enum SalaryType {
  Budget = 'ON_BUDGET_PAY',
  Gross = 'ON_GROSS_PAY',
  Net = 'ON_NET_PAY',
}

export enum ResponseUserState {
  OutDraft = 'OUT_DRAFT',
  OutSubmitted = 'OUT_SUBMITTED',
  OutPending = 'OUT_PENDING',
  OutAcked = 'OUT_ACKED',
  OutAccepted = 'OUT_ACCEPTED',
  OutPaid = 'OUT_PAID',
  OutRefused = 'OUT_REFUSED',
  OutRejected = 'OUT_REJECTED',
  OutRetracted = 'OUT_RETRACTED',
  InSubmitted = 'IN_SUBMITTED',
  InAcked = 'IN_ACKED',
  InAccepted = 'IN_ACCEPTED',
  InPaid = 'IN_PAID',
  InRefused = 'IN_REFUSED',
  Other = 'OTHER',
}

export enum AllowMode {
  Default = 'DEFAULT',
  Always = 'ALWAYS',
  Never = 'NEVER',
  May = 'MAY',
  Should = 'SHOULD',
}

export enum AvatarProperties {
  KeyColor = 'color',
  CodeColor = '#',
}

export enum ProductFlavours {
  Rebounty = 'rebounty',
  Backstage = 'youkno',
  Belong = 'belong',
  Stash = 'stash',
  Alleaves = 'alleaves',
}

export enum Interactions {
  Chat = 'chat',
  OfficialResponse = 'officialResponse',
  Rebounty = 'rebounty',
  Recommend = 'recommend',
  Seen = 'seen',
  Share = 'share',
}

export enum KeyCategory {
  Important = 'GN',
  Normal = 'NN',
  Unimporant = 'SN',
}

export enum SortBy {
  CreatorUpdatedAt = 'creatorUpdatedAtSortKey',
  TypeCreatedAt = 'typeCreatedAtSortKey',
  TypePopularity = 'typePopularitySortKey',
  TypeDiscussed = 'typeDiscussedSortKey',
  ListCreatedAt = 'listCreatedAtSortKey',
  ListPopularity = 'listPopularitySortKey',
  ListDiscussed = 'listDiscussedSortKey',
  StreamCreatedAt = 'streamCreatedAtSortKey',
  StreamPopularity = 'streamPopularitySortKey',
  StreamDiscussed = 'streamDiscussedSortKey',
  BountyCreated = 'bountyCreatedAtSortKey',
  BountyPopularity = 'bountyPopularitySortKey',
  BountyDiscussed = 'bountyDiscussedSortKey',
}

export enum SortType {
  Recent = 'RECENT',
  Popular = 'POPULAR',
  Discussed = 'DISCUSSED',
}

export enum DealType {
  None = 'NONE',
  Invite = 'INVITE',
  Promotion = 'PROMOTION',
  Unknown = 'UNKNOWN',
}

// TODO: change
export enum ScopedReportType {
  'Local wide' = 'report.scope.local',
  'Company wide' = 'report.scope.company',
  'Global wide' = 'report.scope.global',
}

export enum ReportMetaBoolValues {
  Scoped = 'scoped',
  UserFiltered = 'userFiltered',
}

export enum ReportFiltersField {
  FromDate = 'fromDate',
  ToDate = 'toDate',
}

export enum ChatAction {
  Dismiss = 'dismiss',
  Archive = 'archive',
  Resolve = 'resolve',
  Abandon = 'abandon',
}

export enum SignupSteps {
  NiceToMeet = 'NICE_TO_MEET_STEP',
  Welcome = 'WELCOME_STEP',
}

// TODO: use this enum
export enum ChatMessageType {
  Message = 'MSG',
  Status = 'STATUS',
  Info = 'INFO',
  Help = 'HELP',
}

// TODO: use this enum
export enum ChatRoomType {
  Private = 'PRIVATE',
  Group = 'GROUP',
  Recipients = 'RECIPIENTS',
  Participants = 'PARTICIPANTS',
  SurveyChoice = 'SURVEY_CHOICE',
  Support = 'SUPPORT',
  PSG = 'PSG',
  Unknown = 'UNKNOWN',
}

export enum ChatState {
  New = 'NEW',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Dismissed = 'DISMISSED',
  Resolved = 'RESOLVED',
  Abandoned = 'ABANDONED',
}

export enum DisplayMode {
  None = 'NONE',
  Default = 'DEFAULT',
  Highlighted = 'HIGHLIGHTED',
  Unknown = 'UNKNOWN',
}

export enum AttachmentSourceOptions {
  TakePhoto = 'TAKE_PHOTO',
  PhotoLibrary = 'PHOTO_LIBRARY',
  VideoLibrary = 'VIDEO_LIBRARY',
  AudioLibrary = 'AUDIO_LIBRARY',
  PDFFiles = 'PDF_FILES',
}

export enum RelocateState {
  Ready = 'READY',
  Likely = 'LIKELY',
  NotLikely = 'NOT_LIKELY',
  No = 'NO',
}

export enum SalaryExpectation {
  ProposalMatches = 'PROPOSAL_MATCHES',
  ProposalTooLow = 'PROPOSAL_TOO_LOW',
}

export enum BadgeStyles {
  Official = 'officialBadge',
  Ror = 'rorBadge',
}

export enum ClientColors {
  HeaderColor = '#E5E5E5',
  SubHeaderColor = '#EBE1D6',
  PrimaryColor = '#0061AF',
  SecondaryColor = '#F2F2F2',
  HighlightColor = '#DFEFFF',
  LinkColor = '#0F69EF',
}

export enum LanguageLevel {
  Native = 'NATIVE',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

export enum EnvironmentMode {
  Default = 'DEFAULT',
  Test = 'TEST',
}

export enum AudienceType {
  Journal = 'JOURNAL',
  Public = 'PUBLIC',
  Unknown = 'UNKNOWN',
}

export enum SpecialProgram {
  CUAET = 'CUAET',
}

export enum SpecialProgramSpecialty {
  CanadaButcher = 'CANADA_BUTCHER',
  CanadaDriver = 'CANADA_DRIVER',
  CanadaSales = 'CANADA_SALES',
  CanadaPharmacyAssistant = 'CANADA_PHARMACY_ASSISTANT',
  CanadaWarehouse = 'CANADA_WAREHOUSE',
}

export enum JobVariousLicenses {
  CEWithADR = 'CE_WITH_ADR',
}

export enum PhoneStatus {
  Verified = 'VERIFIED',
  Unverified = 'UNVERIFIED',
}

export enum UserIdentityPhotoType {
  Front = 'FRONT',
  Back = 'BACK',
}

export enum AddressType {
  Billing = 'BILLING',
  None = 'NONE',
  Shipping = 'SHIPPING',
  Tax = 'TAX',
  Unknown = 'UNKNOWN',
}

export enum AuditObjectType {
  Address = 'ADDRESS',
  Attachment = 'ATTACHMENT',
  Bounty = 'BOUNTY',
  Chat = 'CHAT',
  Comment = 'COMMENT',
  Event = 'EVENT',
  None = 'NONE',
  PushNotification = 'PUSH_NOTIFICATION',
  Response = 'RESPONSE',
  Seat = 'SEAT',
  Ticket = 'TICKET',
  Venue = 'VENUE',
  User = 'USER',
  Unknown = 'UNKNOWN'
}

export enum AuditVerb {
  AssignCompany = 'ASSIGN_COMPANY',
  AttachmentAdded = 'ATTACHMENT_ADDED',
  AttachmentDeleted = 'ATTACHMENT_DELETED',
  BlacklistedChanged = 'BLACKLISTED_CHANGED',
  InviteToJobReceived = 'INVITE_TO_JOB_RECEIVED',
  InviteToJobSent = 'INVITE_TO_JOB_SENT',
  KYCChanged = 'KYC_CHANGED',
  KYCChangedByPassport = 'KYC_CHANGED_BY_PASSPORT',
  JobApplicationStatusChanged = 'JOB_APPLICATION_STATUS_CHANGED',
  JourneyPhaseUpdated = 'JOURNEY_PHASE_UPDATED',
  PinChanged = 'PIN_CHANGED',
  ReferralCodeChanged = 'REFERRAL_CODE_CHANGED',
  UserAppliedForAJob = 'USER_APPLIED_FOR_A_JOB',
  UserCreated = 'USER_CREATED',
  UserSignedIn = 'USER_SIGNED_IN',
  UserUpdated = 'USER_UPDATED',
}

export enum KYCRejectType {
  Blacklisted = 'BLACKLISTED',
  DoNotMatchUserData = 'DO_NOT_MATCH_USER_DATA',
  Expired = 'EXPIRED',
  Falsified = 'FALSIFIED',
  Fraud = 'FRAUD',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  NotAccepted = 'NOT_ACCEPTED',
  Other = 'OTHER',
  Underage = 'UNDERAGE',
  Unknown = 'UNKNOWN',
  Unverifiable = 'UNVERIFIABLE',
}

export enum KYCUserStatus {
  Rejected = 'REJECTED',
  Verified = 'VERIFIED',
}

export enum VoucherType {
  None = 'NONE',
  Intrinsic = 'INTRINSIC',
  Unknown = 'UNKNOWN',
}

export enum RegionLevel {
  Europe = 'EUROPE',
  Local = 'LOCAL',
  Global = 'GLOBAL',
}

export enum PointCurrency {
  Invite = 'INVITE',
  Like = 'LIKE',
  Min = 'MIN',
  None = 'NONE',
  Point = 'POINT',
  Rating = 'RATING',
  Repu = 'REPU',
  Star = 'STAR',
  Star2 = 'STAR2',
  Star3 = 'STAR3',
  SystemToken = 'SYSTKN',
  Unknown = 'UNKNOWN',
}

export enum IdentityModeType {
  ForcedAnon = 'FORCED_ANON',
  ForcedReal = 'FORCED_REAL',
  Anon = 'ANON',
  Real = 'REAL',
}

export enum FilterType {
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  CATEGORY = 'CATEGORY',
  CHECK = 'CHECK',
  LOOKUP = 'LOOKUP',
}

export enum ShopActionsType {
  Resync = 'RESYNC',
  CreateBanners = 'CREATE_BANNERS',
}

export enum DeliveryType {
  Standard = 'STANDARD',
  Driver = 'DRIVER',
  Unknown = 'UNKNOWN',
  NoDelivery = 'NO_DELIVERY',
}

export enum DocumentType {
  BankStatement = 'BANK_STATEMENT',
  BirthCertificate = 'BIRTH_CERTIFICATE',
  Covid19Vax = 'COVID19_VAXX',
  CriminalBgCheck = 'CRIMINAL_BKGND_CHECK',
  DrivingLicence = 'DRIVING_LICENCE',
  EAD = 'EMPLOYMENT_AUTHORIZATION_CARD',
  GovernmentLetter = 'GOVERNMENT_LETTER',
  IdentityCard = 'IDENTITY_CARD',
  NationalId = 'NATIONAL_ID',
  Other = 'OTHER',
  OtherDoc = 'OTHER_DOC',
  Passport = 'PASSPORT',
  ProofOfResidency = 'PROOF_OF_RESIDENCY',
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
  ResidentCard = 'RESIDENT_CARD',
  Resume = 'RESUME',
  SecondId = 'SECOND_ID',
  SSN = 'SOCIAL_SECURITY_CARD',
  StateId = 'STATE_ID',
  Unknown = 'UNKNOWN',
  UtilityBill = 'UTILITY_BILL',
  Visa = 'VISA',
  WorkPermit = 'WORK_PERMIT',
}

export enum ClientEnvType {
  Production = 'PROD',
  Sandbox = 'SANDBOX',
}

export enum BannerType {
  None = 'NONE',
  Hero = 'HERO',
  Highlights = 'HIGHLIGHTS',
  Recommendations = 'RECOMMENDATIONS',
  Specials = 'SPECIALS',
  Unknown = 'UNKNOWN',
}

export enum AttachmentAction {
  OpenUrl = 'OPEN_URL',
  OpenProduct = 'OPEN_PRODUCT',
  Search = 'SEARCH',
}

export enum TargetApp {
  Shop = 'shop',
}

export enum UserAuthStatus {
  Logged = 'logged',
  NotLogged = 'notLogged',
  Both = 'both',
}

// TODO: move to /types
export type IdentityMode = Record<IdentityModeType, { forced: true; identityType: IdentityType; }>

export enum PromotionType {
  None = 'NONE',
  Discount = 'DISCOUNT',
  Free = 'FREE',
  Unknown = 'UNKNOWN',
}

export enum ProductType {
  Product = 'PRODUCT',
  Coupon = 'COUPON',
}

export enum AreaType {
  None = 'NONE',
  Group = 'GROUP',
  SurveyChoice = 'SURVEY_CHOICE',
  SsoManager = 'SSO_MANAGER',
  Geo = 'GEO',
  FamiliesContent = 'FAMILIES_CONTENT',
  Unknown = 'UNKNOWN',
}

export enum SyncComponentType {
  All = 'all',
  Categories = 'categories',
  Products = 'products',
  Locations = 'locations',
  Extras = 'extras',
  Reservations = 'reservations',
}

export enum UserVerification {
  Moderated = 'moderated',
  Verified = 'verified',
  Rejected = 'rejected',
}

export enum ConfirmationModalType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}
export enum InventoryPolicy {
  Deny = 'DENY',
  Continue = 'CONTINUE',
}

export enum WeightUnit {
  FLWR = 'FLWR',
  FLWR_2 = 'FLWR_2',
  FLWR_4 = 'FLWR_4',
  EA = 'EA',
  G = 'G',
  KG = 'KG',
  LB = 'LB',
  OZ = 'OZ',
}
export enum PaymentMethod {
  Cash = 'CASH',
  Debit = 'DEBIT',
  BillMyBank = 'BILLMYBANK',
  Chargee = 'CHARGEE',
}

export enum DeliveryMethod {
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY',
  Curbside = 'CURBSIDE',
  // Retail = 'RETAIL',
}

export enum OrderStatus {
  None = 'NONE',
  New = 'NEW',
  Proposed = 'PROPOSED',
  Payed = 'PAYED',
  Pending = 'PENDING',
  PendingPayment = 'PENDING_PAYMENT',
  Processing = 'PROCESSING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Shipped = 'SHIPPED',
  MerchReceived = 'MERCH_RECEIVED',
  Settled = 'SETTLED',
  Chargeback = 'CHARGEBACK',
  Disputed = 'DISPUTED',
  PaymentFailed = 'PAYMENT_FAILED',
  Negotiation = 'NEGOTIATION',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  Unknown = 'UNKNOWN',
}

export enum MerchantOrderStatus {
  Processing = 'PROCESSING',
  Shipped = 'SHIPPED',
  Completed = 'COMPLETED',
}

export enum ShortcutsLabels {
  Bold = 'bold',
  Unordered = 'unordered',
  Ordered = 'ordered',
}

export enum AgeFilterType {
  Age18 = '18',
  Age21 = '21',
  None = 'NONE',
}

export enum AnalyticsDateType {
  OneMonth = '30daysAgo',
  TwoMonth = '60daysAgo',
  ThreeMonth = '90daysAgo',
}

export enum ShopStatusType {
  Auto = 'AUTO',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum LimitedShopStatusType {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum OnlineShopType {
  On = 'ON',
  Off = 'OFF',
}

export enum AnonUsersType {
  AuthAtStart = 'AUTH_AT_START',
  AuthAtOrder = 'AUTH_AT_ORDER',
  AllowPurchase = 'ALLOW_PURCHASE',
}

export enum MedicalIdPromptType {
  AtStart = 'AT_START',
  AtOrder = 'AT_ORDER',
}

export enum CustomerBalanceType {
  InCart = 'IN_CART',
  None = 'NONE',
}

export enum TagVerbs {
  TagAdded = 'TAG_ADDED',
  TagDeleted = 'TAG_DELETED',
}

export enum VisaStatus {
  PreparingDocuments = 'PREPARING_DOCUMENTS',
  BiometricsDelivery = 'BIOMETRICS_DELIVERY',
  VisaProcessing = 'VISA_PROCESSING',
  HaveVisa = 'HAVE_VISA',
  DidNotApply = 'DID_NOT_APPLY',
  Unknown = 'UNKNOWN',
  None = 'NONE',
}

export enum ClientScheduleType {
  ClientHoursOfOperation = 'ClientHoursOfOperation',
  ClientSpecialHoursOfOperation = 'ClientSpecialHoursOfOperation',
}

export enum HoursOfOperationType {
  Common = 'common',
  Pickup = 'pickup',
  Delivery = 'delivery',
}

export enum OperationStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  AsMon = 'AS_MON',
  Common = 'COMMON',
  Inherit = 'INHERIT',
  Default = 'DEFAULT'
}

export enum RestrictedOperationStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum DayOfWeekType {
  Monday = 'mon',
  Tuesday = 'tue',
  Wednesday = 'wed',
  Thursday= 'thu',
  Friday = 'fri',
  Saturday = 'sat',
  Sunday = 'sun',
}

export enum GroupType {
  Generic = 'GENERIC',
  Influencers = 'INFLUENCERS',
  OrderHandling = 'ORDER_HANDLING',
  Support = 'SUPPORT',
  Unknown = 'UNKNOWN',
}

export enum JoiningStrategy {
  InviteOnly = 'INVITE_ONLY',
  NeedsApproval = 'NEEDS_APPROVAL',
  Open = 'OPEN',
  Closed = 'CLOSED',
  GroupsOnly = 'GROUPS_ONLY',
  Unknown = 'UNKNOWN',
}

// TODO: move to own file after creation 'enums/' folder
export enum QueueAlias {
  UserActivity,
  UpdateDevice,
  AcceptDevice,
  RejectDevice,
  UpdateUser,
  UpdateUserProfile,
  UpdateUserLocation,
  UpdateCaps,
  CreateInviteImg,
  ReportUser,
  BlockUser,
  DeleteUser,
  FetchUser,
  FetchRanking,
  Cashout,
  Legal,
  DeeplinkIn,
  NewCreditCard,
  RmCreditCard,
  DismissBulletin,
  BulletinAction,
  CreateChat,
  ChatCommand,
  OptOut,
  SendInvite,
  ResendInvite,
  CancelInvite,
  AddGroup,
  DeleteGroup,
  UpdateGroup,
  AddMember,
  RemoveMember,
  AcceptMember,
  RejectMember,
  NewCompany,
  AssignCompany,
  UpdateCompany,
  ListEmployees,
  AddTransaction,
  AddComment,
  EditComment,
  DeleteComment,
  PromoteComment,
  UpdateAccount,
  SearchBounties,
  RequestAccess,
  ForceSignout,
  Signout,

  //  Bounty events
  FetchBounty,
  ActivateBounty,
  PostBounty,
  AcceptParticipant,
  RejectParticipant,
  SealBounty,
  ApplyBounty,
  DropoffBounty,
  WorkStartedBounty,
  WorkPausedBounty,
  WorkProgressBounty,
  WorkCompletedBounty,
  SendBounty,
  UpdateBounty,
  DeleteBounty,
  RetractBounty,
  ArchiveBounty,
  SimulateBounty,
  DismissBounty,
  PauseBounty,
  ResumeBounty,
  ClaimBounty,
  CloseBounty,
  InvoiceBounty,
  PayBounty,
  ApproveBounty,
  RejectBounty,
  RejectClaim,
  CancelClaim,
  ReportBounty,
  NotifyResumeBounty,
  CreateShareBountyImg,
  CreateBountyLink,
  CreateRecommendation,
  SendRecommendation,
  ChangeBountyRatingValue,
  PinBounty,
  BountyEditBadge,
  ListBountyPayeeCandidates,
  ListBountyDistributionTargets,
  MoveBountyToList,
  RebountyBounty,

  // Response events
  ACKResponse,
  AcceptResponse,
  RefuseResponse,
  PromoteResponse,
  UpdateResponse,
  DeleteResponse,
  TalentInterest,
  BountyAttachment,
  ReportResponse,
  ChangeResponseRatingValue,
  RejectResponse,
  RetractResponse,
  DemoteResponse,
  PinResponse,
  ResponseEditBadge,
  PublishResponse,
  SendResponse,

  GetList,
  RequestBadge,
  RemoveBadge,

  AddAttachment,
  DeleteAttachment,
  UpdateAttachment,

  AddFavorite,
  RemoveFavorite,

  TestRetryLater,
  LikeBounty,
  LikeResponse,
  FollowUser,
}

export enum MoreMenuGroupOptions {
  Edit = 'EDIT',
  Add_member = 'ADD_MEMBER',
  Delete = 'DELETE',
  AddProfilePicture = 'ADD_PROFILE_PICTURE',
  AddCoverPicture = 'ADD_COVER_PICTURE',
}

export enum MemberActions {
  None = 'NONE',
  Message = 'MESSAGE',
  Roles = 'ROLES',
  Remove = 'REMOVE',
  MakeGroupAdmin = 'MAKE_GROUP_ADMIN',
  RemoveGroupAdmin = 'REMOVE_GROUP_ADMIN',
}

export enum JobTabsAlias {
  Details = 'details',
  Applications = 'applications',
}

export enum RewardType {
  Money = 'MONEY',
  Points = 'POINTS',
  Voucher = 'VOUCHER',
  None = 'NONE',
  Badge = 'BADGE',
  Both = 'BOTH',
  Either = 'EITHER'
}

export enum ContractType {
  Share = 'SHARE',
  Custom = 'CUSTOM',
}

export enum BucketingPolicy {
  None = 'NONE',
  Common = 'COMMON',
  ByType = 'BY_TYPE',
  ByCompany = 'BY_COMPANY',
  ByCompanyAndType = 'BY_COMPANY_AND_TYPE',
}

export enum LocationBranding {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum LicenseType {
  Yes = 'YES',
  None = 'NONE',
}

export enum InviteeRoles {
  User = 'USER',
  Admin = 'ADMIN',
}

export enum ChatStatus {
  Active = 'ACTIVE',
  New = 'NEW',
  Resolved = 'RESOLVED',
  Abandoned = 'ABANDONED',
}

export enum LandingPageType {
  Default = 'DEFAULT',
  AllProducts = 'ALL_PRODUCTS',
}

export enum LoginMethods {
  Google = 'GOOGLE',
  Facebook = 'FACEBOOK',
  Apple = 'APPLE',
  Email = 'EMAIL',
}

export enum JobDescriptionAllowedTypes {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
}

export enum ButtonType {
  submit = 'submit',
  button = 'button',
}

export enum PerformingFilterTypes {
  best = 'best',
  all = 'all',
}

export enum MemberRole {
  member = 'MEMBER',
  admin = 'ADMIN',
  unknown = 'UNKNOWN',
}

export enum EntityType {
  user = 'U',
  bounty = 'B',
  response = 'R',
  comment = 'C',
  chat = 'H',
  message = 'M',
  attachment = 'A',
  company = 'COMPANY',
}

export enum BountyCardType {
  BOUNTY = 'BOUNTY',
  RESPONSE = 'RESPONSE',
  bounty = 'bounty',
  response = 'response',
  hidden = 'hidden',
  bulletin = 'bulletin',
}

export enum CreditCardTypes {
  Amex = 'AMEX',
  AmericanExpress = 'AMERICANEXPRESS',
  American_Express = 'AMERICAN_EXPRESS',
  Mc = 'MC',
  MasterCard = 'MASTERCARD',
  Master_Card = 'MASTER_CARD',
  Visa = 'VISA',
  Generic = 'GENERIC',
  Other = 'OTHER',
}

export enum ProfileStage {
  Unknown = 'UNKNOWN',
  Authed = 'AUTHED',
  Registered = 'REGISTERED',
  Minprofiled = 'MINPROFILED',
  Profiled = 'PROFILED',
  Vetted = 'VETTED',
}

export enum InvitationStatus {
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Voided = 'VOIDED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export enum PromotionFormType {
  Discount = 'DISCOUNT',
  SelectOne = 'SELECT_ONE'
}

export enum EmailStatus {
  None = 'NONE',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum TemplateType {
  Welcome = 'WELCOME',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  OrderConfirmationPickup = 'ORDER_CONFIRMATION_PICKUP',
  OrderConfirmationDelivery = 'ORDER_CONFIRMATION_DELIVERY',
  OrderConfirmationKiosk = 'ORDER_CONFIRMATION_KIOSK',
  OrderProcessedConfirmation = 'ORDER_PROCESSED_CONFIRMATION',
  Recommendation = 'RECOMMENDATION',
  Invite = 'INVITE',
  WeeklySummaryReport = 'WEEKLY_SUMMARY_REPORT',
  DailyUserCountReport = 'DAILY_USER_COUNT_REPORT',
  ReactivateUser = 'REACTIVATE_USER',
  CustomAlert = 'CUSTOM_ALERT',
  AssignCompany = 'ASSIGN_COMPANY',
  BountyReported = 'BOUNTY_REPORTED',
  ResponseReported = 'RESPONSE_REPORTED',
  NewCompany = 'NEW_COMPANY',
  NewCompanyOperator = 'NEW_COMPANY_OPERATOR',
  ApplicantApplied = 'APPLICANT_APPLIED',
}

export enum ReportType {
  UserStats = 'user-stats',
  SignUp = 'sign-up',
  ReaderUsers = 'reader-users',
  PnCampaign = 'pn-campaign',
  OrderHistory = 'order-history',
  NotLoggedInUsers = 'not-logged-in-users',
  LoggedInUsers = 'logged-in-users',
  JoinedUsers = 'joined-users',
  InviteesPersonal = 'invitees-personal',
  InviteesIndirect = 'invitees-indirect',
  InviteesDirect = 'invitees-direct',
  InviteesAny = 'invitees-any',
  InviteesAllUsers = 'invitees-all-users',
  GlobalInvitees = 'global-invitees',
  DailyNewUsers = 'daily-new-users',
  ContributorUsers = 'contributor-users',
  ContentUser = 'content-user',
}

export enum CurrencyScope {
  User = 'USER',
}

export enum AgreementType {
  Purchase = 'PURCHASE',
}

export enum SyncReportType {
  Categories = 'CATEGORIES',
  Locations = 'LOCATIONS',
  Products = 'PRODUCTS',
}

export enum SettingsStrategy {
  CorpOnly = 'CORP_ONLY',
  Inherit = 'INHERIT',
}

export enum WriteMode {
  Corporate = 'CORPORATE',
  Location = 'LOCATION',
}

export enum SyncReportTriggerType {
  Manual = 'MANUAL',
  Cron = 'CRON',
}

export enum CustomCurrencyStatus {
  Active = 'ACTIVE',
  OnCancelation = 'ON_CANCELATION',
  Expired = 'EXPIRED',
}

export enum ClientInfoStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum ClientStatus {
  Active = 'ACTIVE',
  ToBeDeleted = 'TO_BE_DELETED',
  Deleted = 'DELETED',
}

export enum DiscountType {
  None = 'NONE',
  PromoCode = 'PROMOCODE',
  Delivery = 'DELIVERY',
  Negotiation = 'NEGOTIATION',
  Unknown = 'UNKNOWN',
}

export enum TerminalPosition {
  Up = 'UP',
  Down = 'DOWN',
  Right = 'RIGHT',
  Left = 'LEFT',
  UpRight = 'UP_RIGHT',
  UpLeft = 'UP_LEFT',
  DownRight = 'DOWN_RIGHT',
  DownLeft = 'DOWN_LEFT'
}

export enum PrintReceipt {
  Yes = 'YES',
  No = 'NO',
}

export enum StartButtonPosition {
  None = 'NONE',
  UpLeft = 'UP_LEFT',
  Up = 'UP',
  UpRight = 'UP_RIGHT',
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT',
  DownLeft = 'DOWN_LEFT',
  Down = 'DOWN',
  DownRight = 'DOWN_RIGHT',
  Unknown = 'UNKNOWN',
}

export enum PhoneType {
  Mobile = 'Mobile',
  Work = 'Work',
}

export enum ReservationStatus {
  NotConfirmed = 'NotConfirmed',
  LeftMessage = 'LeftMessage',
  Confirmed = 'Confirmed',
  Late = 'Late',
  PartiallyArrived = 'Partially Arrived',
  AllArrived = 'AllArrived',
  PartiallySeated = 'Partially Seated',
  Seated = 'Seated',
  Appetizer = 'Appetizer',
  Entree = 'Entree',
  Dessert = 'Dessert',
  CheckDropped = 'CheckDropped',
  Paid = 'Paid',
  BusTable = 'BusTable',
  Done = 'Done',
  NoShow = 'NoShow',
  Cancelled = 'Cancelled',
  AssumedSeated = 'AssumedSeated',
  AssumedDone = 'AssumedDone',
}

export enum ReservationAttribute {
  Default = 'default',
  Hightop = 'hightop',
  Bar = 'bar',
  Counter = 'counter',
  Outdoor = 'outdoor',
}

export enum ProductSortOptions {
  PriceAsc = 'product.price_value$asc',
  PriceDesc = 'product.price_value$desc',
  NameAsc = 'product.name$asc',
  NameDesc = 'product.name$desc',
  WeightAsc = 'product.sort_weight$asc',
  WeightDesc = 'product.sort_weight$desc',
  ManufacturerAsc = 'product.manufacturer$asc',
  ManufacturerDesc = 'product.manufacturer$desc',
  Popularity = 'product.sort_popularity$desc',
}

export enum BackgroundArea {
  LandingPage = 'LANDING_PAGE',
}

export enum UrlType {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export enum ViewMode {
  Full = 'FULL',
  Simplified = 'SIMPLIFIED',
}

export enum AddressAutocomplete {
  None = 'NONE',
  Default = 'DEFAULT',
  Google = 'GOOGLE',
}

export enum TribalIdMode {
  None = 'NONE',
  AsDriverLicense = 'AS_DRIVERLICENSE',
}

export enum ChemicalViewMode {
  Hidden = 'HIDDEN',
  Tile = 'TILE',
  Details = 'DETAILS',
  TileDetails = 'TILE_DETAILS',
}

export enum ChemicalKind {
  Cannabinoid = 'CANNABINOID',
  Terpene = 'TERPENE',
  Other = 'OTHER',
}

export enum SupportChat {
  DisableChat = 'DISABLED',
  EnableChat = 'ENABLED',
}

export enum PolicyType {
  Shipping = 'SHIPPING',
  Return = 'RETURN',
  Refund = 'REFUND',
  Additional = 'ADDITIONAL',
}

export enum ChemicalFilters {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
}

export enum NextStep {
  Default = 'DEFAULT',
  Welcome = 'WELCOME'
}

export enum UserResultStatus {
  Deleted = 'DELETED',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED'
}

export enum TabsStyle {
  Default = 'DEFAULT',
  Pills = 'PILLS',
}

export enum ReportReasonTypes {
  hate = 'HATE',
  harassment = 'HARASSMENT',
  drug = 'DRUG',
  violence = 'VIOLENCE',
  dangerOrg = 'DANGER_ORG',
  malicious = 'MALICIOUS',
  misInformation = 'MISINFORMATION',
  privateInfo = 'PRIVATE',
  sexual = 'SEXUAL',
  spam = 'SPAM',
  offTopic = 'OFF_TOPIC',
  illegal = 'ILLEGAL',
  copyright = 'COPYRIGHT'
}

export enum PREDEFINED_CUSTOM_CHEMICALS_CAT {
  Global = '__GLOBAL_KEY',
  NoCat = '__NO_CAT_KEY',
}

export enum SEOType {
  Landing = 'LANDING',
  ProductDescription = 'PRODUCT_DESC',
}

export enum SEOVariables {
  StoreName = '{{storeName}}',
  StoreAddress = '{{storeAddress}}',
  StoreWebsite = '{{storeWebsite}}',
  ProductName = '{{productName}}',
  ProductDescription = '{{productDescription}}',
  ProductCategory = '{{productCategory}}',
  ProductBrand = '{{productBrand}}',
  ProductCannabisType = '{{productCannabisType}}',
}

export enum UserSupportGroup {
  None = 'NONE',
  Deeplink = 'DEEPLINK',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN',
}

export enum Scheme {
  Neutral = 'NEUTRAL',
  Joyful = 'JOYFUL',
  Serene = 'SERENE',
  Energetic = 'ENERGETIC',
  Mysterious = 'MYSTERIOUS',
  Romantic = 'ROMANTIC',
  Professional = 'PROFESSIONAL',
  Nostalgic = 'NOSTALGIC',
  Dramatic = 'DRAMATIC',
  Natural = 'NATURAL',
  Futuristic = 'FUTURISTIC',
}

export enum AuthProviderId {
  Apple = 'apple.com',
  Google = 'google.com',
}

export enum AuthType {
  Login = 'Login',
  Registration = 'Registration',
  Verify = 'Verify',
  ForgotPassword = 'ForgotPassword',
  Pin = 'Pin',
  Allow = 'Allow',
}

export enum TransactionType {
  None = 'NONE',
  CreditCharge = 'CREDIT_CHARGE',
  AdFee = 'AD_FEE',
  BountyPmntSent = 'BOUNTY_PMNT_SENT',
  BountyPmntRecv = 'BOUNTY_PMNT_RECV',
  DistroPayment = 'DISTRO_PAYMENT',
  RatingSent = 'RATING_SENT',
  RatingRecv = 'RATING_RECV',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  TaxFee = 'TAX_FEE',
  PlatformFee = 'PLATFORM_FEE',
  ProcessingFee = 'PROCESSING_FEE',
  Cashout = 'CASHOUT',
  AddVoucher = 'ADD_VOUCHER',
  AddBadge = 'ADD_BADGE',
  Adjustment = 'ADJUSTMENT',
  InitialBalance = 'INITIAL_BALANCE',
  Reversal = 'REVERSAL',
  DailyBonus = 'DAILY_BONUS',
  PostingBonus = 'POSTING_BONUS',
  ChargebackFee = 'CHARGEBACK_FEE',
  Chargeback = 'CHARGEBACK',
  AddInvite = 'ADD_INVITE',
  DeliveryFee = 'DELIVERY_FEE',
  AssignBadgeIn = 'ASSIGN_BADGE_IN',
  AssignBadgeOut = 'ASSIGN_BADGE_OUT',
  BadgeReward = 'BADGE_REWARD',
  FundingPayment = 'FUNDING_PAYMENT',
  Liability = 'LIABILITY',
  OrderPmntSent = 'ORDER_PMNT_SENT',
  OrderPmntRecv = 'ORDER_PMNT_RECV',
  OrderCredited = 'ORDER_CREDITED',
  OrderDebited = 'ORDER_DEBITED',
  CurExpSent = 'CUR_EXP_SENT',
  CurExpRecv = 'CUR_EXP_RECV',
  AccMergeOut = 'ACC_MERGE_OUT',
  AccMergeIn = 'ACC_MERGE_IN',
  Unknown = 'UNKNOWN',
}

export enum AgeRangeType {
  Teenage = "TEENAGE",
  YoungAdult = "YOUNG_ADULT",
  MiddleAdult = "MIDDLE_ADULT",
  OldAdult = "OLD_ADULT",
  YoungSenior = "YOUNG_SENIOR",
  MiddleSenior = "MIDDLE_SENIOR",
  OldSenior = "OLD_SENIOR",
}

export enum FeeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum DeliveryFeeSource {
  Merchant = 'MERCHANT',
  Product = 'PRODUCT',
  NoShippingRequired = 'NO_SHIPPING_REQUIRED',
}

export enum InterestType {
  Category = 'CATEGORY'
}

export enum TrustedTag {
  IsFeatured = 'is_featured',
}

export enum FlwrQuantityVariant {
  eightOz = 'eightOz',
  quarterOz = 'quarterOz',
  halfOz = 'halfOz',
  oneOz = 'oneOz',
}

export enum InvalidReason {
  NoDeliveryToCountry = 'NO_DELIVERY_TO_COUNTRY',
  MissingDeliveryAddress = 'MISSING_DELIVERY_ADDRESS',
  InsufficientRedemptionAmount = 'INSUFFICIENT_REDEMPTION_AMOUNT',
  InsufficientProductAmount = 'INSUFFICIENT_PRODUCT_AMOUNT'
}

export enum CardChargeStatus {
  None = 'NONE',
  Pending = 'PENDING',
  Charged = 'CHARGED',
  Failed = 'FAILED',
  Unknown = 'UNKNOWN',
}

export enum AlertType {
  NewRecvOrder = 'NEW_RECV_ORDER',
  OrderConfirmation = 'ORDER_CONFIRMATION',
  ChargeSucceeded = 'CHARGE_SUCCEEDED',
  PayoutReceived = 'PAYOUT_RECEIVED',
  SubscriptionPaid = 'SUBSCRIPTION_PAID',
  OrderShipped = 'ORDER_SHIPPED',
  Welcome = 'WELCOME',
}

export enum BountySection {
  TokenReward = 'TOKEN_REWARD',
  New = 'NEW',
  Scheduled = 'SCHEDULED',
  Expiration = 'EXPIRATION',
  Category = 'CATEGORY',
  Mood = 'MOOD',
  Express = 'EXPRESS',
  All = 'ALL',
}
export enum PosType {
  Alleaves = 'ALLEAVES',
  MjFreeway = 'MJ_FREEWAY',
  Biotrack = 'BIOTRACK',
}

export enum CategorySource {
  None = 'NONE',
  System = 'SYSTEM',
  Alleaves = 'ALLEAVES',
  MjFreeway = 'MJ_FREEWAY',
  BioTrack = 'BIO_TRACK',
  UNKNOWN = 'UNKNOWN',
}

export enum SslCertificateType {
  RootCertificate = 'ROOT_CERTIFICATE',
  Certificate = 'CERTIFICATE',
  Key = 'KEY'
}

export enum AlleavesEnv {
  Prod = 'PROD',
  Uat = 'UAT',
  Qa = 'QA',
  Dev = 'DEV',
}

export enum FontSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ObjectFit {
  Contain = 'contain',
  Cover = 'cover',
}

export enum ManifestType {
  None = 'NONE',
  M3U8 = 'M3U8',
  MPD = 'MPD',
  Unknown = 'UNKNOWN',
}

export enum PatternType {
  None = 'NONE',
  Chessboard = 'CHESSBOARD',
  DiagonalLines = 'DIAGONAL_LINES',
  QuarterCircles = 'QUARTER_CIRCLES',
  HorizontalLines = 'HORIZONTAL_LINES',
  FourDots = 'FOUR_DOTS',
  DownwardArrows = 'DOWNWARD_ARROWS',
  Unknown = 'UNKNOWN',
}

export enum AdvertPointerType {
  Category = 'CATEGORY',
  Products = 'PRODUCTS',
}