import { ChangeEvent, FC, useState } from "react";
import { Dialog, Grid2 as Grid, MenuItem, SelectChangeEvent, Slider } from "@mui/material";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { cloneDeep, merge } from "lodash";
import classnames from "classnames";

import TextLineForm from "Components/TextLineForm";
import SimpleButton from "Components/SimpleButton";
import NumberInput from "Components/NumberInput";
import CustomModalButtons from "Components/CustomModalButtons";
import ColorInput from "Components/ColorInput";
import StyledSelect from "Components/StyledSelect";
import Media from "Components/Media";
import CustomInput from "Components/CustomInput";

import { Attachment, AttachmentConfiguration } from "Types/attachment.interface";
import { capitalize } from "Utils/text";
import { OBJECT_FIT_OPTIONS } from "Constants/dictionaries";
import { getAttachmentConfigurationSchema } from "Utils/validation/attachmentConfigurationSchema";
import { getErrorsByName } from "Utils/formik";
import { BannerType } from "Constants/enums";
import { BrandingInterface } from "Types/client.interface";
import * as labelStyles from "Assets/scss/modules/label.module.scss";
import * as styles from "./index.module.scss";
import { getHeadlineStyle, getImageStyle, getInitialValues, getOverlayStyle } from "./utils";

interface AttachmentConfigurationDialogProps {
  attachment: Attachment;
  onChange: (attachment: Attachment) => void;
  bannerType?: BannerType;
  branding?: BrandingInterface;
}

const AttachmentConfigurationDialog: FC<AttachmentConfigurationDialogProps> = ({
  attachment,
  onChange,
  branding,
  bannerType = BannerType.Hero,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const url = attachment?.cdnUrl || attachment?.url || attachment?.localUri || '';
  const isSpecials = bannerType === BannerType.Specials;

  const handleChangeEvent = ({ target: { name, value } }: ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    handleChange(name, value);
  };

  const handleChange = (name: string, value: string) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeSlider = (name: string, value: number | number[]) => {
    formik.setFieldValue(name, value);
  };

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleSubmit = (values: AttachmentConfiguration) => {
    const newAttachment = cloneDeep(attachment);
    const updatedAttachment = merge(newAttachment, values);
    onChange(updatedAttachment);
    setIsOpen(false);
  };

  const formik = useFormik({
    initialValues: getInitialValues(attachment, bannerType),
    validationSchema: getAttachmentConfigurationSchema(bannerType),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <SimpleButton
        label={intl.formatMessage({ id: 'button.configure' })}
        onClick={handleOpen}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        slotProps={{
          paper: { className: classnames(styles.paper, { [styles.smallerPaper]: isSpecials } ) }
        }}
      >
        <h3 className="mb-10">
          {intl.formatMessage({ id: 'label.preview' })}
        </h3>
        <div className={classnames(styles.header, { [styles.videoHeader]: isSpecials, [styles.imageHeader]: !isSpecials })}>
          {isSpecials
            ? (
              <>
                <Media
                  height="200px"
                  url={url}
                  loop
                  isPlaying
                  className={styles.image}
                  style={getImageStyle(formik?.values?.background)}
                />
                <div className={styles.layout} />
                <div className={classnames(styles.overlay, styles.videoOverlay)} />
                <div className={styles.buttonWrapper}>
                  <div className={styles.bottomButton} style={{ backgroundColor: branding?.primaryColor?.val }}>
                    {formik?.values?.action?.btnLabel}
                  </div>
                </div>
              </>
            ) : (
              <img
                alt="hero"
                src={url}
                className={styles.image}
                style={getImageStyle(formik?.values?.background)}
              />
            )}
          <div className={classnames(styles.imageText, { [styles.specialsText]: isSpecials })}>
            {formik?.values?.headline1?.text && (
              <p style={getHeadlineStyle(formik?.values?.headline1)}>
                {formik?.values?.headline1?.text}
              </p>
            )}
            {formik?.values?.headline2?.text && (
              <p style={getHeadlineStyle(formik?.values?.headline2)}>
                {formik?.values?.headline2?.text}
              </p>
            )}
          </div>
          <div className={styles.overlay} style={getOverlayStyle(formik?.values?.overlay)} />
        </div>
        <div className={styles.form}>
          {!isSpecials && (
            <>
              <div>
                <h3 className="mb-10">
                  {capitalize(intl.formatMessage({ id: 'label.image' }))}
                </h3>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6 }}>
                    <ColorInput
                      name="background.color"
                      label="label.backgroundColor"
                      value={formik?.values?.background?.color || ''}
                      onChange={handleChange}
                      errors={getErrorsByName(formik, 'background.color')}
                    />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <label className={labelStyles.fieldLabel}>
                      {intl.formatMessage({ id: 'label.objectFit' })}
                    </label>
                    <StyledSelect
                      name="background.objectFit"
                      value={formik?.values?.background?.objectFit}
                      onChange={(e) => handleChangeEvent(e as SelectChangeEvent)}
                    >
                      {OBJECT_FIT_OPTIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </Grid>
                </Grid>
              </div>
              <div>
                <h3 className="mb-10">
                  {intl.formatMessage({ id: 'label.specifyOverlay' })}
                </h3>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6 }}>
                    <ColorInput
                      name="overlay.color"
                      label="label.color"
                      value={formik?.values?.overlay?.color || ''}
                      onChange={handleChange}
                      errors={getErrorsByName(formik, 'overlay.color')}
                    />
                  </Grid>
                  <Grid container spacing={0} alignItems="center" columnSpacing={2} size={{ xs: 6 }}>
                    <Grid size={{ xs: 12 }}>
                      <label className={labelStyles.fieldLabel}>
                        {intl.formatMessage({ id: 'label.opacity' })}
                      </label>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      <NumberInput
                        name="overlay.opacity"
                        value={formik?.values?.overlay?.opacity}
                        onChange={handleChangeEvent}
                        autoComplete="off"
                        decimalScale={1}
                        validationErrors={getErrorsByName(formik, 'overlay.opacity')}
                      />
                    </Grid>
                    <Grid size={{ xs: 6 }} display="flex" alignItems="center">
                      <Slider
                        name="overlay.opacity"
                        value={formik?.values?.overlay?.opacity}
                        aria-label="Default"
                        min={0}
                        max={1}
                        step={0.1}
                        className={getErrorsByName(formik, 'overlay.opacity')?.length > 0 ? "mb-20" : ''}
                        valueLabelDisplay="auto"
                        onChange={(e, value) => handleChangeSlider('overlay.opacity', value)}
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </div>
            </>
          )}
          <TextLineForm
            title={intl.formatMessage({ id: 'label.headline' })}
            textLine={formik?.values?.headline1}
            onChange={handleChange}
            parentName="headline1"
            bannerType={bannerType}
          />
          <TextLineForm
            title={intl.formatMessage({ id: 'label.subtitle' })}
            textLine={formik?.values?.headline2}
            onChange={handleChange}
            parentName="headline2"
            bannerType={bannerType}
          />
          {isSpecials && (
            <div>
              <h3 className="mb-10">
                {intl.formatMessage({ id: 'label.button' })}
              </h3>
              <label className={labelStyles.fieldLabel}>
                {`${intl.formatMessage({ id: 'label.label' })}*`}
              </label>
              <CustomInput
                name="action.btnLabel"
                value={formik?.values?.action?.btnLabel}
                onChange={handleChangeEvent}
                validationErrors={getErrorsByName(formik, 'action.btnLabel')}
              />
            </div>
          )}
        </div>
        <CustomModalButtons
          dismissName="button.cancel"
          submitName="button.save"
          onDismiss={handleClose}
          onSubmit={formik.handleSubmit}
        />
      </Dialog>
    </div>
  );
};

export default AttachmentConfigurationDialog;