import Radio from '@mui/material/Radio';
import * as styles from './styles.module.scss';

const RadioStyled = ({
  className = '',
  ...otherProps 
}) => (
  <Radio
    {...otherProps}
    className={`${styles.radioStyled} ${className}`}
  />
);

export default RadioStyled;
