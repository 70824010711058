import { useState, useEffect, useRef, FC, ReactElement } from 'react';
import { Grid2 as Grid, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import SelectableBountyCard from 'Components/SelectableBountyCard';
import EmptyState from 'Components/EmptyState';
import Pagination from 'Components/Pagination';
import EmptyImage from 'Assets/img/emptyContent/es_incoming.png';
import { Bounty } from 'Types/bounty.interface';
import { paginationItemsPerPage } from 'Constants/common';
import { getProductBackgroundImage } from 'Utils/helpers';
import LoaderOverlay from 'Components/LoaderOverlay';
import * as styles from './index.module.scss';

interface PickProductProps {
  emptyListMessage: string|ReactElement;
  handleSelectProduct: (item: Bounty) => void;
  getProducts: (filters: { start: number; length: number }) => Promise<{ list: Bounty[], totalCount: number}>;
  selectedProducts: Bounty[];
}

const PickProduct:FC<PickProductProps> = ({
  emptyListMessage,
  handleSelectProduct,
  selectedProducts,
  getProducts,
}) => {
  const [bounties, setBounties] = useState<Bounty[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startItem, setStartItem] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();

  const fetchProducts = async () => {
    setIsLoading(true);

    const filter = {
      start: startItem,
      length: paginationItemsPerPage,
    };

    try {
      const { list, totalCount } = await getProducts(filter);
      setBounties(list || []);
      setTotalCount(totalCount || 0);

      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } catch {
      setStartItem(1);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [startItem]);

  const isActive = (bounty: Bounty) => !!selectedProducts?.find((item) => item.id === bounty.id);

  return (
    <div ref={ref} className={styles.root}>
      {bounties?.length || isLoading ? (
        <Grid container spacing={3}>
          {bounties.map((bounty) => (
            <Grid size={{ xs: 6 }} key={bounty.id}>
              <SelectableBountyCard<Bounty>
                description={bounty?.product?.name || ''}
                backgroundImage={getProductBackgroundImage(bounty.product)}
                bounty={bounty}
                handleSelect={handleSelectProduct}
                isActive={isActive(bounty)}
                className={styles.card}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyState
          title={emptyListMessage}
          subTitle={intl.formatMessage({ id: 'emptyContent.stayTuned' })}
          emptyImage={EmptyImage}
        />
      )}
      {totalCount > paginationItemsPerPage && (
        <Box my={2}>
          <Pagination
            disabled={isLoading}
            offset={startItem}
            total={totalCount}
            onChange={setStartItem}
          />
        </Box>
      )}

      {isLoading && <LoaderOverlay />}
    </div>
  );
};

export default PickProduct;
