import { FC } from "react";
import { useIntl } from "react-intl";

import ResponsiveGrid, { ResponsiveGridHeading } from "Components/ResponsiveGrid";
import CheckboxStyled from "Components/CheckboxStyled";

import { Bounty } from "Types/bounty.interface";
import { addCellProps } from "Utils/responsiveGridUtils";
import {  getTopLevelVariant } from "Utils/product";
import ImagePlaceholder from 'Assets/img/general/image_placeholder.png';
import * as styles from './index.module.scss';

interface ProductsListProps {
  products: Bounty[];
  selectedProducts: Bounty[];
  onSelectProduct: (product: Bounty) => void;
}

const ProductsList: FC<ProductsListProps> = ({
  products,
  selectedProducts,
  onSelectProduct,
}) => {
  const intl = useIntl();

  const headings: ResponsiveGridHeading[] = [
    {
      title: '',
      id: 'checkbox',
      className: styles.checkbox,
    },
    {
      title: '',
      id: 'image',
      className: styles.icon,
    },
    {
      title: intl.formatMessage({ id: 'label.name' }),
      id: 'name',
    },
    {
      title: intl.formatMessage({ id: 'label.description' }),
      id: 'description',
    },
    {
      title: intl.formatMessage({ id: 'label.brand' }),
      id: 'brand',
    },
  ];

  const generateRow = (bounty: Bounty) => {
    const { manufacturer } = getTopLevelVariant(bounty?.product);
    const isSelected = selectedProducts?.some(({ id }) => id === bounty?.id);

    const cellValues = [
      <CheckboxStyled
        name="shopSettings.logins.email"
        checked={isSelected}
        onClick={() => onSelectProduct(bounty)}
      />,
      <img className={styles.image} src={bounty?.product?.imageUrl || ImagePlaceholder} alt="productImage" />,
      bounty?.product?.name || '-',
      bounty?.product?.description || '-',
      manufacturer || '-',
    ];

    return cellValues.map((value, index) => (
      <ResponsiveGrid.Cell
        {...addCellProps(headings, index)}
        value={value}
        key={index}
      />
    ));
  };

  return (
    <section id={styles.productsList}>
      <ResponsiveGrid.Table>
        <ResponsiveGrid.Row header>
          {headings.map((h) => (
            <ResponsiveGrid.HeadCell
              key={`header-${h.id}`}
              id={h.id}
              className={h.className}
              label={h.title}
              tooltipLabel={h.tooltipLabel}
            />
          ))}
        </ResponsiveGrid.Row>
        <tbody>
          {products.map((product) => (
            <ResponsiveGrid.Row key={product.id} onClick={() => onSelectProduct(product)}>
              {generateRow(product)}
            </ResponsiveGrid.Row>
          ))}
        </tbody>
      </ResponsiveGrid.Table>
      {!products?.length && (
        <p className={styles.emptyState}>
          {intl.formatMessage({ id: 'label.thisLocationNoProducts' })}
        </p>
      )}
    </section>
  );
};

export default ProductsList;
