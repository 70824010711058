import { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

//  Components

//  Actions/Selectors
import { closeModal } from 'Store/modal/actions';
import { moveBountyToList } from 'Services/bounty/BountyService';
import { userDataSelector } from 'Store/settings/selectors';

//  Other resources
import { getPostableListDefs, getStreamListDefsSelector } from 'Utils/models/ListManager';
import { Bounty } from 'Types/bounty.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import { ListDef } from 'Types/listDef.interface';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

interface MoveBountyProps {
  bounty: Bounty;
}

const MoveBounty:FC<MoveBountyProps> = ({ bounty }) => {
  const [optionsList, setOptionsList] = useState<ListDef[]>([]);
  const [selectedList, setSelectedList] = useState<ListDef|null>(null);

  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();
  const streamLists = useSelector(getStreamListDefsSelector) || [];
  const userData = useSelector(userDataSelector)?.data || {};

  useEffect(() => {
    setOptionsList(getPostableListDefs(streamLists, userData, bounty.type));
  }, []);

  const handleChanges = (list: ListDef) => {
    setSelectedList(list);
  };

  const handleSubmit = () => {
    if (!selectedList) {
      return notificationToast.info(intl.formatMessage({ id: 'info.pleaseSelectList' }));
    }

    dispatch(moveBountyToList({ bounty, list: selectedList }))
      .then(onCloseModal);
  };

  const onCloseModal = () => dispatch(closeModal());

  return (
    <div>
      <div className={styles.root}>
        {optionsList.map((list) => (
          <div
            role="presentation"
            className={classnames(styles.item, {
              [styles.active]: list.name === selectedList?.name,
            })}
            key={list.name}
            onClick={() => handleChanges(list)}
          >
            {list.name}
          </div>
        ))}
      </div>
      <CustomModalButtons
        onDismiss={onCloseModal}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default MoveBounty;
