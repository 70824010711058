import { BountyState, BountyType } from 'Constants/enums';
import { createBounty, fetchBounty, onEditBounty } from 'Services/bounty/BountyService';
import { userDataSelector } from 'Store/settings/selectors';
import { setAttachments } from 'Store/attachments/actions';
import { notificationToast } from 'Utils/notificationToaster';
import { setJobBountyOutbound } from 'Utils/helpers';
import { identityMode } from 'Utils/identityMode';
import { AppDispatch } from 'Types/redux.types';
import { UserData } from 'Types/userData.interface';
import { JobBounty } from 'Types/jobBounty.interface';
import { Bounty } from 'Types/bounty.interface';

export enum Actions {
  Loading = 'bounty/Loading',
  GetBounty = 'bounty/Get',
  Reset = 'bounty/Reset',
}

const updateLoadingStatus = (bool: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: Actions.Loading,
    payload: bool,
  });
};

export const getBounty = (id: string, creatorId: string, bountyType: BountyType) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  // TODO: add types for fetchBounty
  fetchBounty(id, creatorId, bountyType)
    .then((response) => {
      const { attachments, ...rest } = (response as JobBounty | Bounty);
      const bounty = rest.type === BountyType.Job ? setJobBountyOutbound(rest) : rest;

      dispatch({ type: Actions.GetBounty, payload: bounty });
      if (attachments && attachments.attachments) {
        setAttachments({
          attachments: attachments.attachments,
          inlineAttachments: Object.values(attachments.attachments),
        })(dispatch);
      }
    })
    .catch((err) => notificationToast.error(err.message))
    .finally(() => updateLoadingStatus(false)(dispatch));
};

export const setPredictedJobDescription = (predictedBounty: JobBounty) => (dispatch) =>
  dispatch({ type: Actions.GetBounty, payload: predictedBounty });

export const createJobBounty = (bounty : Bounty, attachments = []) => (dispatch, getState) => {
  const state = getState();

  const userData = userDataSelector(state).data || {};

  const bountyDataToCreate = {
    title: bounty.title,
    description: bounty.description,
    jobInfo: bounty.jobInfo,
    state: BountyState.Draft,
    type: BountyType.Job,
    owner: (userData as UserData).owner,
    identityMode: identityMode.REAL,
  };

  dispatch(createBounty(bountyDataToCreate, attachments));
  return Promise.resolve();
};

// TODO: provide correct type to stateAttachments
export const updateJobBounty = (newBounty: JobBounty, stateAttachments?: any) => (dispatch, getState) => {
  const { jobReducer } = getState();
  return dispatch(onEditBounty({ newBounty, oldBounty: jobReducer.bounty, stateAttachments }));
};

export const eraseBounty = () => (dispatch) => dispatch({ type: Actions.Reset });
