import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";

import ProductForm from 'Components/ProductForm';
import LoaderOverlay from 'Components/LoaderOverlay';
import FilesToUpload from 'Components/FilesToUpload';
import TooltipAnchor from 'Components/TooltipAnchor';
import SimpleButton, { SimpleButtonDecoration } from 'Components/SimpleButton';
import CustomDropzone from 'Components/CustomDropzone/index';
import ShippingFeesDrawer from "Containers/ProductCreate/ShippingFeesDrawer";

import { closeDrawer } from 'Store/createBounty/actions';
import { configurationSelector, selectedBountySelector } from 'Store/createBounty/selectors';
import { createProduct, editProduct } from 'Services/ProductService';
import { closeModal, openModal } from "Store/modal/actions";

import { getProductModalTitle, getProductValidationSchema } from 'Utils/product';
import { companySettingsSelector } from 'Store/settings/selectors';
import { generateRewardTypeOptions } from 'Utils/reward';
import { Currency, DeliveryFeeSource, ProductKind, RewardType } from 'Constants/enums';
import { PRODUCT_IMAGE_MIME_TYPES, UPLOAD_FILE_SIZE } from 'Constants/attachment';
import { prepareAcceptType } from 'Utils/attachments';
import { getCurrentUseCase } from 'Utils/useCases';
import { DEFAULT_DELIVERY_FEE } from "Constants/fees";
import { getInitialValues } from "Containers/ProductCreate/utils";
import { MY_SHOP_ROUTE } from "Constants/routes";
import { saveProductChangesModal } from "Constants/modals";
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const ProductCreate = () => {
  const selectedBounty = useSelector(selectedBountySelector) || {};
  const configuration = useSelector(configurationSelector);
  const companySettings = useSelector(companySettingsSelector)?.data || {};
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const [attachments, setAttachments] = useState([]);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const companyCurrency = companySettings.currency || Currency.USD;
  const {
    kind,
    onCallback,
    visibility,
    multipleAttachments = true,
  } = configuration;
  const productKind = kind || selectedBounty?.product?.kind;
  const isToken = productKind === ProductKind.Token;
  const isEditMode = !!(selectedBounty && Object.keys(selectedBounty).length);

  useEffect(() => {
    const currencies = generateRewardTypeOptions({
      rewardTypes: isToken
        ? [RewardType.Money]
        : [RewardType.Money, RewardType.Points, RewardType.Both, RewardType.Either],
      localCurrency: companyCurrency,
    });
    const values = getInitialValues(selectedBounty, visibility, productKind, isToken, companyCurrency);
    setInitialValues(values);
    setCurrencyOptions(currencies);
  }, [selectedBounty]);

  const handleSuccess = (bounty) => {
    if (onCallback) {
      onCallback(bounty);
    }

    dispatch(closeDrawer());
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (selectedBounty && Object.keys(selectedBounty).length) {
      return dispatch(editProduct(values, productKind, selectedBounty, attachments, removedAttachments))
        .then(handleSuccess)
        .finally(() => setSubmitting(false));
    }

    dispatch(createProduct(values, productKind, attachments))
      .then(handleSuccess)
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: getProductValidationSchema(productKind, isToken),
  });

  const handleClose = () => dispatch(closeDrawer());

  const title = useMemo(() => (
    getProductModalTitle(isEditMode, productKind, isToken)
  ), [isEditMode, productKind, isToken]);

  const handleUpload = (files) => {
    setAttachments((prevState) => ([...files, ...prevState]));
  };

  const handleRemove = (attachment) => {
    setRemovedAttachments((prevState) => ([...prevState, attachment]));
  };

  const handleOpenDrawer = () => setIsDrawerOpen(true);

  const handleCloseDrawer = ({ shouldReset = false }) => {
    if (shouldReset) {
      formik.setFieldValue('deliveryFeeSource', DeliveryFeeSource.Merchant);
      formik.setFieldValue('extraDeliveryFee', DEFAULT_DELIVERY_FEE);
    }

    setIsDrawerOpen(false);
  };

  const handleRedirectToFees = () => {
    if (formik.dirty) {
      setIsDrawerOpen(false);
      dispatch(openModal(saveProductChangesModal({
        onCancel: () => dispatch(closeModal()),
        onSave: () => {
          dispatch(closeModal());
          formik.handleSubmit();
        },
      })));
      return;
    }

    setIsDrawerOpen(false);
    handleClose();
    navigate(`${MY_SHOP_ROUTE}?tab=shippingFeesSetup`);
  };

  const handleSaveCustomFees = (shippingFees) => formik.setFieldValue('extraDeliveryFee', shippingFees);

  useEffect(() => {
    const { extraDeliveryFee, deliveryFeeSource } = formik?.values || {};

    if (deliveryFeeSource === DeliveryFeeSource.Product && isEqual(extraDeliveryFee, DEFAULT_DELIVERY_FEE) && !isDrawerOpen) {
      handleOpenDrawer();
    }
  }, [formik?.values?.extraDeliveryFee, formik?.values?.deliveryFeeSource]);

  return (
    <div className={classnames({ [styles.extendedWidth]: getCurrentUseCase().isCampaigns })}>
      <h3 className="mb-30">{title}</h3>

      <ProductForm
        formik={formik}
        currencyOptions={currencyOptions}
        kind={productKind}
        onSeeFees={handleOpenDrawer}
      />

      <label className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'label.productImage' })}
        {' '}
        <TooltipAnchor translationKey="hint.product.image" />
      </label>
      <CustomDropzone
        accept={prepareAcceptType(PRODUCT_IMAGE_MIME_TYPES)}
        onFilePick={handleUpload}
        onSetLoading={setIsUploading}
        fileSizeLimit={UPLOAD_FILE_SIZE.EXTRA_SMALL * 5}
        isMultiple={multipleAttachments}
      >
        <SimpleButton
          decoration={SimpleButtonDecoration.Stroke}
          label={intl.formatMessage({ id: 'label.uploadImage' })}
          iconClass="material-symbols-rounded file_upload mr-5"
          isLoading={isUploading}
          className={classnames(styles.uploadButton, styles.uploadButtonSpacing)}
        />

      </CustomDropzone>

      <FilesToUpload
        bounty={selectedBounty}
        setAttachments={setAttachments}
        attachments={attachments}
        onRemove={handleRemove}
        cols={3}
      />

      <CustomModalButtons
        className="pb-20"
        onDismiss={handleClose}
        onSubmit={formik.handleSubmit}
        disabled={formik.isSubmitting}
      />

      {isDrawerOpen && (
        <ShippingFeesDrawer
          isOpen={isDrawerOpen}
          onClose={handleCloseDrawer}
          deliveryFeeSource={formik?.values?.deliveryFeeSource}
          extraDeliveryFee={formik?.values?.extraDeliveryFee}
          onSave={handleSaveCustomFees}
          onRedirect={handleRedirectToFees}
        />
      )}

      {formik.isSubmitting && <LoaderOverlay />}
    </div>
  );
};

export default ProductCreate;
