import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import CustomDropzone from 'Components/CustomDropzone';
import LoaderOverlay from 'Components/LoaderOverlay';
import CategorySelection from "Containers/BannerCreation/CategorySelection";

import { Attachment } from 'Types/attachment.interface';
import { AttachmentAction, CompanyType } from 'Constants/enums';
import { companySettingsSelector, userDataSelector } from 'Store/settings/selectors';
import { IMAGE_MIME_TYPES } from 'Constants/attachment';
import * as styles from './index.module.scss';

interface HighlightBannerProps {
  selectedCompanyId?: string;
  attachment: Attachment;
  onChange: (attachment: Partial<Attachment>) => void;
  index: number;
}
const acceptedFiles = IMAGE_MIME_TYPES.reduce((prevVal, currVal) => ({ ...prevVal, [currVal]: [] }), {});

const HighlightBanner: FC<HighlightBannerProps> = ({
  selectedCompanyId,
  attachment,
  onChange,
  index,
}) => {
  const intl = useIntl();
  const userData = useSelector(userDataSelector)?.data || {};
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMerchant = currentOrgType === CompanyType.Merchant;
  const companyId = isMerchant ? userData?.company?.id : selectedCompanyId;
  const url = attachment?.cdnUrl || attachment?.url || attachment?.localUri;

  const handlePickFile = (data: Attachment[]) => {
    onChange({ ...attachment, ...data?.[0] });
  };

  const handleClear = () => onChange({ action: { actionType: AttachmentAction.Search } });

  return (
    <div>
      <div className={styles.header}>
        <h4>{intl.formatMessage({ id: 'label.categoryNumber' }, { number: index + 1 })}</h4>
        {url && (
          <span
            role="presentation"
            onClick={handleClear}
            className={classnames(styles.clear, 'material-symbols-rounded')}
          >
            close
          </span>
        )}
      </div>

      <div className={styles.cardWrapper}>
        {url ? (
          <img
            alt="hero"
            src={url}
            className={styles.image}
          />
        ) : (
          <CustomDropzone
            accept={acceptedFiles}
            onFilePick={handlePickFile}
            onSetLoading={setIsLoading}
          >
            <div className={styles.uploadButton}>
              <span className="material-symbols-rounded font-24 mr-5">
                <span className="upload" />
              </span>
              <b>{intl.formatMessage({ id: 'button.uploadImage' })}</b>
            </div>
          </CustomDropzone>
        )}
      </div>

      <CategorySelection
        companyId={companyId}
        onChange={onChange}
        attachment={attachment}
        onLoading={setIsLoading}
      />

      {isLoading && <LoaderOverlay />}
    </div>
  );
};

export default HighlightBanner;
