import { deleteApiData, getApiData, postApiData, putApiData } from 'Services/Api';
import { CATEGORIES_OPEN_ROUTE, CATEGORIES_ROUTE, CATEGORY_DETAILS_ROUTE } from 'Constants/apiRoutes';
import { Category, CreateCategory } from 'Types/category.interface';
import { formatApiRoute } from 'Utils/api';
import { BountyType } from 'Constants/enums';

export const updateCategories = (category: Category, clientId: string) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_ROUTE,
    params: {},
    queryParams: { clientId },
  });

  return putApiData(endpoint, category);
};

export const getOpenCategories = (bountyType: BountyType, clientId?: string) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_OPEN_ROUTE,
    params: { bountyType },
    queryParams: clientId ? { clientId } : {},
  });

  return getApiData<Category>(endpoint);
};

export const getCategories = (clientId: string, bountyType: BountyType) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_ROUTE,
    params: { },
    queryParams: { clientId, categoryType: bountyType },
  });

  return getApiData<Category>(endpoint);
};

export const removeCategory = (clientId: string, categoryId: string) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORY_DETAILS_ROUTE,
    params: { categoryId },
    queryParams: { clientId },
  });

  return deleteApiData(endpoint);
};

export const createCategory = (clientId: string, payload: CreateCategory) => {
  const endpoint = formatApiRoute({
    endpoint: CATEGORIES_ROUTE,
    params: {},
    queryParams: { clientId },
  });

  return postApiData(endpoint, payload);
};