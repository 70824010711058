import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect } from 'react';

import GroupCreateMembers from 'Components/GroupCreateMembers';
import CustomLoader from 'Components/CustomLoader';

import { closeModal } from 'Store/modal/actions';
import { updateMembers, getMyColleagues, clearMyColleagues } from 'Store/groups/actions';
import { ownerSelector } from 'Store/settings/selectors';
import { isColleaguesLoadingSelector, colleaguesSelector } from 'Store/groups/selectors';

import { ExtendedGroupMember, Group, GroupMember } from 'Types/group.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

interface GroupAddMemberProps {
  group: Group | ExtendedGroupMember
}

const GroupAddMember: FC<GroupAddMemberProps> = ({ group }) => {
  const dispatch = useDispatch<ThunkDispatchType>();

  const userDataOwner = useSelector(ownerSelector) || {};
  const isMembersLoading = useSelector(isColleaguesLoadingSelector);
  const members = useSelector(colleaguesSelector);

  useEffect(() => {
    if (userDataOwner?.id) {
      dispatch(getMyColleagues({ ownerId: userDataOwner?.id }));
    }

    return () => {
      dispatch(clearMyColleagues());
    };
  }, [userDataOwner?.id]);

  const handleCloseModal = () => dispatch(closeModal());

  const handleSubmit = (values: { groupId: string; membersIds: Record<string, boolean> }) => {
    updateMembers({ values, groupMembers: group?.members });
    handleCloseModal();
  };

  const formatMembers = (members: GroupMember[]) => {
    const formattedMembers: Record<string, boolean> = {};

    members.forEach((member) => {
      formattedMembers[member.user.id] = true;
    });

    return formattedMembers;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: group?.user?.id || '',
      membersIds: formatMembers(group?.members),
    },
    onSubmit: handleSubmit,
  });

  const handleChange = (memberId: string, { target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('membersIds', {
      ...formik?.values?.membersIds,
      [memberId]: checked,
    });
  };

  return (
    <CustomLoader isLoading={isMembersLoading}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <GroupCreateMembers
          onChange={handleChange}
          values={formik?.values?.membersIds}
          members={members}
        />
        <input type="submit" hidden />
      </form>
      <div className={styles.navigation}>
        <CustomModalButtons
          submitName="button.add"
          onDismiss={handleCloseModal}
          onSubmit={formik.handleSubmit}
        />
      </div>
    </CustomLoader>
  );
};

export default GroupAddMember;
