import firebase from 'firebase/compat/app';
import { getQueueName, getQueueParam } from 'Utils/queues';
import { getApplicationConfig } from 'Utils/appConfig';
import {
  checkStatus,
  downloadFile,
  errorParser,
  formatApiRoute,
  getClientId,
  getFilename,
  getHeaders,
  getUserAgent,
  responseParser,
} from 'Utils/api';
import { QueueAlias } from 'Constants/enums';
import { EVENTS_ROUTE, REQUESTS } from 'Constants/apiRoutes';
import { firebaseGetCurrentlySignedInUser, firebaseGetCurrentUser, firebaseGetTimestamp, firebaseQueuesRef } from './FirebaseService';

const { API_BASE_URL } = getApplicationConfig();

export const addToQueue = (alias: QueueAlias, data: any): Promise<void> => {
  const queueName: string | null = getQueueName(alias);
  const queueParam: string | null = getQueueParam(alias);

  if (!queueName) return Promise.resolve();

  const userId = (firebaseGetCurrentUser() as firebase.User).uid;
  const clientId = getClientId();

  if (!queueName || !queueParam) {
    return Promise.resolve();
  }

  const meta = {
    action: queueName,
    userId,
    clientId,
    createdAt: new Date().getTime(),
    addedAt: firebaseGetTimestamp(),
    agent: getUserAgent(),
    sync: null,
  };

  const dataToSet = {
    meta,
    ...data,
  };

  return firebaseQueuesRef(queueParam)
    .push()
    .set(dataToSet);
};

export const postEvent = (alias: QueueAlias, data: any): Promise<void> => {
  const action: string | null = getQueueName(alias);

  if (!action) {
    const error = {
      message: 'We could not found the action for your event',
      status: 500,
    };

    throw error;
  }

  const endpoint = formatApiRoute({ endpoint: EVENTS_ROUTE, params: { action } });
  return postApiData(endpoint, data);
};

export const getApiData = async <TResponse = Response>(path: string, signal?: AbortSignal): Promise<TResponse> => {
  const token = await firebaseGetCurrentlySignedInUser() as string | null;
  const reqOpts = { headers: getHeaders(token), signal: signal as AbortSignal };
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, reqOpts)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser) as Promise<TResponse>;
};

export const runDownloadFile = async <TResponse = Response>(path: string, signal?: AbortSignal): Promise<TResponse> => {
  const token = await firebaseGetCurrentlySignedInUser() as string | null;
  const reqOpts = { headers: getHeaders(token), signal: signal as AbortSignal };
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, reqOpts)
    .then(checkStatus)
    .then((response) => {
      const contentType = response.headers.get('Content-Type');
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = getFilename(contentDisposition);

      if (!contentType || !contentDisposition) {
        throw new Error();
      }

      return response.blob().then((res) => {
        downloadFile(res, filename, contentType);
      });
    })
    .catch(errorParser) as Promise<TResponse>;
};

export const postRequest = async (queueAlias: QueueAlias, data = {}) => {
  const userId = (firebaseGetCurrentUser() as firebase.User).uid;
  const token = await firebaseGetCurrentlySignedInUser() as string | null;
  const clientId = getClientId();
  const action = getQueueName(queueAlias);
  const url = `${API_BASE_URL}${REQUESTS}/${action}`;
  const meta = {
    action,
    userId,
    clientId,
    createdAt: new Date().getTime(),
    // addedAt: firebaseGetTimestamp(),
    agent: getUserAgent(),
    // sync: null,
  };
  const dataToSet = { meta, ...data };

  const reqOpts = { headers: getHeaders(token), method: 'POST', body: JSON.stringify(dataToSet) };

  return fetch(url, reqOpts)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const customGetTokenApiData = async <TResponse = Response>(path: string, token: string | null): Promise<TResponse> => {
  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const customTokenPostApiData = async <TResponse = Response>(path: string, body: any, token: string | null): Promise<TResponse> => {
  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const uploadFileApi = async <TResponse = Response>(path: string, data: FormData): Promise<TResponse> => {
  const token = await firebaseGetCurrentlySignedInUser() as string | null;
  const url = `${API_BASE_URL}${path}`;

  const reqOpts = {
    headers: getHeaders(token, true),
    method: 'POST',
    body: data,
  };

  return fetch(url, reqOpts)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser) as Promise<TResponse>;
};

const updateApiData = async <TResponse = Response>(path, data, method): Promise<TResponse> => {
  const token = await firebaseGetCurrentlySignedInUser() as string | null;
  const url = `${API_BASE_URL}${path}`;

  const reqOpts = {
    headers: getHeaders(token),
    method,
    body: JSON.stringify(data),
  };

  return fetch(url, reqOpts)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser) as Promise<TResponse>;
};

export const putApiData = async <TResponse = Response>(path: string, data: any = {}): Promise<TResponse> =>
  updateApiData(path, data, 'PUT');

export const patchApiData = async <TResponse = Response>(path: string, data: any = {}): Promise<TResponse> =>
  updateApiData(path, data, 'PATCH');

export const postApiData = async <TResponse = Response>(path: string, data: any = {}): Promise<TResponse> =>
  updateApiData(path, data, 'POST');

export const deleteApiData = async (path): Promise<Response | void> => {
  const token = await firebaseGetCurrentlySignedInUser() as string | null;

  if (!token) {
    return Promise.reject(new Error('No token found'));
  }

  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;
  return fetch(url, {
    method: 'DELETE',
    headers,
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const postWithEmptyResponse = async (path, token): Promise<Response | void> => {
  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;
  return fetch(url, {
    method: 'POST',
    headers,
  })
    .then(checkStatus)
    .catch(errorParser);
};

export const getDataUsingUser = async (path, user): Promise<{ jwt: string }> => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${user.email}:${user.password}`)}`,
    },
  };
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error);
};
