import { AttachmentType, AttachmentSourceOptions } from 'Constants/enums';

export const IMAGE_MIME_TYPES = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
];

export const PRODUCT_IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpeg',
];

export const WORD_MIME_TYPES = [
  'application/doc',
  'application/ms-doc',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/avi',
  'video/mpeg',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-flv',
  'video/3gpp',
  'video/3gpp2'
];

export const CERTIFICATION_TYPES = [
  'application/x-pem-file', // Common MIME type for PEM files
  'application/pem-certificate-chain', // MIME type for PEM certificate chains
  'application/pkcs8', // Occasionally used for private keys in PEM format
  'application/x-x509-ca-cert', // Sometimes used for PEM-encoded X.509 certificates
  'application/x-iwork-keynote-sffkey',
  'application/x-x509-user-cert',
];

export const PDF_MIME_TYPE = 'application/pdf';

export const VIDEO_MIME_TYPE = 'video/*';

export const UPLOAD_FILE_SIZE = {
  DEFAULT: 104857600, // is equal 100mb
  CONTRACT: 52428800, // is equal 50mb
  SMALL: 10485760, // is equal 10mb
  EXTRA_SMALL: 1048576, // is equal 1mb
};

export const DEFAULT_MIME_TYPES = [
  ...IMAGE_MIME_TYPES,
  PDF_MIME_TYPE,
  VIDEO_MIME_TYPE,
];

export const CONTRACT_MIME_TYPES = [
  ...WORD_MIME_TYPES,
  PDF_MIME_TYPE,
];

export const IMAGE_MIME_TYPES_SIGNATURES = [
  '47494638', // gif
  'ffd8ffe0', // jpg
  'ffd8ffe1', // jpg
  'ffd8ffe8', // jpg
  'fff8ffe0', // jpeg
  '89504e47', // png
];

export const CONTRACT_MIME_TYPES_SIGNATURES = [
  '25504446', // pdf
  'd0cf11e0', // doc
  '504b34', // docx
];

export const PDF_MIME_TYPE_SIGNATURE = '25504446';

export const ATTACHMENTS_OPTIONS_PROPS = {
  [AttachmentSourceOptions.AudioLibrary]: {
    attachmentType: AttachmentType.Audio,
    type: AttachmentSourceOptions.AudioLibrary,
    icon: 'audiotrack',
    label: 'library.audio',
    accept: ['audio/*'],
  },
  [AttachmentSourceOptions.VideoLibrary]: {
    attachmentType: AttachmentType.Video,
    type: AttachmentSourceOptions.VideoLibrary,
    icon: 'video_library',
    label: 'library.video',
    accept: ['video/*'],
  },
  [AttachmentSourceOptions.PhotoLibrary]: {
    attachmentType: AttachmentType.Image,
    type: AttachmentSourceOptions.PhotoLibrary,
    icon: 'photo_library',
    label: 'library.photo',
    accept: ['image/*'],
  },
  [AttachmentSourceOptions.PDFFiles]: {
    attachmentType: AttachmentType.PDF,
    type: AttachmentSourceOptions.PDFFiles,
    icon: 'picture_as_pdf',
    label: 'library.pdf',
    accept: [PDF_MIME_TYPE],
  },
  [AttachmentSourceOptions.TakePhoto]: {
    attachmentType: AttachmentType.Image,
    type: AttachmentSourceOptions.TakePhoto,
    icon: 'photo_camera',
    label: 'library.takePicture',
  },
};
