import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'vip',
  title: 'VIP',
  name: 'VIP Console',
  brandName: 'INK Console',
  appLinkSchema: 'vip',
  appTld: 'inkentertainment.com',
  useCase: 'vip',
  tags: ['all'],
  supportEmail: 'support@inkentertainment.com',
  auth: {
    disablePinFlow: true,
    disabledPins: ['1515'],
    customCss: '',
  },
  custom: {
    isLanguageSelectorHidden: true,
  },
  TERMS_OF_SERVICE_URL: 'https://inkentertainment.com/terms-of-use',
  PRIVACY_STATEMENT_URL: 'https://inkentertainment.com/privacy-policy',
  customPages: {
    home: 'AsyncReservationsHomePage',
  },
};

export default config;
