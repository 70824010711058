import classnames from 'classnames';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import ProductsSelection from "Containers/BannerCreation/ProductsSelection";

import EmptyImage from 'Assets/img/general/image_placeholder.png';
import { Product } from 'Types/product.interface';
import { Bounty } from "types/bounty.interface";
import * as styles from './index.module.scss';

interface SelectProductCardProps {
  index?: number;
  product?: Product | null;
  onSelectProducts: (bounties: Bounty[]) => void;
  onRemoveProduct: () => void;
  hasTitle?: boolean;
  companyId: string;
  selectedProducts: Bounty[];
}

const SelectProductCard: FC<SelectProductCardProps> = ({
  index = 0,
  product,
  onSelectProducts,
  onRemoveProduct,
  companyId,
  selectedProducts,
  hasTitle = false,
}) => {
  const intl = useIntl();
  const imageUrl = product?.imageCdn?.imageUrl || EmptyImage;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {hasTitle && (
          <h4>{intl.formatMessage({ id: 'label.itemNumber' }, { number: index + 1 })}</h4>
        )}
        <span
          role="presentation"
          onClick={onRemoveProduct}
          className={classnames(styles.clear, 'material-symbols-rounded')}
        >
          close
        </span>
      </div>
      {(imageUrl || product?.name) && (
        <>
          {imageUrl && <img alt="appLogo" src={imageUrl} className={styles.productImage} />}
          <p className="mb-20">{product?.name}</p>
        </>
      )}
      <ProductsSelection
        companyId={companyId}
        className="mt-15 w-100"
        btnLabel="button.selectProduct"
        titleLabel="label.selectProduct"
        onSelectProducts={onSelectProducts}
        selectedProducts={selectedProducts}
      />
    </div>
  );
};

export default SelectProductCard;
