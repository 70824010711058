import { FormattedMessage } from 'react-intl';
import { cloneDeep, isEqual } from 'lodash';

import { notificationToast } from 'Utils/notificationToaster';

import { SENT_ITEMS } from 'Services/response/CommonService';
import { getRefs, saveResponse } from 'Services/response/OutboundResponseService';
import { postEvent, postRequest } from 'Services/Api';
import { firebaseGetCurrentUser, firebaseGetSentRef } from 'Services/FirebaseService';
import { getOutboundPriorityForResponse, updateAllSortKeys } from 'Utils/keyUtils';

import { uploadBountyResponseAttachments } from 'Services/UploadService';
import { BountyResponseState, IdentityType, QueueAlias, ResponseType } from 'Constants/enums';
import { getAuthorForCreation, getChangedObj } from 'Utils/bountyCreation';
import { getMyUser } from 'Services/BaseService';
import { formatReward } from 'Utils/currency';
import { getIdentityType } from 'Utils/identityMode';
import { isAllowedToDelete, isAllowedToDemote, isAllowedToRefuse, isAllowedToRetract } from 'Utils/bountyResponse';
import { BULLET_SEP } from 'Utils/survey';
import { asBountyInfo, getResponseType, getSurveyChoices } from 'Utils/bounty';
import { isAllowedToPublishResponse, isDraftState } from 'Utils/bountyState';

function getPriorityReference(type, ref) {
  if (type === SENT_ITEMS) {
    return ref.parent;
  }

  return ref;
}

function updateResponse(bountyResponse, updates) {
  const refs = getRefs(bountyResponse);
  Object.values(refs).forEach((ref) => (ref.update(updates)));
}

function updateOutboundPriority(bountyResponse) {
  const refs = getRefs(bountyResponse);

  Object.keys(refs).forEach((key) => {
    const ref = getPriorityReference(key, refs[key]);
    ref.setPriority(getOutboundPriorityForResponse(bountyResponse));
  });
}

function getOutboundResponse(bounty, bountyResponse) {
  if (!bounty) {
    // eslint-disable-next-line no-console
    console.log('Missing bounty');
    return;
  }

  if (bountyResponse && bountyResponse.identityMode && bountyResponse.identityMode.identityType === IdentityType.Real) {
    return {
      ...bountyResponse,
      author: null,
    };
  }

  return bountyResponse;
}

async function newResponseInstance(bountyInfo) {
  const now = new Date().getTime();
  const creator = await getMyUser();

  const response = {
    creator,
    bountyInfo,
    type: getResponseType(bountyInfo),
    state: BountyResponseState.Draft,
    createdAt: now,
    updatedAt: now,
  };

  updateAllSortKeys(response);
  return response;
}

export async function getNewResponse(bounty) {
  const bountyClone = cloneDeep(bounty);
  const mySelf = firebaseGetCurrentUser();
  const ref = firebaseGetSentRef(mySelf.uid).push();
  const bountyResponseId = ref.getKey();

  if (!bountyClone.outboundResponses) {
    bountyClone.outboundResponses = {};
  }

  const response = await newResponseInstance(asBountyInfo(bountyClone));

  response.id = bountyResponseId;

  bountyClone.outboundResponses[response.id] = response;

  return response;
}

function updateResponseState(bounty, response, newState) {
  const oldState = response.state;

  const rr = getOutboundResponse(bounty, response);
  const update = { state: newState };

  updateResponse(rr, update);

  if (isDraftState(newState) !== isDraftState(oldState)) {
    updateOutboundPriority(rr);
  }
}

function publishResponse(bounty, response) {
  const isAllowedToPerformOp = isAllowedToPublishResponse(response.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(<FormattedMessage id="warnings.responses.cannotPublishResponse" />);
  }

  updateResponseState(bounty, response, BountyResponseState.Active);

  postEvent(QueueAlias.PublishResponse, {
    bountyId: bounty.id,
    responseId: response.id,
    identityType: getIdentityType(response.identityMode),
    type: response?.type,
    response,
  });
}

export const createResponse = (bounty, response, userData, attachments) => async () => {
  const responseClone = cloneDeep(response);

  if (attachments && attachments.length) {
    responseClone.attachments = await uploadBountyResponseAttachments(responseClone, attachments, userData);
  }

  return new Promise((resolve) => {
    saveResponse(bounty, responseClone);
    publishResponse(bounty, responseClone);
    resolve();
  });
};

export const onPublishResponse = ({ bounty, response }) => () => (publishResponse(bounty, response));

export const reportResponse = ({ response, reason }) => {
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;

  if (isMine) {
    return null;
  }

  postEvent(QueueAlias.ReportResponse, { responseId: response.id, message: reason });
};

function getResponseUpdates(origResponse, newResponse) {
  const updates = {};
  const changes = {};

  if (!isEqual(origResponse.note, newResponse.note)) {
    updates.note = newResponse.note;
    changes.note = getChangedObj(origResponse.note, newResponse.note);
  }

  const oldApplicant = origResponse.recommendation ? origResponse.recommendation.applicant : null;
  const newApplicant = newResponse.recommendation ? newResponse.recommendation.applicant : null;

  if (!isEqual(oldApplicant, newApplicant)) {
    updates.applicant = newApplicant;
    changes.applicant = getChangedObj(oldApplicant, newApplicant);
  }

  if (!isEqual(origResponse.attachments, newResponse.attachments)) {
    updates.attachments = newResponse.attachments;
    changes.attachments = getChangedObj(origResponse.attachments, newResponse.attachments);
  }

  if (origResponse.identityMode && newResponse.identityMode
    && (origResponse.identityMode.forced !== newResponse.identityMode.forced
      || origResponse.identityMode.identityType !== newResponse.identityMode.identityType)) {
    updates.identityMode = newResponse.identityMode;
    changes.identityMode = getChangedObj(origResponse.identityMode, newResponse.identityMode);
  }

  if (origResponse.visibilityMode !== newResponse.visibilityMode) {
    updates.visibilityMode = newResponse.visibilityMode;
    changes.visibilityMode = getChangedObj(origResponse.visibilityMode, newResponse.visibilityMode);
  }

  return { updates, changes };
}

export const updateBountyResponse = ({
  bounty, oldResponse, newResponse, attachments, userData,
}) => async () => {
  const me = firebaseGetCurrentUser();
  const isMine = newResponse.creator && newResponse.creator.id === me.uid;
  const editedAt = new Date().getTime();

  if (!isMine) {
    return notificationToast.warning(<FormattedMessage id="warnings.responses.notAllowedToEditResponse" />);
  }

  if (attachments && attachments.length) {
    newResponse.attachments = await uploadBountyResponseAttachments(newResponse, attachments, userData);
  } else {
    newResponse.attachments = null;
  }

  const { updates, changes } = getResponseUpdates(oldResponse, newResponse);

  if (!Object.keys(updates).length) {
    notificationToast.info(
      <FormattedMessage id="warnings.responses.nothingChangedInResponse" values={{ responseId: newResponse.id }} />,
    );
    return Promise.resolve();
  }

  updates.editedAt = editedAt;
  changes.editedAt = editedAt;

  const rr = getOutboundResponse(bounty, newResponse);
  updateResponse(rr, updates);

  postEvent(QueueAlias.UpdateResponse, { bountyId: bounty.id, responseId: newResponse.id, changes });
};

export const rejectResponse = ({ response, reason, onSuccess, onInProgressStatusChange }) => {
  onInProgressStatusChange?.(true);

  postRequest(QueueAlias.RejectResponse, { responseId: response.id, reason })
    .then(() => onSuccess?.())
    .finally(() => onInProgressStatusChange?.(false));
};

export const retractResponse = ({ bounty, response }) => {
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToRetract(response.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(
      <FormattedMessage id="warnings.responses.cannotAcceptResponse" values={{ response: response.state }} />,
    );
  }

  if (!isMine) {
    return notificationToast.warning(<FormattedMessage id="warnings.responses.dontHavePermissionToRetractResponse" />);
  }

  updateResponseState(bounty, response, BountyResponseState.Retracted);
  postEvent(QueueAlias.RetractResponse, { bountyId: bounty.id, responseId: response.id });
};

export const ackResponse = ({ bounty, response }) => {
  if (response.state !== BountyResponseState.Active) {
    return notificationToast.warning(
      <FormattedMessage id="warnings.responses.cannotAcknowledgeResponse" values={{ response: response.state }} />,
    );
  }

  updateResponseState(bounty, response, BountyResponseState.Acked);
  postEvent(QueueAlias.ACKResponse, { responseId: response.id });
};

export const acceptResponse = (bountyResponseId) => postRequest(QueueAlias.AcceptResponse, { responseId: bountyResponseId });

export const refuseResponse = ({ bounty, response, reason }) => {
  const isAllowedToPerformOp = isAllowedToRefuse(response.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(
      <FormattedMessage id="warnings.responses.cannotRefuseResponse" values={{ response: response.state }} />,
    );
  }

  const reasonTxt = reason || null;

  updateResponseState(bounty, response, BountyResponseState.Refused);
  postEvent(QueueAlias.RefuseResponse, { responseId: response.id, reason: reasonTxt });

  return notificationToast.warning(<FormattedMessage id="warnings.responses.responseWasRefused" />);
};

const removeResponse = (bounty, bountyResponse) => {
  if (!bountyResponse) {
    return;
  }

  if (bounty.outboundResponses) {
    delete bounty.outboundResponses[bountyResponse.id];
  }

  const refs = getRefs(bountyResponse);
  Object.values(refs).forEach((ref) => (ref.remove()));
};

export const deleteResponse = ({ bounty, response, ignoreState }) => {
  const isAllowedToPerformOp = isAllowedToDelete(response.state);
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;

  if (!ignoreState && !isAllowedToPerformOp) {
    return notificationToast.warning(
      <FormattedMessage id="warnings.responses.cannotDeleteResponse" values={{ response: response.state }} />,
    );
  }

  if (!isMine) {
    return notificationToast.warning(<FormattedMessage id="warnings.responses.dontHavePermissionToDeleteResponse" />);
  }

  const rr = getOutboundResponse(bounty, response);
  removeResponse(bounty, rr);

  postEvent(QueueAlias.DeleteResponse, { bountyId: bounty.id, responseId: response.id });
};

export const demoteResponse = ({ bounty, response }) => {
  const isAllowedToPerformOp = isAllowedToDemote(response.state);

  if (!isAllowedToPerformOp) {
    return notificationToast.warning(
      <FormattedMessage id="warnings.responses.cannotDemoteResponse" values={{ response: response.state }} />,
    );
  }

  postEvent(QueueAlias.DemoteResponse, { bountyId: bounty.id, responseId: response.id });
};

export const sendSurveyResponse = async ({ bounty, option }) => {
  const { outboundResponses } = bounty;
  const wasChosen = getSurveyChoices(outboundResponses).includes(option.code);

  if (wasChosen) {
    if (outboundResponses) {
      Object.values(outboundResponses).forEach((outboundRes) => {
        if (outboundRes.type === ResponseType.Choice && outboundRes.choice === option.code) {
          deleteResponse({ bounty, response: outboundRes, ignoreState: true });
        }
      });
    }

    return null;
  }

  const bountyResponse = await getNewResponse(bounty);
  bountyResponse.type = ResponseType.Choice;
  bountyResponse.note = `${option.code}${BULLET_SEP} ${formatReward(option.reward, option.text)}`;
  bountyResponse.choice = option.code;

  saveResponse(bounty, bountyResponse);
  publishResponse(bounty, bountyResponse);

  return bountyResponse.id;
};

export function removeResponseAttachment(response, attachment) {
  postEvent(QueueAlias.DeleteAttachment, { responseId: response.id, attachmentId: attachment.id });
}
