import { AnyAction } from '@reduxjs/toolkit';

import { ACTIONS } from 'Store/client/actions';
import { Client, ClientInfo } from 'Types/client.interface';
import { ShopSettings } from 'Types/shopSettings.interface';
import { ClientLocation } from 'Types/clientLocation.interface';
import { CustomChemical, ProductChemicals } from "Types/chemicals.interface";

interface State {
  clientId: string;
  isLoading: boolean;
  isSubmitting: boolean;
  locations: ClientLocation[];
  usStates: Record<string, string>;
  client: Client | null;
  parentClient: ClientInfo | null;
  shopSettings: ShopSettings | null;
  parentSettings: ShopSettings | null;
  isMerchantSettingsSelected: boolean;
  activeSettingsPage: number;
  customProductChemicals: CustomChemical[];
  productChemicals: ProductChemicals;
}

const INIT_STATE: State = {
  clientId: '',
  locations: [],
  usStates: {},
  isLoading: false,
  isSubmitting: false,
  client: null,
  parentClient: null,
  shopSettings: null,
  parentSettings: null,
  isMerchantSettingsSelected: false,
  activeSettingsPage: 1,
  customProductChemicals: [],
  productChemicals: {
    enableFilters: false,
    infosByCategory: {},
  }
};

const clientReducer = (state = INIT_STATE, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.SET_CLIENT_ID:
      return {
        ...state,
        clientId: payload,
      };

    case ACTIONS.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: payload,
      };

    case ACTIONS.SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      };

    case ACTIONS.SET_US_STATES:
      return {
        ...state,
        usStates: payload,
      };

    case ACTIONS.SET_CLIENT:
      return {
        ...state,
        client: payload,
      };

    case ACTIONS.SET_PARENT_CLIENT:
      return {
        ...state,
        parentClient: payload,
      };

    case ACTIONS.SET_SHOP_SETTINGS:
      return {
        ...state,
        shopSettings: payload,
      };

    case ACTIONS.SET_PARENT_SETTINGS:
      return {
        ...state,
        parentSettings: payload,
      };

    case ACTIONS.RESET_LOCATIONS:
      return {
        ...state,
        locations: [],
      };

    case ACTIONS.SET_IS_MERCHANT_SETTINGS_SELECTED:
      return {
        ...state,
        isMerchantSettingsSelected: payload,
      };

    case ACTIONS.RESET_US_STATES:
      return {
        ...state,
        usStates: {},
      };

    case ACTIONS.SET_ACTIVE_SETTINGS_PAGE:
      return {
        ...state,
        activeSettingsPage: payload,
      };

    case ACTIONS.SET_CUSTOM_PRODUCT_CHEMICALS:
      return {
        ...state,
        customProductChemicals: payload,
      };

    case ACTIONS.SET_PRODUCT_CHEMICALS:
      return {
        ...state,
        productChemicals: payload,
      };

    case ACTIONS.RESET_REDUCER:
      return {
        ...INIT_STATE,
        clientId: state?.clientId,
        activeSettingsPage: state?.activeSettingsPage,
        locations: state?.locations,
      };

    case ACTIONS.HARD_RESET_REDUCER:
      return INIT_STATE;

    default:
      return state;
  }
};

export default clientReducer;
