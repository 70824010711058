import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomTextarea from 'Components/CustomTextarea';
import SwitchStyled from 'Components/SwitchStyled';
import { addBountyComments } from 'Services/bounty/StatsService';
import { addResponseComments, getReplyCommentStats, increaseResponseComments } from 'Services/response/StatsService';
import { getMyUser } from 'Services/BaseService';
import { userDataSelector, settingsSelector } from 'Store/settings/selectors';
import { identityMode } from 'Utils/identityMode';
import { commentSchema } from 'Utils/validation/comment';
import { CommentType, IdentityType } from 'Constants/enums';
import { getIdentityMode } from 'Utils/opManager';
import { getErrorsByName } from 'Utils/formik';
import { configurationSelector, selectedBountySelector } from 'Store/createBounty/selectors';
import { closeDrawer } from 'Store/createBounty/actions';
import { IdentityMode } from 'types/identityMode.interface';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const AddComment = () => {
  const [commentCount, setCommentCount] = useState<number>(0);
  const [allowedIdentityMode, setAllowedIdentityMode] = useState<IdentityMode|null>(null);

  const userData = useSelector(userDataSelector)?.data || {};
  const settings = useSelector(settingsSelector) || {};
  const bounty = useSelector(selectedBountySelector) || {};
  const { commentType } = useSelector(configurationSelector) || {};

  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();

  const initAnonymity = () => {
    const mode = getIdentityMode(settings, userData);
    setAllowedIdentityMode(mode);
    const isAnonymous = mode.identityType !== IdentityType.Real;
    formik.setFieldValue('postAnonymously', isAnonymous);
  };

  const initCommentCount = () => {
    if (bounty?.bountyInfo) {
      return (
        getReplyCommentStats({
          ownerId: bounty.owner && bounty.owner.id,
          bountyId: bounty.bountyInfo.id,
          responseId: bounty.id,
        })
          .then((response) => setCommentCount(response))
      );
    }
  };

  useEffect(() => {
    initAnonymity();
    initCommentCount();
  }, []);

  const addComment = async ({ commentText, postAnonymously }: { commentText: string; postAnonymously: boolean }) => {
    const creator = await getMyUser();
    const identityModeValue = postAnonymously ? identityMode.ANON : identityMode.REAL;

    const comment = {
      creator,
      sentAt: new Date().getTime(),
      text: commentText,
      identityMode: identityModeValue,
      commentType,
      entityId: bounty.id,
    };

    if (comment.commentType === CommentType.Bounty) {
      dispatch(addBountyComments(bounty.id, comment));
    }

    if (comment.commentType === CommentType.Response) {
      dispatch(addResponseComments(bounty.id, comment));
      dispatch(increaseResponseComments({
        ownerId: bounty && bounty.owner ? bounty.owner.id : null,
        bountyId: bounty?.bountyInfo?.id,
        responseId: bounty?.id,
        commentCount,
      }));
    }

    handleDismiss();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      commentText: '',
      postAnonymously: false,
    },
    onSubmit: addComment,
    validationSchema: commentSchema,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldValue(name, value);
  };

  const handleSwitchAnonymity = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('postAnonymously', checked);
  };

  const handleDismiss = () => {
    dispatch(closeDrawer());
    formik.resetForm();
  };

  return (
    <Box mt={2} className={styles.root}>
      <h3>
        {intl.formatMessage({ id: 'label.add_comment' })}
      </h3>
      <form onSubmit={formik.handleSubmit} noValidate className={styles.form}>
        <CustomTextarea
          name="commentText"
          value={formik?.values?.commentText}
          placeholder={intl.formatMessage({ id: 'placeholder.sayHelpful' })}
          onChange={handleChanges}
          validationErrors={getErrorsByName(formik, 'commentText')}
        />

        {!allowedIdentityMode?.forced && (
          <FormControlLabel
            label={intl.formatMessage({ id: 'bounty.postAnonymously' })}
            control={(
              <SwitchStyled
                checked={!!formik?.values?.postAnonymously}
                onChange={handleSwitchAnonymity}
              />
            )}
          />
        )}
        <CustomModalButtons
          onDismiss={handleDismiss}
          onSubmit={formik.handleSubmit}
          submitName="button.send"
        />
      </form>
    </Box>
  );
};

export default AddComment;
