import { useEffect, useState, FC, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import CustomInput from 'Components/CustomInput';

import { closeModal } from 'Store/modal/actions';
import { updateCompanyProfile } from 'Store/userProfile/actions';
import { getUserSelector } from 'Store/user/selectors';
import { UserService } from 'Services/UserService';

import { ThunkDispatchType } from 'Types/redux.types';
import { getUser } from 'Store/user/actions';
import { getUserAgent } from 'Utils/api';
import { notificationToast } from 'Utils/notificationToaster';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import CustomModalButtons from 'Components/CustomModalButtons';

interface EditDescriptionProps {
  isCompany?: boolean;
}

const EditDescription:FC<EditDescriptionProps> = ({ isCompany }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState('');
  const userProfile = useSelector(getUserSelector) || {};
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  useEffect(() => {
    if (userProfile?.description) {
      setDescription(userProfile.description);
    }
  }, []);

  const handleInputChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setDescription(value);

  const editDescription = async () => {
    setIsSubmitting(true);

    try {
      if (isCompany) {
        await updateCompanyProfile({ description, companyId: userProfile.id });
      } else {
        await UserService.updateUserRequest(userProfile?.id, {
          meta: {
            action: 'update_user',
            userId: userProfile?.id,
            agent: getUserAgent(),
            restEvent: true,
          },
          description,
        });
      }
      await dispatch(getUser(userProfile.id));

      dispatch(closeModal());
    } catch (error: any) {
      notificationToast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => dispatch(closeModal());

  return (
    <form onSubmit={editDescription}>
      <label className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: isCompany ? 'modal_edit_descr_company_label' : 'modal_edit_descr_user_label' })}
      </label>
      <CustomInput
        name="description"
        autoFocus
        value={description}
        onChange={handleInputChanges}
      />
      <input type="submit" hidden />
      <CustomModalButtons
        onDismiss={handleClose}
        onSubmit={editDescription}
        isLoading={isSubmitting}
      />
    </form>
  );
};

export default EditDescription;
