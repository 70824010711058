import { FC, useEffect, useState } from "react";
import { MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { cloneDeep, isEmpty, set } from "lodash";
import { useIntl } from "react-intl";

import StyledSelect from "Components/StyledSelect";

import { Attachment } from "Types/attachment.interface";
import { Category } from "Types/category.interface";
import { getOpenCategories } from "Services/CategoryService";
import { notificationToast } from "Utils/notificationToaster";
import { BountyType } from "Constants/enums";
import { formatCategoriesForAttachment, getSelectedCategories } from "Utils/categories";
import * as labelStyles from "Assets/scss/modules/label.module.scss";

interface CategorySelectionProps {
  attachment: Attachment;
  onChange: (attachment: Partial<Attachment>) => void;
  companyId: string;
  onLoading: (isLoading: boolean) => void;
}

const CategorySelection: FC<CategorySelectionProps> = ({
  attachment,
  onChange,
  companyId,
  onLoading,
}) => {
  const intl = useIntl();
  const [categories, setCategories] = useState<Category>({} as Category);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (companyId) {
      fetchProductCategories();
    }
  }, [companyId]);

  useEffect(() => {
    setSelectedCategories([]);
  }, [companyId]);

  useEffect(() => {
    if (!isEmpty(categories) && !selectedCategories?.length) {
      setSelectedCategories(getSelectedCategories(attachment, categories));
    }
  }, [attachment, categories]);

  const fetchProductCategories = async () => {
    onLoading(true);
    try {
      const response = await getOpenCategories(BountyType.Product, companyId);
      setCategories(response);
    } catch (e: any) {
      notificationToast.error(e.message);
    } finally {
      onLoading(false);
    }
  };

  const handleSelectCategory = ({ target: { value } }: SelectChangeEvent<unknown>, index: number) => {
    const isAllOption = value === '';
    const category = isAllOption ? value : JSON.parse(value as string);
    let newCategories = [...selectedCategories];

    if (index + 1 !== selectedCategories?.length) {
      newCategories = newCategories.slice(0, index + 1);
    }

    if (!isAllOption) {
      newCategories.push(category);
    }

    setSelectedCategories(newCategories);

    const newAttachment = set(cloneDeep(attachment), 'action', {
      ...attachment?.action,
      categories: formatCategoriesForAttachment(newCategories),
      tags: '',
    });
    onChange(newAttachment);
  };

  return selectedCategories?.map((category, index) => {
    const subCategories = category?.children;

    return subCategories && subCategories?.length > 0 ? (
      <div className="mt-10" key={category?.code}>
        <Typography className={labelStyles.fieldLabel}>
          {intl.formatMessage({ id: index === 0 ? 'label.selectCategory' : 'label.selectSubCategory' })}
        </Typography>
        <StyledSelect
          name="banner.category"
          value={selectedCategories?.[index + 1] ? JSON.stringify(selectedCategories?.[index + 1]) : ''}
          onChange={(e) => handleSelectCategory(e, index)}
          displayEmpty
        >
          <MenuItem value="">
            {intl.formatMessage({ id: 'label.all' })}
          </MenuItem>
          {subCategories?.map((subCategory) => (
            <MenuItem key={subCategory?.code} value={JSON.stringify(subCategory)}>
              {subCategory?.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
    ) : null;
  });
};

export default CategorySelection;