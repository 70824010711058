import { useContext, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  allowedOrganizationTypesSelector,
  canSeeSidebarMoreMenu,
  routePermissionsSelector,
} from 'Store/permissions/selectors';
import { isLoggedUserSystemAdminSelector, isSidebarOpenSelector } from 'Store/settings/selectors';
import { sidebarNavLinks, TALK_TO_SUPPORT } from 'Constants/sidebarItems';
import { getApplicationConfig } from 'Utils/appConfig';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import NavMenuItem from 'Components/SideBar/NavMenuItem';
import { getCurrentLocale } from 'Utils/languagesHelpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageContext } from 'Contexts/languagesContext';
import { HOME_ROUTE } from 'Constants/routes';
import useInsertLangCodeToLinks from 'Hooks/useInsertLangCodeToLinks';
import { ApplicationConfig } from 'Types/applicationConfig.type';
import { IMenu } from 'Types/menu.interface';
import SimpleButton from 'Components/SimpleButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setIsSidebarOpen } from "Store/settings/actions";
import { ThunkDispatchType } from "Types/redux.types";
import { BREAKPOINTS } from "Constants/common";
import packageJson from '../../../package.json';
import * as styles from './index.module.scss';

const AppConfig: ApplicationConfig = getApplicationConfig();

const lsMenuName = 'isCompactMenu';

const SideBar = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const routePermissions = useSelector(routePermissionsSelector) || {};
  const orgTypes = useSelector(allowedOrganizationTypesSelector) || {};
  const isSystemAdminUser = useSelector(isLoggedUserSystemAdminSelector);
  const isMobile = useMediaQuery(`@media (max-width:${BREAKPOINTS.sm}px)`);
  const isSidebarOpen = useSelector(isSidebarOpenSelector) || false;
  const dispatch = useDispatch<ThunkDispatchType>();

  const navLinks = useMemo(() => (
    sidebarNavLinks({ routePermissions, orgTypes })
  ), [routePermissions, orgTypes]);
  const showMoreMenu = navLinks?.category3?.length > 0 && isSystemAdminUser && canSeeSidebarMoreMenu;

  const { locale } = useContext(LanguageContext);

  const [open, setOpen] = useState(isMobile
    ? false
    : localStorage.getItem(lsMenuName) !== 'true');
  const [isMenuExpended, setIsMenuExpanded] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');

  const { termsUrl, privacyUrl } = useInsertLangCodeToLinks({
    termsUrl: AppConfig.TERMS_OF_SERVICE_URL || '',
    privacyUrl: AppConfig.PRIVACY_STATEMENT_URL || '',
  }, getCurrentLocale(locale));

  const toggleSideBar = () => {
    if (isMobile) {
      dispatch(setIsSidebarOpen(!isSidebarOpen));
    }

    setOpen(!open);
  };

  useEffect(() => {
    if (open) {
      localStorage.removeItem(lsMenuName);
    } else {
      localStorage.setItem(lsMenuName, 'true');
    }
  }, [open]);

  useEffect(() => {
    if (isMobile && isSidebarOpen && !open) {
      setOpen(true);
    }
  }, [isSidebarOpen, isMobile]);

  const handleSelectActiveMenu = (menu: string, hasChildren = false) => {
    setActiveMenu(menu);

    if (!hasChildren) {
      dispatch(setIsSidebarOpen(false));
    }
  };

  return (
    <div className={classnames(
      styles.sidebar,
      {
        [`${styles.open} open`]: open,
        [styles.transition]: !isMobile,
        [styles.isMenuExpended]: isMenuExpended,
      },
    )}
    >
      <div className={styles.sidebarInner}>
        <div
          className={classnames(
            styles.sidebarHeader,
            AppConfig?.customCss?.sidebarLogo && styles[AppConfig?.customCss?.sidebarLogo],
          )}
        >
          <IconButton
            className={styles.burgerIcon}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSideBar}
            size="medium"
          >
            <span className="material-symbols-rounded icon_menu"/>
          </IconButton>

          <div className={styles.siteLogo}>
            <Link to={HOME_ROUTE}>
              <img
                alt="site logo"
                src={AppConfig?.images?.appLogoText}
                className={classnames('image-logo', styles.imageLogo)}
              />
            </Link>
          </div>
        </div>

        {navLinks && navLinks.category2.map((menu: IMenu, key: number) => (
          <NavMenuItem
            menu={menu}
            key={key}
            open={open}
            activeMenu={activeMenu}
            setActiveMenu={handleSelectActiveMenu}
            setIsMenuExpanded={setIsMenuExpanded}
          />
        ))}

        {showMoreMenu && (
          <Accordion className={styles.accordion}>
            <AccordionSummary>
              <NavMenuItem
                menu={{ path: '', menu_icon: 'icon-more', menu_title: 'sidebar.more' }}
                open={open}
                setActiveMenu={() => handleSelectActiveMenu('sidebar.more', true)}
              />
            </AccordionSummary>
            <AccordionDetails>
              {navLinks && navLinks.category3.map((menu, key) => (
                <NavMenuItem
                  menu={menu}
                  key={key}
                  open={open}
                  setActiveMenu={handleSelectActiveMenu}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {(!isSystemAdminUser || canSeeSidebarMoreMenu) && navLinks && navLinks.category3.map((menu, key) => (
          <NavMenuItem
            menu={menu}
            key={key}
            open={open}
            setActiveMenu={handleSelectActiveMenu}
          />
        ))}
        <NavMenuItem
          menu={{
            path: '',
            menu_icon: 'expand_circle_down',
            menu_title: 'sidebar.more',
            child_routes: [
              ...(routePermissions.canTalkToSupport ? [TALK_TO_SUPPORT] : []),
              ...(termsUrl ? [{
                path: termsUrl,
                menu_icon: 'article',
                menu_title: 'widgets.terms_of_service',
                external: true,
              }] : []),
              ...(privacyUrl ? [{
                path: privacyUrl,
                menu_icon: 'policy',
                menu_title: 'widgets.privacy',
                external: true,
              }] : []),
            ],
            has_arrow: false,
          }}
          open={open}
          activeMenu={activeMenu}
          setActiveMenu={handleSelectActiveMenu}
          setIsMenuExpanded={setIsMenuExpanded}
        />

        {navLinks && navLinks?.create?.map((menu, key) => (
          <div className={styles.createWrapper} key={key}>
            <SimpleButton
              onClick={() => navigate(menu.path)}
              className={classnames('w-100', styles.createButton)}
            >
              <img alt="create-logo" src={AppConfig?.images?.appLogoTextWhite} />
              <span>
                {open && intl.formatMessage({ id: 'button.create' })}
              </span>
            </SimpleButton>
          </div>
        ))}
      </div>

      {open && packageJson.version && (
        <div className={styles.buildVersion} title={packageJson.version}>
          <FormattedMessage id="build_version" values={{ value: packageJson.version }} />
        </div>
      )}
    </div>
  );
};

export default SideBar;
