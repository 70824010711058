import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import GroupCreateInformation from 'Components/GroupCreateInformation';
import GroupCreateMembers from 'Components/GroupCreateMembers';
import CustomLoader from 'Components/CustomLoader';

import { closeModal } from 'Store/modal/actions';
import { clearMyColleagues, createGroup, editGroup, getMyColleagues } from 'Store/groups/actions';
import { companyGroupsSelector, ownerSelector } from 'Store/settings/selectors';
import { isColleaguesLoadingSelector, colleaguesSelector } from 'Store/groups/selectors';

import { GroupType, JoiningStrategy } from 'Constants/enums';
import { createGroupSchema } from 'Utils/validation/group';
import { notificationToast } from 'Utils/notificationToaster';
import { ExtendedGroupMember, Group } from 'Types/group.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

interface CreateForm {
  groupId: string;
  name: string;
  description: string;
  joiningStrategy: JoiningStrategy;
  membersIds: Record<string, boolean>,
}

interface GroupCreateProps {
  group: Group | ExtendedGroupMember;
}

const GroupCreate: FC<GroupCreateProps> = ({ group }) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const userGroups = useSelector(companyGroupsSelector);
  const userDataOwner = useSelector(ownerSelector) || {};
  const isMembersLoading = useSelector(isColleaguesLoadingSelector);
  const members = useSelector(colleaguesSelector);

  const isEditMode = group && Object.keys(group).length > 0;
  const buttonName = isEditMode
    ? 'button.edit'
    : step === 1 ? 'label.continue' : 'button.create';

  useEffect(() => () => { dispatch(clearMyColleagues()); }, []);

  const handleCloseModal = () => dispatch(closeModal());

  const handleCreateGroup = (values: CreateForm) => {
    createGroup({
      ...values,
      parentGroup: userGroups,
      type: GroupType.Generic,
    });

    handleCloseModal();
  };

  const handleEditGroup = (values: CreateForm) => {
    editGroup(values)
      .then(handleCloseModal)
      .catch(() => notificationToast.error(intl.formatMessage({ id: 'errors.somethingWentWrong' })));
  };

  const handleSubmit = (values: CreateForm) => {
    if (isEditMode) {
      return handleEditGroup(values);
    }

    if (step === 1) {
      setStep(2);
      dispatch(getMyColleagues({ ownerId: userDataOwner?.id }));
    } else {
      handleCreateGroup(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: group?.user?.id || '',
      name: group?.user?.name || '',
      description: group?.description || '',
      joiningStrategy: group?.joiningStrategy || JoiningStrategy.InviteOnly,
      membersIds: {},
    },
    validationSchema: createGroupSchema,
    onSubmit: handleSubmit,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, value);
  };

  const handleChangeMember = (memberId: string, { target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('membersIds', {
      ...formik?.values?.membersIds,
      [memberId]: checked,
    });
  };

  return (
    <CustomLoader isLoading={isMembersLoading}>
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        {step === 1 && (
          <GroupCreateInformation
            onChange={handleChanges}
            formik={formik}
          />
        )}
        {step === 2 && (
          <GroupCreateMembers
            onChange={handleChangeMember}
            values={formik?.values?.membersIds}
            members={members}
          />
        )}
        <input type="submit" hidden />
      </form>
      <div className={styles.navigation}>
        <CustomModalButtons
          submitName={buttonName}
          onDismiss={handleCloseModal}
          onSubmit={formik.handleSubmit}
        />
      </div>
    </CustomLoader>
  );
};

export default GroupCreate;
