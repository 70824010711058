import { ConfigCommon } from '../../../src/types/config.interface';

const config: ConfigCommon = {
  id: 'alleaves',
  title: 'Alleaves',
  name: 'Alleaves Shop Console',
  brandName: 'Alleaves Shop Console',
  appLinkSchema: 'alleaves',
  appTld: 'alleaves.shop',
  useCase: 'shop',
  tags: ['all', 'active'],
  supportEmail: 'support@alleaves.com',
  auth: {
    disableSignup: true,
    disablePinFlow: true,
    disabledPins: ['1515'],
    customCss: 'background-shade',
  },
  custom: {
    shopUrl: 'https://shop.alleaves.shop',
    shopBaseUrl: 'https://{slug}.alleaves.shop', // TODO: remove shopUrl when we have the production url finalized
    shopTestUrl: 'https://alleaves-shop--test-u4p0tu7z.web.app',
    supportedLanguages: ['en-US', 'es-ES'],
    supportedCountryCodes: ['US'],
  },
  TERMS_OF_SERVICE_URL: '',
  PRIVACY_STATEMENT_URL: 'https://www.alleaves.com/privacy-policy',
  customPages: {
    home: 'AsyncClientHomePage',
    profile: 'AsyncDefaultUserProfilePage',
  },
};

export default config;
