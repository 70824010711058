import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Drawer } from "@mui/material";

import SimpleButton from "Components/SimpleButton";
import Search from "Components/Search";
import ProductsList from "Containers/BannerCreation/ProductsList";
import Pagination from "Components/Pagination";
import LoaderOverlay from "Components/LoaderOverlay";
import CustomModalButtons from "Components/CustomModalButtons";

import { getPublicProducts } from "Services/ProductService";
import useDebounce from "Hooks/useDebounce";

import { Bounty } from "Types/bounty.interface";
import { notificationToast } from "Utils/notificationToaster";
import { paginationItemsPerPage } from "Constants/common";
import * as styles from "./index.module.scss";

interface ProductsSelectionProps {
  companyId: string;
  className?: string;
  btnLabel?: string;
  onSelectProducts: (bounty: Bounty[]) => void;
  selectedProducts: Bounty[];
  titleLabel?: string;
  hasMultiSelect?: boolean;
}

const ProductsSelection: FC<ProductsSelectionProps> = ({
  companyId,
  className = '',
  btnLabel = 'label.selectProducts',
  titleLabel = 'label.selectProducts',
  onSelectProducts,
  selectedProducts,
  hasMultiSelect = false,
}) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [products, setProducts] = useState<Bounty[]>([]);
  const [currentSelection, setCurrentSelection] = useState<Bounty[]>([]);
  const debouncedSearch = useDebounce([search], 700);

  useEffect(() => {
    fetchProducts();
  }, [page, debouncedSearch, companyId]);

  const fetchProducts = async () => {
    setIsLoading(true);

    try {
      const queryParams = {
        start: page,
        length: paginationItemsPerPage,
        companyId,
        ...(search ? { 'q': search } : {}),
      };
      const response = await getPublicProducts(queryParams);

      setProducts(response?.list || []);
      setTotalCount(response?.totalCount || 0);
    } catch (error: any) {
      notificationToast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = () => {
    setCurrentSelection(selectedProducts);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleSave = () => {
    onSelectProducts(currentSelection);
    setIsOpen(false);
  };

  const handleSelect = (bounty: Bounty) => {
    if (!hasMultiSelect) {
      setCurrentSelection([bounty]);
      return;
    }

    let newSelectedProducts: Bounty[];

    if (currentSelection?.find(({ id }) => id === bounty?.id)) {
      newSelectedProducts = currentSelection?.filter(({ id }) => id !== bounty?.id);
    } else {
      newSelectedProducts = [...currentSelection, bounty];
    }

    setCurrentSelection(newSelectedProducts);
  };

  return (
    <>
      <SimpleButton onClick={handleOpen} className={className}>
        {intl.formatMessage({ id: btnLabel })}
      </SimpleButton>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        ModalProps={{ keepMounted: true }}
        slotProps={{ paper: { className: styles.detailsDrawer } }}
      >
        <h3 className="mb-30">{intl.formatMessage({ id: titleLabel })}</h3>

        <div className={styles.content}>
          <Search value={search} onChange={setSearch}/>

          <ProductsList
            products={products}
            onSelectProduct={handleSelect}
            selectedProducts={currentSelection}
          />

          {totalCount > paginationItemsPerPage && (
            <Pagination
              disabled={isLoading}
              total={totalCount}
              offset={page}
              onChange={setPage}
            />
          )}
        </div>

        <CustomModalButtons
          dismissName="button.cancel"
          submitName="button.save"
          onDismiss={handleClose}
          onSubmit={handleSave}
          className="mt-30"
        />

        {isLoading && <LoaderOverlay />}
      </Drawer>
    </>
  );
};

export default ProductsSelection;