import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { add } from 'date-fns/add';
import moment from 'moment';
import { addInteraction } from 'Utils/interactions';
import * as Reward from 'Utils/models/Reward';
import { DEFAULT } from 'Utils/visibilityMode';
import { formatApiReward } from 'Utils/reward';
import {
  BannerType,
  BountyType,
  Currency,
  IdentityType,
  Interactions,
  PointCurrency,
  ProductType,
  PromotionFormType,
  VisibilityModeType,
} from 'Constants/enums';
import { getMyCurrency } from 'Utils/settings';
import { Bounty } from 'Types/bounty.interface';
import { UserData } from 'Types/userData.interface';
import { CreatePromotion } from 'Types/promotion.interface';
import { encryptDescription } from 'Utils/yaml';
import { getPostableListDefs, getStreamListDefs } from 'Utils/models/ListManager';
import { isSurveyType } from 'Utils/bounty';
import { setListDef } from 'Utils/models/Lists';
import { setScoreDescription, setSurveyDescription } from 'Utils/survey';
import { formatDistributionAreasList, formatSentTo } from 'Utils/distributionGroup';
import { DEFAULT_RATE_STAR_POINTS_VALUE } from 'Constants/bounty';
import { buildAgentString } from 'Utils/appConfig';
import { Money } from 'Types/money.interface';
import { Settings } from 'Types/settings.interface';
import * as OpManager from 'Utils/opManager';
import { setStartPointValue } from 'Utils/points';
import { BadgeInfo, CreateBadge } from 'Types/badge.interface';
import { User } from 'Types/user.interface';
import { cloneDeep, has, isNil, isNull, isObject, isUndefined } from "lodash";

interface FormatBountyParams {
  values: any;
  parentBounty?: Bounty;
  userData: UserData;
  settings: Settings;
}

const formatLocationFilter = (locations: string[]) => locations.reduce((acc: any, value: string) => {
  acc[value] = { pin: true };
  return acc;
}, {});

const formatCountryFilter = (countries: string[]) => ({
  US: { stateCodes: countries.join(',') },
});

export const formatExpiresAt = (values: any) => {
  if (values?.days || values?.hours || values?.minutes) {
    return formatExpiryDate(values);
  }

  return values.expiresAt || null;
};

export const formatBounty = ({ values, parentBounty, userData, settings }: FormatBountyParams) => {
  const streamLists = getStreamListDefs(settings, userData);
  const lists = getPostableListDefs(streamLists, userData, values.type);

  const bounty: Partial<Bounty> = {
    type: values.type,
    owner: userData.owner! || null,
    identityMode: getIdentityMode({ settings, userData, anonymity: values.anonymity }),
    reward: formatApiReward(values.reward, settings, userData),
    description: values.description ? { text: values.description } : null,
    title: values?.title ? values.title : null,
    postAt: values?.postAt || null,
    targetUrl: values?.targetUrl || null,
    expiresAt: formatExpiresAt(values),
    timeLimit: values?.timeLimit || null,
    onBehalfOf: values?.onBehalfOf?.user || null,
    reservationInfo: values?.reservationInfo || null,
  };

  bounty.rating = setStartPointValue(getStarPointsValue(false, values), bounty?.rating); // we dont have rating feature implemented yep on web

  if (parentBounty?.id) {
    bounty.parentBounty = parentBounty;
  }

  if (values.officialResponse) {
    bounty.interactions = addInteraction(Interactions.OfficialResponse);
  }

  if (values.visibilityType) {
    const vt = values.visibilityType ? VisibilityModeType.Private : VisibilityModeType.Visible;

    if (vt !== DEFAULT.visibilityType) {
      bounty.responseVisibilityMode = {
        forced: DEFAULT.forced,
        visibilityType: vt as VisibilityModeType,
      };
    }
  }

  if (values.streamUrl || values.streamKey) {
    bounty.broadcastInfo = {
      streamUrl: values.streamUrl || null,
      streamKey: values.streamKey || null,
    };
  }

  if (values.price) {
    bounty.reward = {
      money: getPrice({ settings, userData, price: values.price })!,
    };
  }

  if (lists?.length && !values.distributionDetails?.length) {
    const listDef = lists.length === 1 || !values.listId
      ? lists[0]
      : lists.find((item) => item.id === values.listId);

    const { listId, listCode } = setListDef(listDef);

    if (bounty.type && [BountyType.Banner, BountyType.News].includes(bounty.type)) {
      bounty.listId = values?.listId || null;
      bounty.listCode = values?.listCode !== undefined ? values.listCode : null;
    } else {
      bounty.listId = listId as string;
      bounty.listCode = listCode as string;
    }
  }

  if (bounty.expiresAt && bounty.type === BountyType.Lottery) {
    bounty.contestInfo = {
      winnerAnnouncementAt: new Date(add(bounty.expiresAt, { days: 1 })).getTime(),
    };
  }

  if (values?.distributionDetails?.length) {
    bounty.distribution = formatDistributionAreasList(values.distributionDetails, false);
    bounty.to = formatSentTo(values.distributionDetails);
  } else {
    bounty.distribution = null;
    bounty.to = null!;
  }

  if (bounty.type && isSurveyType(bounty.type)) {
    const { surveyOptions, description, type } = values;
    bounty.description = setSurveyDescription(surveyOptions, description, type);
  }

  if (bounty.type === BountyType.Score) {
    bounty.description = setScoreDescription(bounty.description);
  }

  if (bounty.type === BountyType.Banner) {
    bounty.banner = {
      bannerType: values?.banner?.bannerType || BannerType.Hero,
      targetApp: values?.banner?.targetApp || null,
      userAuthStatus: values?.banner?.userAuthStatus || null,
      priority: values?.banner?.priority ? +values?.banner?.priority : null,
      weight: values?.banner?.weight ? +values?.banner?.weight : null,
      countryFilters: values?.banner?.countryFilters?.length
        ? formatCountryFilter(values.banner.countryFilters)
        : null,
      locationFilters: values?.banner?.locationFilters?.length
        ? formatLocationFilter(values?.banner?.locationFilters)
        : null,
      bountyIds: values?.banner?.bountyIds || null,
    };
  }

  return bounty;
};

// TODO type values
export function formatBadgeCollection({ values, userData, settings }: { values: any, userData: UserData, settings: Settings }) {
  const { officialResponse, visibilityType, anonymity, reward, ...remainingValues } = values;

  const bounty = {
    ...remainingValues,
    owner: userData.owner || null,
    identityMode: getIdentityMode({ settings, userData, anonymity }),
    reward: formatApiReward(reward, settings, userData),
  };

  if (officialResponse) {
    bounty.interactions = addInteraction(Interactions.OfficialResponse);
  }

  if (visibilityType) {
    const vt = visibilityType ? VisibilityModeType.Private : VisibilityModeType.Visible;

    if (vt !== DEFAULT.visibilityType) {
      bounty.responseVisibilityMode = {
        forced: DEFAULT.forced,
        visibilityType: vt,
      };
    }
  }

  return bounty;
}

export const formatBadges = (badges: Record<string, CreateBadge>) => (
  Object
    .keys(badges)
    .reduce((acc, badgeKey) => {
      const badge = badges[badgeKey];
      const { reward, ...remainingBadgeProps } = badge;
      delete remainingBadgeProps.badgeIcon;
      delete remainingBadgeProps.badgeImage;

      (Object.keys(badge) as (keyof typeof badge)[])
        .forEach((key) => (badge[key] === undefined && delete badge[key]));

      acc[badgeKey] = remainingBadgeProps as BadgeInfo;

      if (reward?.amount) {
        acc[badgeKey].rewardStr = `${reward.amount} ${PointCurrency.Point}`;
      }

      return acc;
    }, {} as Record<string, BadgeInfo>)
);

export const formatPromotionProduct = ({ id, product }: Bounty, productType: ProductType) => {
  if (!product) {
    return null;
  }

  const commonProductInfoFields = ['merchantId', 'description', 'imageUrl'];
  const commonProps = pick(product, commonProductInfoFields);
  let sku = null;

  if (productType === ProductType.Product) {
    const variants = product?.variants ? Object.keys(product?.variants) : [];
    sku = variants[0] || null;
  }

  return {
    ...commonProps,
    productType,
    sku,
    productId: id,
  };
};

export const formatPromotion = (promotion: CreatePromotion, userData: UserData, settings: Settings) => {
  const generalInfoFields = ['type', 'couponCode', 'deliveryFee', 'price'];

  const payload: Partial<Bounty> = {
    owner: userData.owner || null,
    type: BountyType.Promotion,
    identityMode: {
      identityType: IdentityType.Real,
      forced: false,
    },
  };

  const { days = '0', hours = '0', minutes = '0', ...remainingProps } = promotion;
  const myCurrency = getMyCurrency(settings, userData);

  const generalInfo = pick(remainingProps, generalInfoFields);
  const product = remainingProps?.selectedProductData?.product
    ? formatPromotionProduct(remainingProps?.selectedProductData, ProductType.Product)
    : remainingProps?.selectedProductData;

  const metaInfo = {
    products: product ? [product] : null,
    ...generalInfo,
    ttl: (+days * 24 * 60 * 60) + (+hours * 60 * 60) + (+minutes * 60),
    price: generalInfo.price ? `${generalInfo.price} ${myCurrency}` : null,
    deliveryFee: generalInfo.deliveryFee ? `${generalInfo.deliveryFee} ${myCurrency}` : null,
    discountInfo: remainingProps.discount ? { percentageDiscount: remainingProps.discount / 100 } : null,
    maxUsageQty: remainingProps.maxUsageQty ? +remainingProps.maxUsageQty : null,
    maxOverallUsageQty: remainingProps.maxOverallUsageQty ? +remainingProps.maxOverallUsageQty : null,
  };

  payload.description = remainingProps.description
    ? { text: encryptDescription(remainingProps.description, metaInfo) }
    : null;

  if (remainingProps?.type === PromotionFormType.Discount) {
    payload.activateAt = remainingProps.activateAt || null;
    payload.expiresAt = remainingProps.expiresAt || null;
  }

  if (remainingProps?.type === PromotionFormType.SelectOne && (days || minutes || hours)) {
    payload.expiresAt = formatExpiryDate({ days, hours, minutes });
  }

  return payload;
};

//  TODO: create an interface for savedData
export function getStarPointsValue(hasRatingValue: boolean, savedData: any) {
  if (!hasRatingValue) {
    return DEFAULT_RATE_STAR_POINTS_VALUE;
  }

  try {
    return parseInt(savedData.rating, 10);
  } catch {
    return DEFAULT_RATE_STAR_POINTS_VALUE;
  }
}

export function formatExpiryDate({ days = 0, minutes = 0, hours = 0, postAt = 0 }: { days?: number; minutes?: number; hours?: number, postAt?: number }) {
  if (!days && !minutes && !hours) {
    return null;
  }

  let now = postAt || new Date();
  now = moment(now).add(days, 'd').toDate();
  now = moment(now).add(hours, 'h').toDate();
  now = moment(now).add(minutes, 'm').toDate();

  return now.getTime();
}

export function getPrice({ settings, userData, price }: { settings: Settings; userData: UserData; price: string }): Money {
  const myCurrency = getMyCurrency(settings, userData);
  const amount = parseFloat(price).toFixed(2);
  return {
    amount: amount ? amount.toString() : null,
    currency: myCurrency || Currency.CAD,
  };
}

export function getChangedObj(oldValue: unknown, newValue: unknown) {
  return {
    oldValue: oldValue || null,
    newValue: newValue || null,
  };
}

export const removeObjNullProps = (data: any = {})=> {
  const copy = { ...data };

  Object.keys(copy).forEach((key) => {
    if (copy[key] === null) {
      delete copy[key];
    }
  });

  return copy;
};

export const areValuesDifferent = (value1: any, value2: any)=> {
  if (isNil(value1) && isNil(value2)) {
    return false;
  }

  return !isEqual(value1, value2);
};

const cleanObject = <T extends Record<string, any> | null | undefined>(
  reference: T,
  target: T
): T => {
  if (isNull(target) || isUndefined(target)) return target;
  if (isNull(reference) || isUndefined(reference)) return cloneDeep(target) as T;

  const deepClean = (ref: Record<string, any>, obj: Record<string, any>): void => {
    if (!isObject(obj) || isNull(obj)) return;

    for (const key in obj) {
      if (!has(ref, key) && (isNull(obj[key]) || isUndefined(obj[key]))) {
        delete obj[key];
      } else if (isObject(obj[key]) && !isNull(obj[key])) {
        deepClean(ref[key] as Record<string, any>, obj[key] as Record<string, any>);
      }
    }
  };

  const clonedTarget = cloneDeep(target) as Record<string, any>;
  deepClean(reference as Record<string, any>, clonedTarget);
  return clonedTarget as T;
};

type Updates = Partial<Bounty> & { 'agentInfo/editedOn'?: string }
export function getBountyUpdates(origBounty: Bounty, newBounty: Bounty) {
  const updates: Updates = {} as Updates;
  const changes: any = {};

  newBounty.agentInfo = { editedOn: buildAgentString() };

  if (areValuesDifferent(origBounty.name, newBounty.name)) {
    updates.name = newBounty.name;
    changes.name = getChangedObj(origBounty.name, newBounty.name);
  }

  if (areValuesDifferent(origBounty.description, newBounty.description)) {
    updates.description = newBounty.description;
    changes.description = getChangedObj(origBounty.description, newBounty.description);
  }

  if (areValuesDifferent(origBounty.title, newBounty.title)) {
    updates.title = newBounty.title;
    changes.title = getChangedObj(origBounty.title, newBounty.title);
  }

  if (areValuesDifferent(origBounty.targetUrl, newBounty.targetUrl)) {
    updates.targetUrl = newBounty.targetUrl;
    changes.targetUrl = getChangedObj(origBounty.targetUrl, newBounty.targetUrl);
  }

  if (areValuesDifferent(origBounty.broadcastInfo, newBounty.broadcastInfo)) {
    updates.broadcastInfo = newBounty.broadcastInfo;
    changes.broadcastInfo = getChangedObj(origBounty.broadcastInfo, newBounty.broadcastInfo);
  }

  const cleanedReward = cleanObject(origBounty.reward, newBounty.reward);

  if (areValuesDifferent(origBounty.reward, cleanedReward)) {
    updates.reward = cleanedReward ? Reward.toMap(cleanedReward) : null;
    changes.reward = getChangedObj(origBounty.reward, Reward.toMap(cleanedReward));
  }

  if (areValuesDifferent(origBounty.distribution, newBounty.distribution)) {
    updates.distribution = newBounty.distribution;
    changes.distribution = getChangedObj(origBounty.distribution, newBounty.distribution);
    updates.to = newBounty.to;
  }

  if (areValuesDifferent(origBounty.attachments, newBounty.attachments)) {
    updates.attachments = newBounty.attachments;
    changes.attachments = getChangedObj(origBounty.attachments, newBounty.attachments);
  }

  if (areValuesDifferent(origBounty.bountyCategories, newBounty.bountyCategories)) {
    updates.bountyCategories = newBounty.bountyCategories;
    changes.bountyCategories = getChangedObj(origBounty.bountyCategories, newBounty.bountyCategories);
  }

  if (areValuesDifferent(origBounty.expiresAt, newBounty.expiresAt)) {
    updates.expiresAt = newBounty.expiresAt;
    changes.expiresAt = getChangedObj(origBounty.expiresAt, newBounty.expiresAt);
  }

  if (areValuesDifferent(origBounty.terms, newBounty.terms)) {
    updates.terms = newBounty.terms;
    changes.terms = getChangedObj(origBounty.terms, newBounty.terms);
  }

  if (areValuesDifferent(origBounty.listId, newBounty.listId)) {
    updates.listId = newBounty.listId;
    changes.listId = getChangedObj(origBounty.listId, newBounty.listId);
  }

  if (areValuesDifferent(origBounty.listCode, newBounty.listCode)) {
    updates.listCode = newBounty.listCode;
    changes.listCode = getChangedObj(origBounty.listCode, newBounty.listCode);
  }

  if (areValuesDifferent(origBounty.summary, newBounty.summary)) {
    updates.summary = newBounty.summary;
    changes.summary = getChangedObj(origBounty.summary, newBounty.summary);
  }

  if (origBounty.identityMode && newBounty.identityMode
    && (origBounty.identityMode.forced !== newBounty.identityMode.forced
      || origBounty.identityMode.identityType !== newBounty.identityMode.identityType)) {
    updates.identityMode = newBounty.identityMode;
    changes.identityMode = getChangedObj(origBounty.identityMode, newBounty.identityMode);
  }

  if (areValuesDifferent(origBounty.responseVisibilityMode, newBounty.responseVisibilityMode)) {
    updates.responseVisibilityMode = newBounty.responseVisibilityMode;
    changes.visibilityMode = getChangedObj(origBounty.responseVisibilityMode, newBounty.responseVisibilityMode);
  }

  if (areValuesDifferent(origBounty.postAt, newBounty.postAt)) {
    updates.postAt = newBounty.postAt;
    changes.postAt = getChangedObj(origBounty.postAt, newBounty.postAt);
  }

  if (areValuesDifferent(origBounty.product, newBounty.product)) {
    updates.product = newBounty.product;
    changes.product = getChangedObj(origBounty.product, newBounty.product);
  }

  if (areValuesDifferent(origBounty.jobInfo, newBounty.jobInfo)) {
    updates.jobInfo = newBounty.jobInfo;
    changes.jobInfo = getChangedObj(origBounty.jobInfo, newBounty.jobInfo);
  }

  if (areValuesDifferent(origBounty.onBehalfOf, newBounty.onBehalfOf)) {
    updates.onBehalfOf = newBounty.onBehalfOf;
    changes.onBehalfOf = getChangedObj(origBounty.onBehalfOf, newBounty.onBehalfOf);
  }

  if (areValuesDifferent(origBounty.contestInfo, newBounty.contestInfo)) {
    updates.contestInfo = newBounty.contestInfo;
    changes.contestInfo = getChangedObj(origBounty.contestInfo, newBounty.contestInfo);
  }

  if (areValuesDifferent(origBounty.timeLimit, newBounty.timeLimit)) {
    updates.timeLimit = newBounty.timeLimit;
    changes.timeLimit = getChangedObj(origBounty.timeLimit, newBounty.timeLimit);
  }

  const cleanedBanner = cleanObject(origBounty.banner, newBounty.banner);

  if (areValuesDifferent(origBounty.banner, cleanedBanner)) {
    updates.banner = cleanedBanner;
    changes.banner = getChangedObj(origBounty.banner, cleanedBanner);
  }

  if (areValuesDifferent(origBounty.activateAt, newBounty.activateAt)) {
    updates.activateAt = newBounty.activateAt;
    changes.activateAt = getChangedObj(origBounty.activateAt, newBounty.activateAt);
  }

  if (areValuesDifferent(origBounty.tags, newBounty.tags)) {
    updates.tags = newBounty.tags;
    changes.tags = getChangedObj(origBounty.tags, newBounty.tags);
  }

  updates['agentInfo/editedOn'] = newBounty.agentInfo.editedOn;

  return { updates, changes };
}

export function getIdentityMode({ settings, userData, anonymity }: { settings: Settings; userData: UserData; anonymity: boolean }) {
  const identityMode = OpManager.getIdentityMode(settings, userData);

  return {
    forced: identityMode?.forced,
    identityType: anonymity ? IdentityType.Anon : IdentityType.Real,
  };
}

export function getPostAnonymity({ selectedBounty, settings, userData }: { selectedBounty: Bounty; settings: Settings; userData: UserData }) {
  if (selectedBounty && selectedBounty.identityMode) {
    return selectedBounty.identityMode.identityType !== IdentityType.Real;
  }

  const identityMode = OpManager.getIdentityMode(settings, userData);

  return identityMode.identityType !== IdentityType.Real;
}

export const getAuthorForCreation = (creator: Partial<User>, author: Partial<User>) => {
  if (!author) {
    return null;
  }

  return creator?.id === author?.id ? null : author;
};
