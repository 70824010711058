import { ChangeEvent, FC, MouseEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Popover } from '@mui/material';
import { HexColorPicker } from 'react-colorful';

import CustomInput from 'Components/CustomInput';

import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import * as styles from 'Components/ColorInput/index.module.scss';

interface ColorInputProps {
  label: string;
  name: string;
  value: string;
  errors?: string[] | ReactNode[];
  onChange: (name: string, value: string) => void;
  isDisabled?: boolean;
}

const ColorInput: FC<ColorInputProps> = ({
  label,
  name,
  value,
  errors,
  onChange,
  isDisabled = false,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPopperOpen = Boolean(anchorEl);

  const handleOpenPopper = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => onChange(name, value);

  const handleChangeColor = (color: string) => onChange(name, color);

  return (
    <div>
      <label className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: label })}
      </label>
      <div className={styles.colorWrapper}>
        <div>
          <div
            role="presentation"
            className={styles.colorBox}
            style={{ backgroundColor: value }}
            onClick={handleOpenPopper}
          />

          {isPopperOpen && (
            <Popover
              open={isPopperOpen}
              anchorEl={anchorEl}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              slotProps={{ paper: { className: styles.popperPaper } }}
              onClose={handleClosePopper}
            >
              <HexColorPicker color={value} onChange={handleChangeColor} />
            </Popover>
          )}
        </div>

        <CustomInput
          disabled={isDisabled}
          name={name}
          value={value}
          onChange={handleChange}
          className={styles.fullWidth}
          validationErrors={errors}
        />
      </div>
    </div>
  );
};

export default ColorInput;
