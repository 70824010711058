/**
 * Search component with magnifier icon (left side) and erase button (right side)
 * @param value {string} search value
 * @param onChange {function} callback on field change, returns a string
 */

import { FC } from 'react';
import { useIntl } from 'react-intl';
import * as styles from './styles.module.scss';

interface SearchProps {
  value: string;
  onChange: (value: string) => unknown;
}

const Search: FC<SearchProps> = ({ value, onChange }) => {
  const intl = useIntl();

  return (
    <div className={styles.searchWrapper}>
      <input
        data-qa="smartSearchInput"
        value={value}
        type="search"
        placeholder={intl.formatMessage({ id: 'placeholder.search' })}
        onChange={(event) => onChange(event.target.value)}
      />
      {value && (
        <button className={styles.eraseButton} onClick={() => onChange('')} type="button" aria-label="+">
          <i className="icon-add" />
        </button>
      )}
    </div>
  );
};

export default Search;
