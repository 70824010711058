import { ChangeEvent, FC } from 'react';
import { useDispatch } from 'react-redux';
import { notificationToast } from 'Utils/notificationToaster';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';

//  Components
import CustomInput from 'Components/CustomInput';

//  Actions/Selectors
import { closeModal } from 'Store/modal/actions';
import { rejectBounty } from 'Services/bounty/BountyService';
import { rejectResponse } from 'Services/response/ResponseService';

//  Other resources
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import { getErrorsByName } from 'Utils/formik';
import { reportSchema } from 'Utils/validation/report';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { ThunkDispatchType } from 'Types/redux.types';
import CustomModalButtons from 'Components/CustomModalButtons';

interface RejectBountyProps {
  bounty?: Bounty;
  response?: BountyResponse;
  onSuccess?: () => any;
  onInProgressStatusChange?: () => boolean;
}

const RejectBounty:FC<RejectBountyProps> = ({ bounty, response, onSuccess, onInProgressStatusChange }) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const intl = useIntl();

  const handleSubmit = ({ reason }: { reason: string }) => {
    if (!reason?.trim()) {
      notificationToast.info(intl.formatMessage({ id: 'error.provideDeclineReason' }));
    } else {
      if (response?.bountyInfo) {
        rejectResponse({
          response, reason, onSuccess, onInProgressStatusChange,
        });
      } else {
        rejectBounty({
          bounty, reason, onSuccess,
        });
      }

      dispatch(closeModal());
    }
  };

  const handleClose = () => dispatch(closeModal());

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: reportSchema,
    onSubmit: handleSubmit,
  });

  const handleChanges = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(name, value);
  };

  return (
    <form>
      <Typography className={labelStyles.fieldLabel}>
        {intl.formatMessage({ id: 'label.declineReason' })}
      </Typography>
      <CustomInput
        name="reason"
        placeholder={intl.formatMessage({ id: 'label.declineReason.placeholder' })}
        onChange={handleChanges}
        validationErrors={getErrorsByName(formik, 'reason')}
      />
      <input type="submit" hidden />
      <CustomModalButtons
        onDismiss={handleClose}
        onSubmit={formik.handleSubmit}
      />
    </form>
  );
};

export default RejectBounty;
