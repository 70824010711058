import { useIntl } from 'react-intl';
import { Grid, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ValidationError } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import CustomInput from 'Components/CustomInput';
import PhoneCodePicker from 'Components/PhoneCodePicker';
import CustomTextarea from 'Components/CustomTextarea';
import StyledSelect from 'Components/StyledSelect';
import CustomDateTimePicker from 'Components/CustomDateTimePicker';

import { closeDrawer } from 'Store/createBounty/actions';
import ReservationService from 'Services/ReservationService';
import { LocationsService } from 'Services/LocationsService';

import { getErrorsByName } from 'Utils/formik';
import { RESERVATION_ATTRIBUTE_OPTIONS } from 'Constants/dictionaries';
import { ReservationAttribute } from 'Constants/enums';
import { createReservation } from 'Utils/validation/reservationValidation';
import { getValidationErrorsHash } from 'Utils/validation/yup';
import { notificationToast } from 'Utils/notificationToaster';
import { ThunkDispatchType } from 'Types/redux.types';
import { authUserCompanyIdSelector } from 'Store/auth/selectors';
import { DictionaryItem } from 'Types/dictionaryItem.type';
import { MONTH_HOURS_FORMAT } from 'Constants/common';
import * as labelStyles from 'Assets/scss/modules/label.module.scss';
import CustomModalButtons from 'Components/CustomModalButtons';
import * as styles from './index.module.scss';

const ReservationCreate = () => {
  const intl = useIntl();
  const dispatch = useDispatch<ThunkDispatchType>();
  const companyId = useSelector(authUserCompanyIdSelector);
  const [restaurantsAsOptions, setRestaurantsAsOptions] = useState<DictionaryItem<string, string>[]>([]);
  const [availableHours, setAvailableHours] = useState<number[]>([]);

  useEffect(() => {
    if (companyId) {
      fetchRestaurants();
    }
  }, [companyId]);

  const fetchRestaurants = async () => {
    try {
      const response = await LocationsService.getLocations(companyId);
      setRestaurantsAsOptions(response?.list?.map((location) => ({
        label: location?.name,
        value: `${location?.id}-${location?.name}`,
      })) || []);
    } catch (error: any) {
      notificationToast.error(error?.message);
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      await createReservation.validate(values, { abortEarly: false });

      const queryParams = {
        date: values?.date,
        partySize: +values?.partySize,
        restaurantId: values?.restaurantId,
        reservationAttribute: values?.reservationAttribute?.toUpperCase(),
      };
      const availability = await ReservationService.checkReservationAvailability(queryParams);

      if (availability?.no_availability_reasons?.length > 0) {
        formik.setFieldError('date', 'error.thisDateAndTimeAreNotAvailable');
        return;
      }

      const isTimeAvailable = availability?.times?.some((time) => time === values?.date);

      if (!isTimeAvailable && availability?.times?.length > 0) {
        setAvailableHours(availability?.times);
        formik.setFieldError('date', 'error.selectAvailableDate');
        return;
      }

      const reservation = {
        ...values,
        partySize: +values?.partySize,
      };
      await ReservationService.createReservation(reservation);
      notificationToast.success('notification.success.reservationCreated');
      handleClose();
    } catch (error: any) {
      if (ValidationError.isError(error)) {
        formik.setErrors(getValidationErrorsHash(error));
      } else {
        notificationToast.error(error?.message || intl.formatMessage({ id: 'errors.somethingWentWrong' }));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      restaurantId: '',
      date: '',
      firstName: '',
      lastName: '',
      phone: {
        number: '',
        countryCode: 'US',
      },
      partySize: '',
      reservationAttribute: ReservationAttribute.Default,
      notes: '',
    },
    onSubmit: handleSubmit,
  });

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>) => {
    formik.setFieldValue(name, value);
  };

  const handleCountryCodeChange = (value: string) => {
    formik.setFieldValue('phone.countryCode', value);
  };

  const handleChangeDate = (date: Date | null, name: string) => {
    const time = date ? new Date(date).getTime() : null;
    formik.setFieldValue(name, time);
  };

  const handleSelectAvailableDate = (date: number) => {
    formik.setFieldValue('date', date);
  };

  const handleClose = () => dispatch(closeDrawer());

  return (
    <div className={styles.root}>
      <h3 className="mb-30">
        {intl.formatMessage({ id: 'label.createReservation' })}
      </h3>

      <div className={styles.formWrapper}>
        <h4 className="mb-10">
          {intl.formatMessage({ id: 'label.reservationDetails' })}
        </h4>

        <div>
          <label className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.restaurant' })}*`}
          </label>
          <StyledSelect
            name="restaurantId"
            value={formik.values?.restaurantId}
            onChange={handleChange}
            disabled={!restaurantsAsOptions?.length}
            validationErrors={getErrorsByName(formik, 'restaurantId')}
          >
            {restaurantsAsOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </StyledSelect>
        </div>

        <div>
          <p className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.date&Time' })}*`}
          </p>
          <CustomDateTimePicker
            name="date"
            ampm={false}
            minDateTime={new Date()}
            value={formik?.values?.date}
            onDateChange={handleChangeDate}
            validationErrors={getErrorsByName(formik, 'date')}
          />
        </div>

        {availableHours?.length > 0 && (
          <Grid container spacing={1.5}>
            {availableHours?.map((availableHours) => (
              <Grid item xs={3}>
                <div
                  role="presentation"
                  className={styles.date}
                  onClick={() => handleSelectAvailableDate(availableHours)}
                >
                  <p>{format(new Date(availableHours), MONTH_HOURS_FORMAT)}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        )}

        <div>
          <label className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.table' })}
          </label>
          <StyledSelect
            name="reservationAttribute"
            value={formik.values?.reservationAttribute}
            onChange={handleChange}
          >
            {RESERVATION_ATTRIBUTE_OPTIONS.map(({ label, value }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </StyledSelect>
        </div>

        <div>
          <label className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.partySize' })}*`}
          </label>
          <CustomInput
            name="partySize"
            value={formik.values?.partySize}
            onChange={handleChange}
            validationErrors={getErrorsByName(formik, 'partySize')}
          />
        </div>

        <h4 className="mb-10 mt-30">
          {intl.formatMessage({ id: 'label.guestInformation' })}
        </h4>

        <div>
          <label className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.firstName' })}*`}
          </label>
          <CustomInput
            name="firstName"
            value={formik.values?.firstName}
            onChange={handleChange}
            validationErrors={getErrorsByName(formik, 'firstName')}
          />
        </div>

        <div>
          <label className={labelStyles.fieldLabel}>
            {`${intl.formatMessage({ id: 'label.lastName' })}*`}
          </label>
          <CustomInput
            name="lastName"
            value={formik.values?.lastName}
            onChange={handleChange}
            validationErrors={getErrorsByName(formik, 'lastName')}
          />
        </div>

        <div className={styles.phoneWrapper}>
          <div className={styles.countryCode}>
            <label className={labelStyles.fieldLabel}>
              {intl.formatMessage({ id: 'editProfile.formControls.labels.countryCode' })}
            </label>
            <PhoneCodePicker
              value={formik?.values?.phone?.countryCode}
              onChange={handleCountryCodeChange}
              validationErrors={getErrorsByName(formik, 'phone.countryCode')}
            />
          </div>

          <div className={styles.phoneNumber}>
            <label className={labelStyles.fieldLabel}>
              {`${intl.formatMessage({ id: 'label.phoneNumberSMS' })}*`}
            </label>
            <CustomInput
              name="phone.number"
              value={formik?.values?.phone?.number}
              onChange={handleChange}
              validationErrors={getErrorsByName(formik, 'phone.number')}
            />
          </div>
        </div>

        <div>
          <Typography className={labelStyles.fieldLabel}>
            {intl.formatMessage({ id: 'label.notes' })}
          </Typography>
          <CustomTextarea
            name="notes"
            placeholder={intl.formatMessage({ id: 'placeholder.specialRequestsOrOccasions' })}
            value={formik?.values?.notes || ''}
            onChange={handleChange}
            rows={5}
            validationErrors={getErrorsByName(formik, 'notes')}
          />
        </div>
      </div>

      <CustomModalButtons
        onDismiss={handleClose}
        onSubmit={formik.handleSubmit}
        isLoading={formik.isSubmitting}
      />
    </div>
  );
};

export default ReservationCreate;
